import React, { useEffect, useState } from 'react';
import { multilanguage } from 'redux-multilanguage';
import Scrollbars from 'react-custom-scrollbars-2';
import { Box } from '@material-ui/core';

import useWindowDimension from '../../hooks/useWindowDimension';
import './PageNotFound.scss';
import { MetaTags } from 'react-meta-tags';

const PageNotFound = () => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const dimensions = useWindowDimension();
    useEffect(() => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
    }, []);
    const handleResize = () => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);
    return (
        <>
            <MetaTags>
                <title>Page Not Found | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Page Not Found  your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:title" content="Page Not Found | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Page Not Found  your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Scrollbars className='pageNotFoundMain' style={{ height: dimensions.height - headerHeight, marginTop: headerHeight }}>
                <Box className='pageNotFound'>
                    <Box className='pageNotFoundMiddle'>
                        <img alt='404' src='https://res.cloudinary.com/dygllydcg/image/upload/v1689830081/MouthMap/Web/Static-images/teeth-animation.gif' />
                    </Box>
                </Box>
                <Box className='pageNotFoundFooter'>
                    <Box className='pageNotFoundFooterTitle'>404</Box>
                    <Box className='pageNotFoundFooterText'>The page you are looking for may have been moved, deleted or possibly never existed.</Box>
                </Box>
                <Box className='goBackButton'>
                    <button className='btn' onClick={() => { window.history.back(); }}>Go to Dashboard</button>
                </Box>
            </Scrollbars>
        </>

    );
};
export default multilanguage(PageNotFound);
