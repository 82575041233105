import * as React from 'react';
import { Box, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';

import './CustomRadioButton.scss';

export default function CustomRadioButton (props) {
    return (
        <Box className='customRadioButton'>
            <Typography variant='body2'>{props.label}</Typography>
            <Radio
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                value={props.value}
                name="radio-buttons"
            />
        </Box>
    );
}
