import React, { Fragment, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { multilanguage } from 'redux-multilanguage';
import { Grid } from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import { connect } from 'react-redux';

import HookForm from '../../../../components/HookForm/HookForm';
import CustomCheckBox from '../../../../components/CustomCheckBox/CustomCheckBox';
import CustomTextField from '../../../../components/CustomTextField/CustomTextFeild';
import CustomLoadingButton from '../../../../components/CustomLoadingButton/CustomLoadingButton';

import instance from '../../../../api/axios';
import Constant from '../../../../utils/constant';
import { showToast } from '../../../../redux/actions/toastAction';
import useWindowDimension from '../../../../hooks/useWindowDimension';
import { setRememberMe, setUser } from '../../../../redux/actions/authAction';

import './Login.scss';

const Login = (props) => {
    const { strings, rememberData } = props;
    const history = useNavigate();
    const dimensions = useWindowDimension();
    const [busy, setBusy] = useState(false);
    const [signIn, setSignIn] = useState();
    const [showPass, setShowPass] = useState('password');
    const [rememberMe, setRememberMe] = useState(props?.rememberData?.isRemember);
    const signInForm = {
        email: {
            name: 'email',
            validate: {
                required: {
                    value: true,
                    message: strings.EMAIL_ADDRESS_IS_REQUIRED
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: strings.ENTER_VALID_EMAIL
                }
            }
        },
        password: {
            name: 'password',
            validate: {
                required: {
                    value: true,
                    message: strings.PASSWORD_IS_REQUIRED
                }
            }
        }
    };
    const onFormSubmit = async (value) => {
        setBusy(true);
        console.log('login details', value);
        const params = {
            sEmail: value.email,
            sPassword: value.password,
            sUserRole: 'Admin'
        };
        await instance.post('/auth/login', params)
            .then((res) => {
                console.log('res', res);
                if (rememberMe === true) {
                    props.setRememberMe({ email: value.email, password: value.password, isRemember: true });
                } else {
                    props.setRememberMe({});
                }
                props.setUser(res.payload.user);
                setTimeout(() => {
                    props.showToast({
                        message: strings.LOGIN_SUCCESSFULLY,
                        type: 'success'
                    });
                    setBusy(false);
                    history('/home');
                }, 3000);
                window.localStorage.setItem(Constant.REFRESH_TOKEN_KEY, res.payload.tokens.refresh.token);
                window.localStorage.setItem(Constant.ACCESS_TOKEN_KEY, res.payload.tokens.access.token);
            }).catch((err) => {
                console.log('ererer', err);
                setTimeout(() => {
                    setBusy(false);
                    props.showToast({
                        message: strings.LOGIN_ERROR,
                        type: 'error'
                    });
                }, 3000);
            });
    };
    const handleChangePassword = () => {
        if (showPass === 'password') {
            setShowPass('text');
        } else {
            setShowPass('password');
        }
    };
    useEffect(() => {
    }, [busy]);
    return (
        <>
            <MetaTags>
                <title>Admin Portal | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Log in to the Mouthmap Admin Portal to view the latest sales information, update app content, manage orders and deliveries, and more." />
                <meta property="og:title" content="Admin Portal | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Log in to the Mouthmap Admin Portal to view the latest sales information, update app content, manage orders and deliveries, and more." />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Grid container component="main" className="signinMain" style={{ height: dimensions.height + 'px' }}>
                <Grid item xs={6} className="signinLeft">
                    <Box className='centerContent' align='center'>
                        <img src={Constant.IMAGESURL.LOGO} className="logo" alt="Logo" width='336' height='287' />
                    </Box>
                </Grid>
                <Grid item xs={6} className="signinRight" style={{ height: dimensions.height + 'px' }}>
                    <img src={Constant.IMAGESURL.LOGO_TEXT} className="logo" alt="Logo2" width='167' height='92' />
                    <Box className='upperContent'>
                        <Typography className='title'>{strings.SIGNIN}</Typography>
                    </Box>
                    <HookForm
                        defaultForm={{
                            email: rememberData?.email,
                            password: rememberData?.password,
                            rememberMe: rememberData?.isRemember
                        }}
                        init={(form) => setSignIn(signIn)}
                        onSubmit={(e) => onFormSubmit(e)}
                    >
                        {(formMethod) => {
                            return (
                                <Fragment>
                                    <Box className='formGroupField'>
                                        <CustomTextField
                                            placeholder={strings.EMAIL_ADDRESS}
                                            variant='outlined'
                                            autoFocus={false}
                                            type='text'
                                            formMethod={formMethod}
                                            rules={signInForm.email.validate}
                                            name={signInForm.email.name}
                                            errors={formMethod?.formState.errors}
                                            classText="loginpage"
                                        />
                                    </Box>
                                    <Box className='formGroupField'>
                                        <CustomTextField
                                            placeholder='Password'
                                            variant='outlined'
                                            autoFocus={false}
                                            type={showPass}
                                            formMethod={formMethod}
                                            rules={signInForm.password.validate}
                                            name={signInForm.password.name}
                                            errors={formMethod?.formState.errors}
                                            classText="loginpage"
                                            IconName={showPass === 'password' ? <span className='icon icon-eyeoff' onClick={handleChangePassword} ></span> : <span className='icon icon-eye' onClick={handleChangePassword}></span>}
                                        />
                                    </Box>
                                    <Box className='submitBtn'>
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            btnTitle={strings.SIGNIN}
                                            loading={busy}
                                            disabled={!formMethod?.formState.isValid}
                                            className="submitButton"
                                        />
                                    </Box>
                                    <Box className='rememberField'>
                                        <CustomCheckBox label={strings.REMEMBER_ME} checked={rememberMe} onCheckedChange={(checked) => { setRememberMe(checked); }} />
                                    </Box>
                                    <Link
                                        component={RouterLink}
                                        to='/forgotpassword'
                                        className='redirectLinkText'
                                        color='text.primary'>{strings.FORGOT_PASSWORD}
                                    </Link>
                                </Fragment>
                            );
                        }}
                    </HookForm>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        rememberData: state.auth?.rememberData
    };
};

const mapDispatchToProps = {
    showToast,
    setRememberMe,
    setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(Login));
