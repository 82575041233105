/* eslint-disable no-template-curly-in-string */

/*eslint-disable  */

import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import useWindowDimension from "../../../hooks/useWindowDimension";

import "./Home.scss";
import FilterRadioButton from "../../../components/FIlterRadioButton/FilterRadioButton";
import instance from "../../../api/axios";
import { MetaTags } from "react-meta-tags";

const Home = (props) => {
  const dimensions = useWindowDimension();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [earingDate, setEaringDate] = useState(new Date());
  const [productStartDate, setProductStartDate] = useState(new Date());
  const [earningDateRadio, setEarningDateRadio] = useState("Weekly");
  const [earningDateRadio2, setEarningDateRadio2] = useState("Weekly2");
  const [weekFilter, setWeekFilter] = useState("Weekly");
  const [weekFilter2, setWeekFilter2] = useState("Weekly2");
  const [xAxisCatData, setXAxisCatData] = useState([]);
  const [xAxisCatDataOptTwo, setXAxisCatDataOptTwo] = useState([]);
  const [earningSeriesData, setEarningSeriesData] = useState([]);
  const [userSummary, setUserSummary] = useState([]);
  const [subscribeProductSummary, setSubscribeProductSummary] = useState([]);
  const [oneTimeProductSummary, setOneTimeProductSummary] = useState([]);

  const [dashboardData, setDashboardData] = useState([]);

  const onChangeMyEaringDate = (dates) => {
    setEaringDate(dates);
    if (weekFilter === "Monthly") {
      getMonthlyTotalEarning(dates.getFullYear());
    }
    if (weekFilter === "Weekly") {
      getWeeklyTotalEarning(dates.getMonth() + 1);
    }
  };
  const onChangeMyProgressDate = (dates) => {
    setProductStartDate(dates);
    if (weekFilter2 === "Monthly2") {
      getMonthlySummary(dates.getFullYear());
    }
    if (weekFilter2 === "Weekly2") {
      getWeeklySummary(dates.getMonth() + 1);
    }
  };

  const handleResize = () => {
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
  };

  useEffect(() => {
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );

    const addResizeEventListener = () => {
      window.addEventListener("resize", handleResize);
    };

    const removeResizeEventListener = () => {
      window.removeEventListener("resize", handleResize);
    };

    addResizeEventListener();

    return () => {
      removeResizeEventListener();
    };
  }, []);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  const options1 = {
    chart: {
      type: "area",
      height: (5 / 20) * 100 + "%",
      spacingRight: 10,
      spacingLeft: 15,
      spacingBottom: 0,
    },
    xAxis: {
      categories: xAxisCatData,
      lineColor: "#E7EFee",
      lineWidth: 1,
    },
    yAxis: {
      lineColor: "#E7EFee",
      lineWidth: 1,
      labels: {
        format: "£{text}",
      },
      title: {
        text: "Earnings",
      },
    },
    tooltip: {
      shared: false,
      headerFormat: '<span style="font-size:12px"></span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>£{point.y}</b></td></tr>',
      footerFormat: "</table>",
      useHTML: true,
      backgroundColor: "#fff",
      borderWidth: 0,
      borderRadius: 10,
      style: {
        color: "#3B4D61",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillOpacity: 0.5,
        color: "#f3ddd8",
      },
    },
    series: [
      {
        name: "",
        data: earningSeriesData,
      },
    ],
  };
  const options2 = {
    chart: {
      type: "column",
      height: (6 / 25) * 100 + "%",
      spacingRight: 10,
      spacingBottom: 0,
    },

    xAxis: {
      categories: xAxisCatDataOptTwo,
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Numbers",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px,"></span><table>',
      pointFormat:
        "<tr><td>{series.name}: </td>" +
        '<td style="padding:2px 0px 2px 20px"><b style="color: #707070">{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      stickOnContact: false,
      shared: true,
      useHTML: true,
      backgroundColor: "#fff",
      borderWidth: 0,
      borderRadius: 10,
      style: {
        color: "#707070",
        paddingVertical: 10,
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Users",
        color: "#C0C6CC",
        data: userSummary,
      },
      {
        name: "Subscriptions",
        color: "#E8BCB1",
        data: subscribeProductSummary,
      },
      {
        name: "One-time purchases",
        color: "#3B4D61",
        data: oneTimeProductSummary,
      },
    ],
  };

  const getMonthlyTotalEarning = async (year) => {
    let params = "";
    if (year) {
      params = `?year=${year}&filter=${weekFilter}`;
    } else {
      const currentYear = new Date().getFullYear();
      params = `?year=${currentYear}&filter=${weekFilter}`;
    }
    await instance
      .get(`/order/getTotalEarning${params}`)
      .then((response) => {
        const arrayData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        response.payload.totalEarning.forEach((element) => {
          arrayData[element._id - 1] = element.totalEarning;
        });
        setEarningSeriesData(arrayData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getMonthlySummary = async (year) => {
    let params = "";
    if (year) {
      params = `?year=${year}&filter=${weekFilter2}`;
    } else {
      const currentYear = new Date().getFullYear();
      params = `?year=${currentYear}&filter=${weekFilter2}`;
    }
    await instance
      .get(`/order/getPlatformSummary${params}`)
      .then((response) => {
        const subscribedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const oneTimeData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        const userData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        response.payload.users.forEach((ele) => {
          userData[ele._id - 1] = ele.onBoardedUser;
        });
        setUserSummary(userData);
        response.payload.totalSummary.forEach((element) => {
          subscribedData[element._id - 1] = element.subscribedProducts;
          oneTimeData[element._id - 1] = element.onetimeProducts;
        });

        setSubscribeProductSummary(subscribedData);
        setOneTimeProductSummary(oneTimeData);
      })
      .catch((error) => {
        console.log("error>", error);
      });
  };

  const getYearlyTotalEarning = async () => {
    const params = `?filter=${weekFilter}`;
    await instance
      .get(`/order/getTotalEarning${params}`)
      .then((response) => {
        if (response.payload.totalEarning.length > 0) {
          const allYears = response.payload.totalEarning.map((obj) => obj._id);
          const allYearsData = response.payload.totalEarning.map(
            (obj) => obj.totalEarning
          );

          setXAxisCatData(allYears);
          setEarningSeriesData(allYearsData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getYearlySummary = async () => {
    const params = `?filter=${weekFilter2}`;

    await instance
      .get(`/order/getPlatformSummary${params}`)
      .then((response) => {
        const allYearsUsers = response.payload.combinedData.map(
          (obj) => obj.onBoardedUser
        );
        setUserSummary(allYearsUsers);
        if (response.payload.combinedData.length > 0) {
          const allYears = response.payload.combinedData?.map(
            (obj) => obj?.year
          );
          const allYearsSubscribedProducts = response.payload.combinedData?.map(
            (obj) => obj?.subscribedProducts
          );
          const allYearsOnetimeProducts = response.payload.combinedData?.map(
            (obj) => obj?.onetimeProducts
          );
          setXAxisCatDataOptTwo(allYears);
          setSubscribeProductSummary(allYearsSubscribedProducts);
          setOneTimeProductSummary(allYearsOnetimeProducts);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getWeeklyTotalEarning = async (month) => {
    try {
      let currentMonth = new Date().getMonth() + 1;
      let defaultArrayData = [];
      let params = "";
      let totalWeek;

      if (month) {
        params = `?month=${month}&filter=${weekFilter}`;
      } else {
        params = `?month=${currentMonth}&filter=${weekFilter}`;
      }

      const res = await instance.get(`/order/getTotalEarning${params}`);
      totalWeek = res.payload.weekdata.totalWeeks;
      const weekdata = res.payload.weekdata;

      if (totalWeek === 4) {
        setXAxisCatData(["Week 1", "Week 2", "Week 3", "Week 4"]);
        defaultArrayData = [0, 0, 0, 0];
      } else if (totalWeek === 5) {
        setXAxisCatData(["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]);
        defaultArrayData = [0, 0, 0, 0, 0];
      } else {
        setXAxisCatData([
          "Week 1",
          "Week 2",
          "Week 3",
          "Week 4",
          "Week 5",
          "Week 6",
        ]);
        defaultArrayData = [0, 0, 0, 0, 0, 0];
      }

      for (let i = 1; i <= totalWeek; i++) {
        const weekKey = `week${i}`;
        if (weekKey in weekdata) {
          const weekValue = weekdata[weekKey];
          defaultArrayData[i - 1] = weekValue;
        }
      }
      setEarningSeriesData(defaultArrayData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getWeeklySummary = async (month) => {
    let currentMonth = new Date().getMonth() + 1;
    let defaultUserSummary = [];
    let defaultSubscribeProductSummary = [];
    let defaultOneTimeProductSummary = [];
    let params = "";
    let totalWeek;
    if (month) {
      params = `?month=${month}&filter=${weekFilter2}`;
    } else {
      params = `?month=${currentMonth}&filter=${weekFilter2}`;
    }
    await instance
      .get(`/order/getPlatformSummary${params}`)
      .then((res) => {
        totalWeek = res.payload.weekdata.totalWeeks;
        const weekdata = res.payload.weekdata;
        const OneTimeProductWeekData = res.payload.OneTimeProductWeekData;
        const subscribeProductWeekData = res.payload.subscribeProductWeekData;

        if (totalWeek === 4) {
          setXAxisCatDataOptTwo(["Week 1", "Week 2", "Week 3", "Week 4"]);
          defaultUserSummary = [0, 0, 0, 0];
          defaultSubscribeProductSummary = [0, 0, 0, 0];
          defaultOneTimeProductSummary = [0, 0, 0, 0];

          for (let i = 1; i <= totalWeek; i++) {
            const weekKey = `week${i}`;
            if (weekKey in weekdata) {
              const weekValue = weekdata[weekKey];
              defaultUserSummary[i - 1] = weekValue;
            }
            if (weekKey in OneTimeProductWeekData) {
              const weekValue = OneTimeProductWeekData[weekKey];
              defaultOneTimeProductSummary[i - 1] = weekValue;
            }
            if (weekKey in subscribeProductWeekData) {
              const weekValue = subscribeProductWeekData[weekKey];
              defaultSubscribeProductSummary[i - 1] = weekValue;
            }
          }
          setSubscribeProductSummary(defaultSubscribeProductSummary);
          setOneTimeProductSummary(defaultOneTimeProductSummary);
          setUserSummary(defaultUserSummary);
        } else if (totalWeek === 5) {
          setXAxisCatDataOptTwo([
            "Week 1",
            "Week 2",
            "Week 3",
            "Week 4",
            "Week 5",
          ]);
          defaultUserSummary = [0, 0, 0, 0, 0];
          defaultSubscribeProductSummary = [0, 0, 0, 0, 0];
          defaultOneTimeProductSummary = [0, 0, 0, 0, 0];
        } else {
          setXAxisCatDataOptTwo([
            "Week 1",
            "Week 2",
            "Week 3",
            "Week 4",
            "Week 5",
            "Week 6",
          ]);
          defaultUserSummary = [0, 0, 0, 0, 0, 0];
          defaultSubscribeProductSummary = [0, 0, 0, 0, 0, 0];
          defaultOneTimeProductSummary = [0, 0, 0, 0, 0, 0];
        }
        for (let i = 1; i <= totalWeek; i++) {
          const weekKey = `week${i}`;
          if (weekKey in weekdata) {
            const weekValue = weekdata[weekKey];
            defaultUserSummary[i - 1] = weekValue;
          }
          if (weekKey in OneTimeProductWeekData) {
            const weekValue = OneTimeProductWeekData[weekKey];
            defaultOneTimeProductSummary[i - 1] = weekValue;
          }
          if (weekKey in subscribeProductWeekData) {
            const weekValue = subscribeProductWeekData[weekKey];
            defaultSubscribeProductSummary[i - 1] = weekValue;
          }
        }
        setSubscribeProductSummary(defaultSubscribeProductSummary);
        setOneTimeProductSummary(defaultOneTimeProductSummary);
        setUserSummary(defaultUserSummary);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await instance.get("/user/userList");
        const arrayData = [];
        const dashboardUserList = {
          id: "1",
          produsericon: "icon-user",
          produtype: "Users",
          produCount: response1.payload.userCount,
        };
        arrayData.push(dashboardUserList);

        const response2 = await instance.get("/products/allProductList");
        const dashboardProductList = {
          id: "2",
          produsericon: "icon-user",
          produtype: "Products",
          img: "https://res.cloudinary.com/dygllydcg/image/upload/v1689829938/MouthMap/Web/Static-images/brush.svg",
          produCount: response2.payload.product.length,
        };
        arrayData.push(dashboardProductList);

        const response3 = await instance.get("/order/pendingOrder");
        const dashboardPendingOrderList = {
          id: "3",
          produsericon: "icon-user",
          produtype: "Pending Orders",
          produCount: response3.payload.length,
        };
        arrayData.push(dashboardPendingOrderList);

        setDashboardData(arrayData);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (weekFilter === "Monthly") {
      const catData = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      setXAxisCatData(catData);
    }
  }, [weekFilter]);

  useEffect(() => {
    if (weekFilter2 === "Monthly2") {
      const catData = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      setXAxisCatDataOptTwo(catData);
    }
  }, [weekFilter2]);

  useEffect(() => {
    const fetchData = async () => {
      if (weekFilter === "Monthly") {
        await getMonthlyTotalEarning();
      } else if (weekFilter === "Yearly") {
        await getYearlyTotalEarning();
      } else if (weekFilter === "Weekly") {
        await getWeeklyTotalEarning();
      }
    };

    fetchData();
  }, [weekFilter]);

  useEffect(() => {
    const fetchData = async () => {
      if (weekFilter2 === "Monthly2") {
        await getMonthlySummary();
      } else if (weekFilter2 === "Yearly2") {
        await getYearlySummary();
      } else if (weekFilter2 === "Weekly2") {
        await getWeeklySummary();
      }
    };

    fetchData();
  }, [weekFilter2]);

  const MyProgressDateFilter = ({ value, onClick }) => {
    return (
      <button type="button" className="custom-date-input" onClick={onClick}>
        {value} <i className="icon icon-chevron-down" />
      </button>
    );
  };
  return (
    <>
      <MetaTags>
        <title> Dashboard | Mouthmap - Your Route To A Healthy Mouth </title>
        <meta
          name="description"
          content=" View summary data at-a-glance via the dashboard view including total app users, pending orders, sales info, and more."
        />
        <meta
          property="og:title"
          content=" Dashboard | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content=" View summary data at-a-glance via the dashboard view including total app users, pending orders, sales info, and more."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>
      <Box
        className="dashboard"
        style={{
          marginTop: headerHeight,
          height: dimensions.height - 110 + "px",
        }}
      >
        <Box className="dashboardWrapper">
          <Box className="dashboardContainer">
            <Box className="sectionTitle">
              <Typography className="title" variant="h5">
                Dashboard
              </Typography>
            </Box>

            <Box className="dashboardProductWrapper">
              <Box className="ProductView">
                {dashboardData.map((item, index) => {
                  return (
                    <Box className="ProductMain" key={index}>
                      <Box className="ProductIcon">
                        {item.produtype === "Users" && (
                          <Typography
                            component="i"
                            className={item.produsericon}
                          />
                        )}
                        {item.produtype === "Products" && (
                          <img
                            src="https://res.cloudinary.com/dygllydcg/image/upload/v1689830010/MouthMap/Web/Static-images/products.png"
                            srcSet="https://res.cloudinary.com/dygllydcg/image/upload/v1689830010/MouthMap/Web/Static-images/products.png"
                            alt={item.img}
                            loading="lazy"
                          />
                        )}
                        {item.produtype === "Pending Orders" && (
                          <img
                            src="https://res.cloudinary.com/dygllydcg/image/upload/v1689830039/MouthMap/Web/Static-images/pending.png"
                            srcSet="https://res.cloudinary.com/dygllydcg/image/upload/v1689830039/MouthMap/Web/Static-images/pending.png"
                            alt={item.img}
                            loading="lazy"
                          />
                        )}
                      </Box>
                      <Box className="ProductContentWrapper">
                        <Typography component="p" className="subtext">
                          {item.produtype}
                        </Typography>
                        <Typography component="h2" className="countdesign">
                          {item.produCount}
                        </Typography>
                      </Box>
                      <Box className="ProductBorder" />
                    </Box>
                  );
                })}
              </Box>
              <Box className="dashboardContentWrapper">
                <Box className="dashboardrecentorder">
                  <Box className="chartsection Earning-chart">
                    <Box className="chartHeader">
                      <Box className="chartTilte">
                        <Typography className="title" variant="h5">
                          Total Earnings
                        </Typography>
                      </Box>
                      <Box className="chartTabAndDateWrapper">
                        <Box className="radioWrap">
                          <Stack className="filterRadioButtonRow">
                            <FilterRadioButton
                              id="Weekly"
                              label="Weekly"
                              value="Weekly"
                              changed={(e) => {
                                setEarningDateRadio(e.target.value);
                                setWeekFilter(e.target.value);
                              }}
                              isSelected={earningDateRadio === "Weekly"}
                            />
                            <FilterRadioButton
                              id="Monthly"
                              label="Monthly"
                              value="Monthly"
                              changed={(e) => {
                                setEarningDateRadio(e.target.value);
                                setWeekFilter(e.target.value);
                              }}
                              isSelected={earningDateRadio === "Monthly"}
                            />
                            <FilterRadioButton
                              id="Yearly"
                              label="Yearly"
                              value="Yearly"
                              changed={(e) => {
                                setEarningDateRadio(e.target.value);
                                setWeekFilter(e.target.value);
                              }}
                              isSelected={earningDateRadio === "Yearly"}
                            />
                          </Stack>
                        </Box>
                        <Box className="dropDownWrap">
                          <Box
                            className={
                              earningDateRadio === "Yearly" ||
                              earningDateRadio === "Monthly"
                                ? "monthWrap hideDisabled"
                                : "monthWrap"
                            }
                          >
                            <DatePicker
                              disabled={
                                earningDateRadio === "Yearly" ||
                                earningDateRadio === "Monthly"
                              }
                              selected={earingDate}
                              onChange={(e) => onChangeMyEaringDate(e)}
                              customInput={<MyProgressDateFilter />}
                              popperClassName="dateFilter monthPicker"
                              maxDate={new Date()}
                              showYearPicker={false}
                              showMonthYearPicker={true}
                              dateFormat="MMM"
                              isClearable={false}
                            />
                          </Box>
                          <Box
                            className={
                              earningDateRadio === "Weekly" ||
                              earningDateRadio === "Yearly"
                                ? "yearWrap hideDisabled"
                                : "yearWrap"
                            }
                          >
                            <DatePicker
                              disabled={
                                earningDateRadio === "Weekly" ||
                                earningDateRadio === "Yearly"
                              }
                              selected={earingDate}
                              onChange={(e) => onChangeMyEaringDate(e)}
                              customInput={<MyProgressDateFilter />}
                              popperClassName="dateFilter"
                              maxDate={new Date()}
                              showYearPicker={true}
                              dateFormat="yyyy"
                              isClearable={false}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {xAxisCatData && (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={options1}
                      />
                    )}
                  </Box>
                  <Box className="chartsection Earning-chart">
                    <Box className="chartHeader">
                      <Box className="chartTilte platform-wrapper">
                        <Typography className="title" variant="h5">
                          Platform Summary
                        </Typography>
                        <Box className="color-define">
                          <Box className="color-User color-box-wrapper">
                            <Box className="box" />
                            <Typography className="color-Define-title">
                              Users
                            </Typography>
                          </Box>
                          <Box className="color-Subscribed color-box-wrapper">
                            <Box className="box" />
                            <Typography className="color-Define-title">
                              Subscriptions
                            </Typography>
                          </Box>
                          <Box className="color-Onetimebuy color-box-wrapper">
                            <Box className="box" />
                            <Typography className="color-Define-title">
                              One-time purchases
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="chartTabAndDateWrapper">
                        <Box className="radioWrap">
                          <Stack className="filterRadioButtonRow">
                            <FilterRadioButton
                              id="Weekly2"
                              label="Weekly"
                              value="Weekly2"
                              changed={(e) => {
                                setEarningDateRadio2(e.target.value);
                                setWeekFilter2(e.target.value);
                              }}
                              isSelected={earningDateRadio2 === "Weekly2"}
                            />
                            <FilterRadioButton
                              id="Monthly2"
                              label="Monthly"
                              value="Monthly2"
                              changed={(e) => {
                                setEarningDateRadio2(e.target.value);
                                setWeekFilter2(e.target.value);
                              }}
                              isSelected={earningDateRadio2 === "Monthly2"}
                            />
                            <FilterRadioButton
                              id="Yearly2"
                              label="Yearly"
                              value="Yearly2"
                              changed={(e) => {
                                setEarningDateRadio2(e.target.value);
                                setWeekFilter2(e.target.value);
                              }}
                              isSelected={earningDateRadio2 === "Yearly2"}
                            />
                          </Stack>
                        </Box>
                        <Box className="dropDownWrap">
                          <Box
                            className={
                              earningDateRadio2 === "Yearly2" ||
                              earningDateRadio2 === "Monthly2"
                                ? "monthWrap hideDisabled"
                                : "monthWrap"
                            }
                          >
                            <DatePicker
                              disabled={
                                earningDateRadio2 === "Yearly2" ||
                                earningDateRadio2 === "Monthly2"
                              }
                              selected={productStartDate}
                              onChange={(e) => onChangeMyProgressDate(e)}
                              customInput={<MyProgressDateFilter />}
                              popperClassName="dateFilter monthPicker"
                              maxDate={new Date()}
                              showYearPicker={false}
                              showMonthYearPicker={true}
                              dateFormat="MMM"
                              isClearable={false}
                            />
                          </Box>
                          <Box
                            className={
                              earningDateRadio2 === "Weekly2" ||
                              earningDateRadio2 === "Yearly2"
                                ? "yearWrap hideDisabled"
                                : "yearWrap"
                            }
                          >
                            <DatePicker
                              disabled={
                                earningDateRadio2 === "Weekly2" ||
                                earningDateRadio2 === "Yearly2"
                              }
                              selected={productStartDate}
                              onChange={(e) => onChangeMyProgressDate(e)}
                              customInput={<MyProgressDateFilter />}
                              popperClassName="dateFilter"
                              maxDate={new Date()}
                              showYearPicker={true}
                              dateFormat="yyyy"
                              isClearable={false}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options2}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Home));
