import React from 'react';
import { multilanguage } from 'redux-multilanguage';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import './CustomCard.scss';

const CustomCard = (props) => {
    const { strings } = props;
    const label = { inputProps: { 'aria-label': 'Switch' } };
    const deletePopup = (e) => {
        e.preventDefault();
        props.deletePopup();
    };
    const { selectedColors } = props;

    return (
        <Card sx={{ maxWidth: 398 }} className={selectedColors ? 'cardWrap ColorWrap' : 'cardWrap ' + props.videoBrandCard}>
            {props.imageurl !== undefined &&
                <CardMedia
                    component="img"
                    height="115"
                    width="115"
                    image={props.imageurl}
                    alt={props.alttext}
                />
            }
            <Box className={props.imageurl !== undefined ? 'rightWrap' : 'rightWrap withoutImage'}>
                <CardContent className='cardContent'>
                    <Typography gutterBottom variant="subtitle2" className='category' component="div">
                        {props.category}
                    </Typography>
                    <Box className='mainBrushBox'>
                        {props.color && <Typography className='brushColor' style={{ backgroundColor: props.color }}></Typography>}
                        <Typography variant='subtitle1' className='title'>{props.title}</Typography>
                    </Box>
                    <Box className='statusBox'>
                        <Typography variant="caption" className={props.status === 'Active' ? 'active' : 'inactive'}>{props.status}</Typography>
                        <Typography variant="caption" className={props.subscription ? 'subscribed' : 'hide'} visibility={props.subscription ? 'visible' : 'hidden'}>Subscribed</Typography>
                        <Typography variant="caption" className='brushSize'>{props.brushSize}</Typography>
                    </Box>
                </CardContent>
                <CardActions className="productFooter">
                    <Box className="leftSide ">
                        <Typography className='price'>{props.price}</Typography>
                    </Box>
                    <Box className="rightSide">
                        <Button size="small" onClick={props.editPopup}>{strings.EDIT}</Button>
                        <Button size="small" className='deleteBtn' onClick={(e) => deletePopup(e)} >{strings.DELETE}</Button>
                        <Switch {...label} checked={props?.checked} disableTouchRipple onClick={(e) => props.disablePopup(e)} />
                    </Box>
                </CardActions>
            </Box>
            {selectedColors && (
                <Box className="colorBoxes">
                    {selectedColors.map((selectedColor, index) => (
                        <div
                            key={index}
                            className="colorBox"
                            style={{ backgroundColor: selectedColor }}
                        ></div>
                    ))}
                </Box>
            )}
        </Card>
    );
};

export default multilanguage(CustomCard);
