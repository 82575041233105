import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import './CustomTextEditor.scss';

const EditorStyledToolbar = (props) => {
    return (
        <Editor
            placeholder={props.placeholder}
            value={props.value}
            className={props.classs}
            // defaultValue={props.defaultValue}
            defaultContentState={props.defaultContentState}
            onContentStateChange={props.onContentStateChange}
            toolbarClassName='customToolbar'
            wrapperClassName='wrapperClassName'
            editorClassName='customEditor'
            onChange={props.onChange}
            toolbar={{
                options: ['inline', 'textAlign', 'list', 'link', 'image', 'emoji', 'history'],
                inline: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'underline'],
                    bold: { className: undefined },
                    italic: { className: undefined },
                    underline: { className: undefined },
                    strikethrough: { className: undefined },
                    monospace: { className: undefined },
                    superscript: { className: undefined },
                    subscript: { className: undefined }
                },
                blockType: {
                    inDropdown: true,
                    options: [
                        'Normal',
                        'H1',
                        'H2',
                        'H3',
                        'H4',
                        'H5',
                        'H6',
                        'Blockquote',
                        'Code'
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                },
                fontSize: {
                    inDropdown: true,
                    options: [
                        8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                        48, 60, 72, 96
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                },
                fontFamily: {
                    options: [
                        'Arial',
                        'Georgia',
                        'Impact',
                        'Tahoma',
                        'Times New Roman',
                        'Verdana'
                    ],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                },
                list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['ordered'],
                    ordered: { className: undefined },
                    indent: { className: undefined },
                    outdent: { className: undefined }
                },
                textAlign: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: [
                        'left',
                        'center'
                    ],
                    left: { className: undefined },
                    center: { className: undefined },
                    right: { className: undefined },
                    justify: { className: undefined }
                },
                link: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    dropdownClassName: undefined,
                    showOpenOptionOnHover: true,
                    defaultTargetOption: '_self',
                    options: ['link'],
                    link: { className: undefined },
                    unlink: { className: undefined },
                    linkCallback: undefined
                },
                embedded: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    embedCallback: undefined,
                    defaultSize: {
                        height: 'auto',
                        width: 'auto'
                    }
                },
                image: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    embedCallback: undefined
                },
                emoji: {
                    className: undefined,
                    component: undefined,
                    popupClassName: undefined,
                    embedCallback: undefined
                },
                history: {
                    undo: { className: undefined },
                    redo: { className: undefined }
                }
            }}
        />
    );
};
export default EditorStyledToolbar;
