import loaderReducer from './loaderReducer';
import toastReducer from './toastReducer';
import { combineReducers } from 'redux';
import { createMultilanguageReducer } from 'redux-multilanguage';
import authReduer from './authReduer';

const rootReducer = combineReducers({
    multilanguage: createMultilanguageReducer({ currentLanguageCode: 'en' }),
    loading: loaderReducer,
    auth: authReduer,
    toastReducer
});

export default rootReducer;
