/*eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Typography,
  Fade,
  Divider,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  Chip,
  Stack,
} from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import { multilanguage } from "redux-multilanguage";
import { useNavigate } from "react-router-dom";

import ModalBox from "../../components/ModalBox/ModalBox";
import SearchBox from "../../components/SearchBox/SearchBox";
import ControlledSwitches from "../../components/SwitchToggler/SwitchToggler";
import FilterRadioButton from "../../components/FIlterRadioButton/FilterRadioButton";
import CustomLoadingButton from "../../components/CustomLoadingButton/CustomLoadingButton";

import instance from "../../api/axios";
import { isCheckValueAndSetParams } from "../../utils/helper";
import useWindowDimension from "../../hooks/useWindowDimension";

import "./UserManagerment.scss";
import { MetaTags } from "react-meta-tags";

const UserManagerment = (props) => {
  const { strings } = props;
  console.log("123");
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const [searchText, setSearchText] = useState({
    search: "",
    page: 1,
    limit: 15,
    sortBy: { createdAt: -1 },
  });
  const [pageLoad, setPageLoad] = useState(true);
  const [selectDateRadio, setSelectDateRadio] = useState(null);
  const [selectUsageRadio, setSelectUsageRadio] = useState(null);
  const [selectSubscribeRadio, setSelectSubscribeRadio] = useState(null);
  const [deleteUserData, setDeleteUserData] = useState(false);
  const [disableUserData, setDisableUserData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const scrollBar = useRef();
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    userListApi();
  }, [searchText]);

  const [userData, setUserData] = useState([]);
  const [dataValue, setDataValue] = useState();

  const userListApi = async () => {
    let params = "";
    if (searchText.page) {
      params = `?page=${searchText.page}`;
    }
    if (searchText.sortBy) {
      params += `&sortBy=${JSON.stringify(searchText.sortBy)}`;
    }
    if (searchText.search) {
      params += `&search=${searchText.search}`;
    }
    if (searchText.limit) {
      params += `&limit=${searchText.limit}`;
    }
    console.log("selectSubscribeRadio", selectSubscribeRadio);
    await instance
      .get(
        `/user/userList${params}${isCheckValueAndSetParams(
          "&key=",
          selectDateRadio
        )}${isCheckValueAndSetParams(
          "&subscription=",
          selectSubscribeRadio
        )}${isCheckValueAndSetParams("&payment=", selectUsageRadio)}`
      )
      .then((res) => {
        if (res.payload.user.length === 0) {
          setPageLoad(false);
        }
        if (searchText.page === 1) {
          console.log("if condition");
          setUserData(res.payload.user);
        } else {
          console.log("else condition");
          setUserData(userData?.concat(res.payload.user));
        }
      })
      .catch((e) => {
        console.log("response", e);
      });
  };

  const userDeleteApi = async () => {
    await instance
      .delete(`/user/user/${dataValue?._id}`)
      .then((res) => {
        setUserData(userData.filter((item) => item._id !== dataValue._id));
        console.log("delete", res);
      })
      .catch((e) => {
        console.log("delete", e);
      });
  };

  const userStatusApi = async (value) => {
    const params = {
      id: dataValue._id,
      sStatus: value,
    };
    await instance
      .put("/user/user/updateStatus/", params)
      .then((res) => {
        setTimeout(() => {}, 3000);
        userListApi();
      })
      .catch((e) => {
        console.log("delete", e);
      });
  };

  const [headerHeight, setHeaderHeight] = useState(0);
  const [SectionHeight, setSectionHeight] = useState(0);
  const [SearchHeight, setSearchHeight] = useState(0);
  useEffect(() => {
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setSectionHeight(
      document.getElementsByClassName("sectionTitle")[0]?.offsetHeight
    );
    setSearchHeight(
      document.getElementsByClassName("searchBoxDiv")[0]?.offsetHeight
    );
  }, []);
  const handleResize = () => {
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setSectionHeight(
      document.getElementsByClassName("sectionTitle")[0]?.offsetHeight
    );
    setSearchHeight(
      document.getElementsByClassName("searchBoxDiv")[0]?.offsetHeight
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <MetaTags>
        <title>
          {" "}
          User Management | Mouthmap - Your Route To A Healthy Mouth{" "}
        </title>
        <meta
          name="description"
          content="See all current app users and view their subscription status, regular clinic, date of registration, and lots more. "
        />
        <meta
          property="og:title"
          content=" User Management | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content="See all current app users and view their subscription status, regular clinic, date of registration, and lots more."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>
      <Box
        className="userManageMent"
        style={{
          marginTop: headerHeight,
          height: dimensions.height - headerHeight - 20,
        }}
      >
        <Box className="userManagermentWrapper">
          <Box className="userManagementCountainer">
            <Box className="sectionTitle">
              <Typography className="title" variant="h5">
                Manage users
              </Typography>
            </Box>
            <Box className="customSearchCardRow">
              <Box className="customSearchCard">
                <SearchBox
                  placeholder="Search by User name"
                  value={searchText.search}
                  onSearch={(e) => {
                    setSearchText({ ...searchText, search: e, page: 1 });
                  }}
                  onClear={() => {
                    setSearchText({ ...searchText, search: "", page: 1 });
                  }}
                />
                <Box className="filerButton">
                  <Button
                    id="fade-button"
                    className="filterIcon"
                    aria-controls={openMenu ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    disableTouchRipple
                  >
                    <span className="icon-filter"></span>
                  </Button>
                  <Menu
                    className="filterMenu"
                    id="fade-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "fade-button",
                    }}
                    TransitionComponent={Fade}
                  >
                    <MenuItem className="filterMenuItem" disableTouchRipple>
                      <Box className="filterCardBoxModel">
                        <Box className="filterDateSection">
                          <Typography className="title" variant="h5">
                            Date
                          </Typography>
                          <Stack className="filterRadioButtonRow">
                            <FilterRadioButton
                              id="All time"
                              label="All time"
                              value="All_time"
                              changed={(e) =>
                                setSelectDateRadio(e.target.value)
                              }
                              isSelected={selectDateRadio === "All_time"}
                              onClick={() => console.log("3212312132132")}
                            />
                            {console.log(
                              selectDateRadio,
                              "selectDateRadioselectDateRadio"
                            )}
                            <FilterRadioButton
                              id="Last week"
                              label="Last week"
                              value="7day"
                              changed={(e) =>
                                setSelectDateRadio(e.target.value)
                              }
                              isSelected={selectDateRadio === "7day"}
                            />
                            <FilterRadioButton
                              id="Last month"
                              label="Last month"
                              value="lastmonth"
                              changed={(e) =>
                                setSelectDateRadio(e.target.value)
                              }
                              isSelected={selectDateRadio === "lastmonth"}
                            />
                          </Stack>
                        </Box>
                        <Divider />
                        <Box className="filterDateSection filterDateSection1">
                          <Typography className="title" variant="h5">
                            Usage
                          </Typography>
                          <Stack className="filterRadioButtonRow">
                            <FilterRadioButton
                              id="Paying"
                              label="Paying"
                              value="true"
                              changed={(e) =>
                                setSelectUsageRadio(e.target.value)
                              }
                              isSelected={selectUsageRadio === "true"}
                            />
                            <FilterRadioButton
                              id="Non paying"
                              label="Non paying"
                              value="false"
                              changed={(e) =>
                                setSelectUsageRadio(e.target.value)
                              }
                              isSelected={selectUsageRadio === "false"}
                            />
                          </Stack>
                        </Box>
                        <Divider />
                        <Box className="filterDateSection filterDateSection2">
                          <Typography className="title" variant="h5">
                            Subscription
                          </Typography>
                          <Stack className="filterRadioButtonRow">
                            <FilterRadioButton
                              id="Subscribed"
                              label="Subscribed"
                              value="true"
                              changed={(e) =>
                                setSelectSubscribeRadio(e.target.value)
                              }
                              isSelected={selectSubscribeRadio === "true"}
                            />
                            <FilterRadioButton
                              id="Non Subscribed"
                              label="Non Subscribed"
                              value="false"
                              changed={(e) =>
                                setSelectSubscribeRadio(e.target.value)
                              }
                              isSelected={selectSubscribeRadio === "false"}
                            />
                          </Stack>
                        </Box>
                        <Divider />
                        <Box className="filterDateSectionButtonGroup">
                          <CustomLoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                            btnTitle="Apply"
                            onClick={() => {
                              setAnchorEl(null);
                              userListApi();
                              if (selectDateRadio === "All_time") {
                                userListApi();
                              }
                            }}
                            className="submitButton"
                            // disable={!(selectSubscribeRadio !== 'Subscribed' && selectUsageRadio !== 'Paying' && selectDateRadio !== 'All time')}
                          />
                          {selectSubscribeRadio ||
                          selectUsageRadio ||
                          selectDateRadio ? (
                            <CustomLoadingButton
                              fullWidth
                              size="large"
                              type="button"
                              variant="text"
                              btnTitle="Clear"
                              className="clear"
                              onClick={() => {
                                setSelectSubscribeRadio(null);
                                setSelectUsageRadio(null);
                                setSelectDateRadio(null);
                              }}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Scrollbars
                className="userManagementDatatable"
                onScroll={() => {
                  if (
                    Math.round(
                      scrollBar?.current.getScrollTop() +
                        scrollBar?.current.getClientHeight()
                    ) === scrollBar?.current.getScrollHeight()
                  ) {
                    if (pageLoad) {
                      setSearchText({
                        ...searchText,
                        page: searchText.page + 1,
                      });
                    }
                  }
                }}
                ref={scrollBar}
                style={{
                  height:
                    dimensions.height -
                    headerHeight -
                    SectionHeight -
                    SearchHeight -
                    93,
                }}
              >
                <Table stickyHeader className="UserManagementtable">
                  <TableHead className="tableHeader">
                    <TableRow className="tableHeaderRow">
                      <TableCell className="mobilesizehide tableHeaderColumn">
                        User Name{" "}
                        <span
                          className="icon icon-sort-icon"
                          onClick={() => {
                            const sb = {};
                            if (
                              searchText.sortBy &&
                              searchText.sortBy.sFirstName &&
                              searchText.sortBy.sFirstName === -1
                            ) {
                              sb.sFirstName = 1;

                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            } else if (
                              searchText.sortBy &&
                              searchText.sortBy.sFirstName &&
                              searchText.sortBy.sFirstName === 1
                            ) {
                              sb.sFirstName = -1;
                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            } else {
                              sb.sFirstName = 1;
                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            }
                          }}
                        ></span>
                      </TableCell>
                      <TableCell className="mobilesizehide tableHeaderColumn">
                        Email{" "}
                        <span
                          className="icon icon-sort-icon"
                          onClick={() => {
                            const sb = {};
                            if (
                              searchText.sortBy &&
                              searchText.sortBy.sEmail &&
                              searchText.sortBy.sEmail === -1
                            ) {
                              sb.sEmail = 1;

                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            } else if (
                              searchText.sortBy &&
                              searchText.sortBy.sEmail &&
                              searchText.sortBy.sEmail === 1
                            ) {
                              sb.sEmail = -1;
                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            } else {
                              sb.sEmail = 1;
                              setSearchText({
                                ...searchText,
                                sortBy: sb,
                                page: 1,
                              });
                              setPageLoad(true);
                            }
                          }}
                        ></span>
                      </TableCell>
                      <TableCell className="mobilesizehide tableHeaderColumn">
                        Clinician Code
                      </TableCell>
                      <TableCell className="mobilesizeShow tableHeaderColumn">
                        User Details
                      </TableCell>
                      <TableCell className="tableHeaderColumn">
                        Registration Date
                      </TableCell>
                      <TableCell className="tableHeaderColumn">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="tableBody">
                    {userData.length > 0 ? (
                      userData.map((item, index) => {
                        const today = new Date(item.createdAt);
                        return (
                          <TableRow
                            onClick={() =>
                              navigate(
                                "/usermanagerment/usermanagementdetails",
                                { state: item }
                              )
                            }
                            className={
                              item.sStatus === "Active"
                                ? "tableBodyRow"
                                : "tableBodyRow disableColor"
                            }
                            key={index}
                          >
                            <TableCell className="tableBodyColumn">
                              <Box className="userDetails mobilesizehide">
                                <Typography
                                  className="userShortName"
                                  variant="body2"
                                >
                                  {item.sFirstName?.charAt(0) +
                                    item.sLastName?.charAt(0)}
                                </Typography>
                                {/* <p className='charcter'>{item?.sFirstname.charAt(0) + item?.sLastname.charAt(0)}</p> */}
                                <Typography
                                  className="userName"
                                  variant="body2"
                                >
                                  {item.sFirstName + " " + item.sLastName}
                                </Typography>
                              </Box>
                              <Box className="userDetailsMobileSizeShow">
                                <Box className="userDetailsMbilesizeRow">
                                  <Typography
                                    className="userShortName"
                                    variant="body2"
                                  >
                                    {item.sFirstName?.charAt(0) +
                                      item.sLastName?.charAt(0)}
                                  </Typography>
                                  <Box className="userDetailsMbilesizeRowInner">
                                    <Typography
                                      className="title"
                                      variant="body2"
                                    >
                                      {item.sFirstName + item.sLastName}
                                    </Typography>
                                    <Typography
                                      className="title"
                                      variant="body2"
                                    >
                                      {item.sEmail}
                                    </Typography>
                                    <Box className="userciliconeCode">
                                      <Typography
                                        className="titlKey"
                                        variant="body2"
                                      >
                                        ClinicianCode:
                                      </Typography>
                                      <Typography
                                        className="titleValue"
                                        variant="body2"
                                      >
                                        {" "}
                                        {item.sClinicianCode
                                          ? item.sClinicianCode
                                          : "-"}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell className="tableBodyColumn mobilesizehide">
                              {item.sEmail}
                            </TableCell>
                            <TableCell className="tableBodyColumn mobilesizehide">
                              {" "}
                              {item.sClinicianCode
                                ? item.sClinicianCode
                                : "N011"}
                            </TableCell>
                            <TableCell className="tableBodyColumn">
                              {today.toGMTString().substring(5, 16)}
                            </TableCell>
                            <TableCell className="tableBodyColumn">
                              <Box className="userDataStatus">
                                <Box className="userDataStatusChipActive">
                                  <Typography
                                    className={
                                      item.subscribed === false
                                        ? "hideSubcreibe subcreibeStatus"
                                        : "subcreibeStatus"
                                    }
                                  >
                                    Subscribed
                                  </Typography>
                                  <Chip
                                    label={item.sStatus}
                                    variant="outlined"
                                    className={
                                      item.sStatus === "Active"
                                        ? "chip"
                                        : "chip inactive"
                                    }
                                  />
                                </Box>
                                <Box className="statusModel">
                                  <Button
                                    className="deleteBtn"
                                    variant="text"
                                    onClick={(event) => {
                                      event.stopPropagation(event);
                                      setDeleteUserData(true);
                                      setDataValue(item);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                  <ControlledSwitches
                                    checked={item.sStatus !== "Inactive"}
                                    onClick={(e) => {
                                      setDisableUserData(true);
                                      setDataValue(item);
                                      e.stopPropagation(e);
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="5">
                          <Box className="no-data">
                            {searchText.search === ""
                              ? "Nothing to show yet"
                              : "No Such User Found"}
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbars>
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalBox
        open={deleteUserData}
        close={() => setDeleteUserData(false)}
        title="Delete!"
        warningText={`${
          strings.ARE_YOU_SURE_DELETE_USER +
          " " +
          dataValue?.sFirstName +
          " " +
          dataValue?.sLastName +
          "?"
        }`}
        onSubmit={() => {
          setDeleteUserData(false);
          userDeleteApi();
        }}
        button1="Yes, Delete"
      />
      <ModalBox
        open={disableUserData}
        close={() => setDisableUserData(false)}
        title={
          dataValue?.sStatus !== "Inactive" ? strings.DISABLE : strings.ENABLE
        }
        warningText={
          dataValue?.sStatus !== "Inactive"
            ? `${
                strings.ARE_YOU_SURE_DISABLE_USER +
                " " +
                dataValue?.sFirstName +
                " " +
                dataValue?.sLastName +
                "?"
              }`
            : `${
                strings.ARE_YOU_SURE_ENABLE_USER +
                " " +
                dataValue?.sFirstName +
                " " +
                dataValue?.sLastName +
                "?"
              }`
        }
        onSubmit={() => {
          setDisableUserData(false);
          if (dataValue?.sStatus === "Active") {
            userStatusApi("Inactive");
          } else {
            userStatusApi("Active");
          }
        }}
        button1={
          dataValue?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
    </>
  );
};

export default multilanguage(UserManagerment);
