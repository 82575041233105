import React from 'react';
import { multilanguage } from 'redux-multilanguage';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import ReactPlayer from 'react-player';
import Card from '@mui/material/Card';

import './VideoCard.scss';

const VideoCard = (props) => {
    const { strings } = props;
    const label = { inputProps: { 'aria-label': 'Switch' } };
    const deletePopup = (e) => {
        e.preventDefault();
        props.deletePopup();
    };

    return (
        <Card sx={{ maxWidth: 398 }} className="videoCard">
            {props.imageurl !== undefined &&
                <Box className='imageWrapper'
                    onClick={props.openVideoModal}
                >
                    <ReactPlayer
                        muted
                        width="100%"
                        height={100}
                        url={props.imageurl}
                    />
                    {/* {console.log('url==>', props.imageurl)} */}

                    <a href={props.onClickPlay} target='_blank' rel="noreferrer noopener" className="icon-play-filled"><span className="path1"></span><span className="path2"></span></a>
                </Box>
            }
            <CardContent className='cardContent rightWrap'>
                <Box className='cardTop'>
                    <Typography variant='h3' className='title'>{props.title}</Typography>
                </Box>
                <CardActions className="cardBottom">
                    <Typography gutterBottom variant="subtitle2" className='category' component="div">
                        <Box className="smallTitle">{strings.CATEGORY}</Box>
                        <Typography component="div">{props.category}</Typography>
                    </Typography>
                    <Typography variant="subtitle2" component="div" className={props.status === 'Active' ? 'active' : 'inactive'}>
                        <Box className="smallTitle">{strings.STATUS}</Box>
                        <Typography component="div" className="statusText">{props.status}</Typography>
                    </Typography>
                    <Box className={props.pin === 'Pin' ? 'rightSide' : 'rightSide hid'}>
                        <Button size="small" onClick={props.editPopup}>{strings.EDIT}</Button>
                        <Button size="small" className='deleteBtn' onClick={(e) => deletePopup(e)} >{strings.DELETE}</Button>
                        {/* <Switch {...label} defaultChecked disableTouchRipple onClick={(e) => props.disablePopup(e)} /> */}
                        <Switch {...label} checked={props?.checked} disableTouchRipple onClick={(e) => props.disablePopup(e)} />

                    </Box>
                </CardActions>
            </CardContent>
        </Card>
    );
};

export default multilanguage(VideoCard);
