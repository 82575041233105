import React from 'react';
import { multilanguage } from 'redux-multilanguage';
import { Box } from '@mui/material';

import './SearchBox.scss';

/* eslint-disable */
const SearchBox = (props) => {
    let { placeholder, onSearch, onClear, value, disabled } = props
    return (
        <>
            <Box className='searchBoxDiv SearchWrap'>
                <span className='icon icon-search2'></span>
                <Box className='inputWrap'>
                    <input autoComplete='off' type='text' name='focus' maxLength={200} disabled={disabled} className='searchInput' value={value} placeholder={placeholder} onChange={(e) => { onSearch && onSearch(e.target.value.trimLeft()); }} />
                </Box>
                {value !== undefined && value !== '' ? <button className='icon-close clearIcon' type='reset' onClick={() => { onClear && onClear(), props.setOrderNotFoundMsg && props.setOrderNotFoundMsg(false); }}></button > : null}
                {props.children}
            </Box>
        </>
    );
};

export default multilanguage(SearchBox);