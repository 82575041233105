import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

import './CustomLoadingButton.scss';

const CustomLoadingButton = (props) => {
    const { onClick, disabled, className, btnTitle, type, fullWidth, color, startIcon, variant, endIcon, loading, loadingPosition } = props;
    return (
        <div className='customLoadingBtnMain'>
            <LoadingButton
                loading={loading}
                loadingPosition={loadingPosition}
                variant={variant}
                type={type}
                color={color}
                onClick={onClick}
                disabled={disabled}
                fullWidth={fullWidth}
                className={`customLoadingBtn ${className}`}
                startIcon={<i className={startIcon}/>}
                endIcon={endIcon}
            >
                {btnTitle}
            </LoadingButton>
        </div>
    );
};

export default CustomLoadingButton;
