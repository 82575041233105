import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { multilanguage, loadLanguages } from 'redux-multilanguage';
import { connect } from 'react-redux';
import routes from './routes';

import ToastComponent from './components/ToastComponent/ToastComponent';
import { hideToast } from './redux/actions/toastAction';

import './App.scss';

const App = (props) => {
    useEffect(() => {
        props.dispatch(
            loadLanguages({
                languages: {
                    en: require('./translations/english.json')
                }
            })
        );
    });

    window.onbeforeunload = (e) => {
        props.hideToast();
    };

    return (
        <div className="App">
            <Suspense fallback>
                <ToastComponent />
                <BrowserRouter basename="/">
                    <Routes>
                        {routes.map((route, index) => {
                            return <Route
                                key={index}
                                exact={route.exact}
                                path={route.path}
                                element={route.component}
                            />;
                        })}
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {
    hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(App));
