/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Typography,
  Container,
  Grid,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { useLocation } from "react-router-dom";

import { jsPDF as JsPDF } from "jspdf";
import html2canvas from "html2canvas";

import AddModal from "../../components/AddModal/AddModal";

import useWindowDimension from "../../hooks/useWindowDimension";

import "./ViewOrderDetails.scss";
import { MetaTags } from "react-meta-tags";
import moment from "moment";

const ViewOrderDetails = () => {
  const dimensions = useWindowDimension();
  const location = useLocation();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mainTitleHeight, setMainTitleHeight] = useState(0);
  const [detailsSectionHeight, setDetailsSectionHeight] = useState(0);
  const [addressWrapHeight, setAddressWrapHeight] = useState(0);
  const [orderDetailsPopup, setOrderDetailsPopup] = useState({
    value: "",
    type: false,
  });
  const data = location.state;
  console.log(`stateData ----->>>>`, data);
  console.log("orderItem", data);
  const today = new Date(data?.orderPlacedDate);
  useEffect(() => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setDetailsSectionHeight(
      document.getElementsByClassName("particulerUserDetails")[0]?.offsetHeight
    );
    setAddressWrapHeight(
      document.getElementsByClassName("addressWrap")[0]?.offsetHeight
    );
  }, []);
  const handleResize = () => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setDetailsSectionHeight(
      document.getElementsByClassName("particulerUserDetails")[0]?.offsetHeight
    );
    setAddressWrapHeight(
      document.getElementsByClassName("addressWrap")[0]?.offsetHeight
    );
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  const orderProductsData = [
    {
      id: "1",
      product: {
        image:
          "https://res.cloudinary.com/dygllydcg/image/upload/v1689830118/MouthMap/Web/Static-images/peakflow.webp",
        category: "Healthcare Oral",
        name: "Interdental Brush Size-2",
        subscriptionid: "123",
      },
      ordertype: "Subscribed",
      price: "£20",
      quantity: "2",
      amount: "£40",
      discount: "£5",
      subtotal: "£35",
    },
    {
      id: "2",
      product: {
        image:
          "https://res.cloudinary.com/dygllydcg/image/upload/v1689830118/MouthMap/Web/Static-images/peakflow.webp",
        category: "Healthcare Oral",
        name: "Interdental Brush Size-2",
      },
      ordertype: "One Time",
      price: "£20",
      quantity: "2",
      amount: "£40",
      discount: "£5",
      subtotal: "£35",
    },
    {
      id: "3",
      product: {
        image:
          "https://res.cloudinary.com/dygllydcg/image/upload/v1689830118/MouthMap/Web/Static-images/peakflow.webp",
        category: "Healthcare Oral",
        name: "Interdental Brush Size-2",
      },
      ordertype: "One Time",
      price: "£20",
      quantity: "2",
      amount: "£40",
      discount: "£5",
      subtotal: "£35",
    },
  ];
  const orderStatus = data?.orderDetail?.sStatus;
  let total_amount = 0;
  let total_discount = 0;

  const downloadPDFGroups = () => {
    var seconds = new Date().getTime() / 1000;
    const input = document.getElementById("orderDetailBox");
    html2canvas(input).then((canvas) => {
      // const imgWidth = 208;
      // const imgHeight = canvas.height * imgWidth / canvas.width;
      const imgData = canvas.toDataURL("img/png/jpeg/jpg");
      const pdf = new JsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save(`orderDetail_${seconds}.pdf`);
    });
  };
  return (
    <>
      <MetaTags>
        <title>
          {" "}
          Order Details | Mouthmap - Your Route To A Healthy Mouth{" "}
        </title>
        <meta
          name="description"
          content="View client order history, download order summaries, and see which brands and products are the most popular."
        />
        <meta
          property="og:title"
          content=" Order Details | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content="View client order history, download order summaries, and see which brands and products are the most popular."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>

      <Box
        className="orderDetails"
        style={{ marginTop: headerHeight }}
        id="orderDetailBox"
      >
        <Box className="orderDetailsContainer">
          <Container>
            <Typography variant="h1" className="mainTitle">
              Order Details{" "}
              <i
                className="icon-download"
                onClick={() => {
                  downloadPDFGroups();
                }}
              ></i>
            </Typography>
            <Stack className="particulerUserDetails" direction="row">
              <Box className="particuleruserDetailsColumn">
                <Typography variant="h5" className="userSHortName">
                  {data?.userDetail?.sFirstName?.charAt(0) +
                    data?.userDetail?.sLastName?.charAt(0)}
                </Typography>
                <Typography variant="h5" className="fullName">
                  {data.userDetail.sFirstName + " " + data.userDetail.sLastName}
                </Typography>
              </Box>
              <Box className="particuleruserDetailsColumn">
                <Box className="divided"></Box>
                <Typography variant="h5" className="orderDate">
                  Order Date:
                </Typography>
                <Typography variant="h5" className="orderTime">
                  &nbsp;{moment(data?.orderDetail?.orderPlacedDate).format('DD MMM YYYY') || "-"}
                </Typography>
              </Box>
              <Box className="particuleruserDetailsColumn">
                <Box className="divided"></Box>
                <Typography variant="h5" className="orderId">
                  Order id:
                </Typography>
                <Typography variant="h5" className="orderIdNumber">
                  {"#" + data?.orderDetail.sOrderNo}
                </Typography>
              </Box>
            </Stack>
            <Grid container spacing={2} className="addressWrap">
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Billing Address:
                  </Typography>
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].fullName}</Typography> */}
                  {/* <Typography variant='body2'>3535 Isaacs Creek Road Jacksonville, LN E1 6AN,UK</Typography> */}
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].sHouseNo + '  ' + data?.userData?.sAddress[0].sAddressLine1 + '  ' + data?.userData?.sAddress[0].sAddressLine2 + '  ' + data?.userData?.sAddress[0].sCityName.city + '  ' +
                                    data?.userData?.sAddress[0].stateName.state + ',  ' + data?.userData?.sAddress[0].sCountryName.country}</Typography> */}
                  <Typography variant="body2">
                    {/* {`${data.billingDetail.sAddressLine1} ${data.billingDetail.sAddressLine2}, ${data.billingDetail.sCityName.sCityName}, ${data.billingDetail.sCountryName.sCountryName}`} */}

                    {`${data?.billingDetail?.sHouseNo
                      ? data?.billingDetail?.sHouseNo
                      : ""
                      } ${data?.billingDetail?.sAddressLine1
                        ? data?.billingDetail?.sAddressLine1
                        : ""
                      }, ${data?.billingDetail?.sAddressLine2
                        ? data?.billingDetail?.sAddressLine2
                        : ""
                      }, ${data?.billingDetail?.sCityName?.value
                        ? data?.billingDetail?.sCityName?.value
                        : ""
                      },${data?.billingDetail?.stateName?.value
                        ? data?.billingDetail?.stateName?.value
                        : ""
                      },${data?.billingDetail?.sCountryName?.value
                        ? data?.billingDetail?.sCountryName?.value
                        : ""
                      }-${data?.billingDetail?.sPostCode
                        ? data?.billingDetail?.sPostCode
                        : ""
                      }`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Shipping Address:
                  </Typography>
                  {/* <Typography variant='body2'>{data.userData?.sAddress[0].fullName}</Typography> */}
                  {/* <Typography variant='body2'>3535 Isaacs Creek Road Jacksonville, LN E1 6AN,UK</Typography> */}
                  {/* <Typography variant='body2'>{data.userData?.sAddress[0].sHouseNo + '  ' + data.userData?.sAddress[0].sAddressLine1 + '  ' + data.userData?.sAddress[0].sAddressLine2 + '  ' + data.userData?.sAddress[0].sCityName.city + '  ' +
                                    data.userData?.sAddress[0].stateName.state + ',  ' + data.userData?.sAddress[0].sCountryName.country}</Typography> */}
                  <Typography variant="body2">{`${data?.shippingDetail?.sHouseNo
                    ? data?.shippingDetail?.sHouseNo
                    : ""
                    } ${data?.shippingDetail?.sAddressLine1
                      ? data?.shippingDetail?.sAddressLine1
                      : ""
                    }, ${data?.shippingDetail?.sAddressLine2
                      ? data?.shippingDetail?.sAddressLine2
                      : ""
                    }, ${data?.shippingDetail?.sCityName?.value
                      ? data?.shippingDetail?.sCityName?.value
                      : ""
                    },${data?.shippingDetail?.stateName.value
                      ? data?.shippingDetail?.stateName?.value
                      : ""
                    },${data?.shippingDetail?.sCountryName?.value
                      ? data?.shippingDetail?.sCountryName?.value
                      : ""
                    }-${data?.shippingDetail?.sPostCode
                      ? data?.shippingDetail?.sPostCode
                      : ""
                    }`}</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Scrollbars
              className="orderDetailsDataTable"
              style={{
                height:
                  dimensions.height -
                  headerHeight -
                  mainTitleHeight -
                  detailsSectionHeight -
                  addressWrapHeight -
                  20,
              }}
            >
              <Table
                stickyHeader
                className="orderDetailsTable"
                id="orderDetailTable"
              >
                <TableHead className="tableHeader">
                  <TableRow className="tableHeaderRow">
                    <TableCell className="tableHeaderColumn">Product</TableCell>
                    <TableCell className="tableHeaderColumn">
                      Order Type
                    </TableCell>
                    <TableCell className="tableHea  derColumn">
                      <Typography component="span" className="desktopItem">
                        Price
                      </Typography>
                    </TableCell>
                    <TableCell className="tableHeaderColumn">
                      Quantity
                    </TableCell>
                    <TableCell className="tableHeaderColumn">Amount</TableCell>
                    <TableCell className="tableHeaderColumn">
                      Discount
                    </TableCell>
                    <TableCell className="tableHeaderColumn">
                      Subtotal
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {data.products.map((item, index) => {
                    total_amount += item?.sPrice * item?.sQuantity;
                    total_discount += item?.sDiscount * item?.sQuantity;
                    console.log("orderProductsData", orderProductsData);
                    return (
                      <TableRow
                        key={index}
                        onClick={() =>
                          setOrderDetailsPopup({ value: item, type: true })
                        }
                        className={
                          item.sStatus === "Active"
                            ? "tableBodyRow"
                            : "tableBodyRow disableColor"
                        }
                      >
                        <TableCell className="tableBodyColumn">
                          <Box className="listingImageBoxRow" display="flex">
                            <img
                              src={
                                process.env.REACT_APP_AWS_BASE_URL +
                                item.productDetail?.sProductImage[0]
                              }
                              alt="listingImage"
                              className="image"
                              height="70"
                              width="70"
                            />
                            <Box className="productDetails" display="flex">
                              <Typography className="productCtegory">
                                {item.brandDetail?.sBrandName}
                              </Typography>
                              <Box className='mainBox'>
                                <Typography className='brushColor' style={{ backgroundColor: item.productDetail?.color ? item.productDetail?.color : 'black' }}> </Typography>
                                <Typography className="productName">
                                  {item.productDetail?.sName}
                                </Typography>
                                <Typography className='productSize'>{item.productDetail?.size ? item.productDetail?.size : '-'}</Typography>
                              </Box>
                              {/* <Typography className='productSubscriptionId'>{item.product.subscriptionid ? 'Subscription Id:' + '#' + item.product.subscriptionid : '' }</Typography> */}
                              {/* <Typography className='productSubscriptionId'>{ item?.productDetail?.sMonthly_subScription ? 'Subscription Id: #' : '' }</Typography> */}
                              <Box className="responsiveItem">
                                <Typography
                                  component="span"
                                  className={
                                    item?.productDetail?.sMonthly_subScription
                                      ? "orderType subscribed"
                                      : "orderType"
                                  }
                                >
                                  {item?.productDetail?.sMonthly_subScription
                                    ? "Subscribed"
                                    : "One Time"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          <Typography
                            component="span"
                            className={
                              item?.productDetail?.sMonthly_subScription
                                ? "orderType subscribed"
                                : "orderType"
                            }
                          >
                            {item?.productDetail?.sMonthly_subScription
                              ? "Subscribed"
                              : "One Time"}
                          </Typography>
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          <Typography className="desktopItem" component="span">
                            {item.sPrice}
                          </Typography>
                          <Typography
                            className="responsiveItem"
                            component="span"
                          >
                            Price: {item?.sPrice}
                          </Typography>
                          <Box className="responsiveItem">
                            Quantity: {item?.sQuantity}
                          </Box>
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          {item?.sQuantity}
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          {(item?.sPrice * item?.sQuantity).toFixed(2)}
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          {(item?.sDiscount * item?.sQuantity).toFixed(2)}
                        </TableCell>
                        <TableCell className="tableBodyColumn">
                          {(
                            item?.sPrice * item?.sQuantity -
                            item?.sDiscount * item?.sQuantity
                          ).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow className="totalCountRows">
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Total Amount
                      <br />
                      {`£${total_amount.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      Total Discount
                      <br />
                      {`£${total_discount.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      Grand Total
                      <br />
                      {`£${(total_amount - total_discount).toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Shipping Charge
                      <br />£{data?.orderDetail?.shippingCharges ? data.orderDetail.shippingCharges : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      Paid Amount
                      <Typography component="div" className="totalPaid">{`£${(
                        total_amount -
                        total_discount +
                        (data?.orderDetail?.shippingCharges ? data?.orderDetail?.shippingCharges : 0)
                      ).toFixed(2)}`}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Scrollbars>
          </Container>
        </Box>
        <AddModal
          open={orderDetailsPopup.type}
          close={() => setOrderDetailsPopup({ value: "", type: false })}
        >
          <Box sx={style} className="addModalWrap orderDetailsPopup">
            <Typography id="modal-modal-title" variant="h5">
              Order Details
              <Typography
                component="span"
                className="icon-close clearIcon"
                onClick={() => {
                  setOrderDetailsPopup(false);
                }}
              ></Typography>
            </Typography>
            <Box className="detailsTop" display="flex">
              <Box className="imageWrap">
                <img
                  src={
                    process.env.REACT_APP_AWS_BASE_URL +
                    orderDetailsPopup?.value?.productDetail?.sProductImage[0]
                  }
                  height="115"
                  width="115"
                  alt="Order Image"
                />
              </Box>
              <Box className="productDetails">
                <Typography variant="subtitle2" className="pCategory">
                  {orderDetailsPopup?.value?.brandDetail?.sBrandName}
                </Typography>
                <Box className='mainBox'>
                  <Typography className='brushColor' style={{ backgroundColor: orderDetailsPopup?.value?.productDetail?.color ? orderDetailsPopup?.value?.productDetail?.color : 'black' }}> </Typography>
                  <Typography variant="subtitle2" className="pName">
                    {orderDetailsPopup?.value?.productDetail?.sName}
                  </Typography>
                </Box>
                <Typography component="div" className="pDigits">
                  price :{" "}
                  <Typography className="pPrice" component="span">
                    {orderDetailsPopup?.value?.sPrice?.toFixed(2)}
                  </Typography>
                  Quantity :{" "}
                  <Typography className="pQuantity" component="span">
                    {orderDetailsPopup?.value?.sQuantity}
                  </Typography>
                  Discount :{" "}
                  <Typography className="pDiscount" component="span">
                    {(
                      orderDetailsPopup?.value?.sDiscount *
                      orderDetailsPopup?.value?.sQuantity
                    )?.toFixed(2)}
                  </Typography>
                  <Typography className="pQuantity" component="span">
                    {orderDetailsPopup?.value?.productDetail?.size ? orderDetailsPopup?.value?.productDetail?.size : '-'}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box className="orderDetails" display="flex">
              <Typography component="span" className="orderId">
                {orderDetailsPopup?.value?.productDetail?.sMonthly_subScription
                  ? "Subscription Id:"
                  : `Order Id: #${data?.orderDetail?.sOrderNo}`}
              </Typography>
              <Typography
                component="span"
                className={
                  orderDetailsPopup?.value?.productDetail?.sMonthly_subScription
                    ? "orderType subscribed"
                    : "orderType"
                }
              >
                {orderDetailsPopup?.value?.productDetail?.sMonthly_subScription
                  ? "Subscribed"
                  : "One Time"}
              </Typography>
              <Typography component="span" className="orderDate">
                Order Date:{" "}
                <Typography component="span">
                  {moment(orderDetailsPopup?.value?.orderDetail?.orderPlacedDate).format('DD MMM YYYY')}
                </Typography>
              </Typography>
              <Typography component="span" className="orderStatus">
                Status:{" "}
                <Typography
                  component="span"
                  className={
                    orderStatus === "IN_ORDER"
                      ? "red status"
                      : orderStatus === "On The Way"
                        ? "green status"
                        : orderStatus === "Unsubscribed"
                          ? "red status"
                          : "status"
                  }
                >
                  {orderStatus.replace(/_/g, " ")}
                </Typography>
              </Typography>
            </Box>
            <Grid container spacing={2} className="addressWrap">
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Billing Address:
                  </Typography>
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].fullName}</Typography> */}
                  <Typography variant="body2">{`${data.billingDetail.sAddressLine1} ${data.billingDetail.sAddressLine2}, ${data.billingDetail.sCityName}, ${data.billingDetail.sCountryName.value}`}</Typography>
                  {/* <Typography variant='body2'>3535 Isaacs Creek Road Jacksonville, LN E1 6AN,UK</Typography> */}
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].sHouseNo + '  ' + data?.userData?.sAddress[0].sAddressLine1 + '  ' + data?.userData?.sAddress[0].sAddressLine2 + '  ' + data?.userData?.sAddress[0].sCityName.city + '  ' +
                                    data?.userData?.sAddress[0].stateName.state + ',  ' + data?.userData?.sAddress[0].sCountryName.country}</Typography> */}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Shipping Address:
                  </Typography>
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].fullName}</Typography> */}
                  <Typography variant="body2">{`${data.shippingDetail.sAddressLine1} ${data.shippingDetail.sAddressLine2}, ${data.shippingDetail.sCityName}, ${data.shippingDetail.sCountryName?.value}`}</Typography>
                  {/* <Typography variant='body2'>3535 Isaacs Creek Road Jacksonville, LN E1 6AN,UK</Typography> */}
                  {/* <Typography variant='body2'>{data?.userData?.sAddress[0].sHouseNo + '  ' + data?.userData?.sAddress[0].sAddressLine1 + '  ' + data?.userData?.sAddress[0].sAddressLine2 + '  ' + data?.userData?.sAddress[0].sCityName.city + '  ' +
                                    data?.userData?.sAddress[0].stateName.state + ',  ' + data?.userData?.sAddress[0].sCountryName.country}</Typography> */}
                </Stack>
              </Grid>
            </Grid>
            <Box className="totalNumbers">
              <Typography component="div">
                Total amount
                <Typography component="span">
                  £
                  {(
                    orderDetailsPopup?.value?.sPrice *
                    orderDetailsPopup?.value?.sQuantity
                  )?.toFixed(2)}
                </Typography>
              </Typography>
              <Typography component="div">
                Promocode discount
                <Typography component="span">
                  £
                  {Math.round(
                    orderDetailsPopup?.value?.sDiscount *
                    orderDetailsPopup?.value?.sQuantity
                  )?.toFixed(2)}
                </Typography>
              </Typography>
              <Typography component="div">
                Grand Total
                <Typography component="span">
                  £
                  {(
                    orderDetailsPopup?.value?.sPrice *
                    orderDetailsPopup?.value?.sQuantity -
                    parseInt(
                      orderDetailsPopup?.value?.sDiscount *
                      orderDetailsPopup?.value?.sQuantity
                    )
                  )?.toFixed(2)}
                </Typography>
              </Typography>
            </Box>
            {orderDetailsPopup.value === "Subscribed" ? (
              <Box className="summary">
                <Typography variant="subtitle" component="div">
                  Summary
                </Typography>
                <Box className="statusWrap">
                  <Box className="updateLetest commonWrap">
                    <Typography
                      className={
                        orderStatus === "IN_ORDER"
                          ? "red icon icon-time-status"
                          : orderStatus === "On The Way"
                            ? "green icon icon-time-status"
                            : orderStatus === "Unsubscribed"
                              ? "red icon icon-time-status"
                              : "icon icon-time-status"
                      }
                      component="span"
                    ></Typography>
                    <Box className="statusDetails" display="flex">
                      <Box className="left">
                        <Box
                          className={
                            orderStatus === "IN_ORDER"
                              ? "red productName"
                              : orderStatus === "On The Way"
                                ? "green productName"
                                : orderStatus === "Unsubscribed"
                                  ? "red productName"
                                  : "productName"
                          }
                        >
                          {orderStatus === "Delivered"
                            ? "Next billing: Upcoming in next 30 days"
                            : orderStatus === "Unsubscribed"
                              ? "Unsubscribed"
                              : "Interdental brush size-1"}
                        </Box>
                        <Box className="details2">
                          <Typography component="span">
                            {orderStatus === "On The Way"
                              ? "15 Mar 2021"
                              : orderStatus === "Unsubscribed"
                                ? "15 Mar 2021"
                                : ""}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="right">
                        <Typography className="amount" component="div">
                          {orderStatus === "Unsubscribed" ? "" : "£10"}
                        </Typography>
                        <Typography
                          component="span"
                          className={
                            orderStatus === "IN_ORDER"
                              ? "red orderState bold"
                              : orderStatus === "On The Way"
                                ? "green orderState bold"
                                : orderStatus === "Unsubscribed"
                                  ? "red orderState bold"
                                  : "orderState bold"
                          }
                        >
                          {orderStatus === "Delivered"
                            ? ""
                            : orderStatus === "Unsubscribed"
                              ? ""
                              : orderStatus}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="updateLast commonWrap">
                    <Typography className="icon" component="span">
                      <img
                        src="https://res.cloudinary.com/dygllydcg/image/upload/v1689830158/MouthMap/Web/Static-images/checked-status.svg"
                        width="18"
                        height="18"
                        alt="icon checked"
                      />
                    </Typography>
                    <Box className="statusDetails" display="flex">
                      <Box className="left">
                        <Box className='mainBox'>
                          <Typography className='brushColor' style={{ backgroundColor: '#B468BD' }}> </Typography>
                          <Box className="productName">
                            Interdental brush
                          </Box>
                          <Typography className='productSize'>Size-1</Typography>
                        </Box>
                        <Box className="details2">
                          <Typography component="span" className="orderState">
                            15 Mar 2021
                          </Typography>
                          Subscription id: #123
                        </Box>
                      </Box>
                      <Box className="right">
                        <Typography className="amount" component="div">
                          £10
                        </Typography>
                        <Typography className="delivery" component="div">
                          Delivered
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="updateInitial commonWrap">
                    <Typography className="icon" component="span">
                      <img
                        src="https://res.cloudinary.com/dygllydcg/image/upload/v1689830158/MouthMap/Web/Static-images/checked-status.svg"
                        width="18"
                        height="18"
                        alt="icon checked"
                      />
                    </Typography>
                    <Box className="statusDetails" display="flex">
                      <Box className="left">
                        <Box className='mainBox'>
                          <Typography className='brushColor' style={{ backgroundColor: '#B468BD' }}> </Typography>
                          <Box className="productName">
                            Interdental brush
                          </Box>
                          <Typography className='productSize'>Size-1</Typography>
                        </Box>
                        <Box className="details2">
                          <Typography component="span" className="orderState">
                            15 Mar 2021
                          </Typography>
                          Subscription id: #123
                        </Box>
                      </Box>
                      <Box className="right">
                        <Typography className="amount" component="div">
                          £10
                        </Typography>
                        <Typography className="delivery" component="div">
                          Delivered
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </AddModal>
      </Box>
    </>
  );
};

export default ViewOrderDetails;
