import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, TableHead, TableRow, TableCell, Table, TableBody, Chip, ButtonGroup } from '@mui/material';
import { multilanguage } from 'redux-multilanguage';
import Scrollbars from 'react-custom-scrollbars-2';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import moment from 'moment';

import ModalBox from '../../../components/ModalBox/ModalBox';
import HookForm from '../../../components/HookForm/HookForm';
import AddModal from '../../../components/AddModal/AddModal';
import SearchBox from '../../../components/SearchBox/SearchBox';
import CustomTextField from '../../../components/CustomTextField/CustomTextFeild';
import CustomRadioCard from '../../../components/CustomRadioCard/CustomRadioCard';
import CustomRadioButton from '../../../components/CustomRadioButton/CustomRadioButton';
import CustomLoadingButton from '../../../components/CustomLoadingButton/CustomLoadingButton';

import instance from '../../../api/axios';
import Constant from '../../../utils/constant';
import { showToast } from '../../../redux/actions/toastAction';
import useWindowDimension from '../../../hooks/useWindowDimension';

import './ManagerPromoCode.scss';
import { MetaTags } from 'react-meta-tags';

const ManagerPromoCode = (props) => {
    const { strings } = props;
    const dimensions = useWindowDimension();
    const [startDate, setStartDate] = useState(null);
    const [searchText, setsearchText] = useState({
        search: '',
        page: 1,
        limit: 15
    });
    const [busy, setBusy] = useState(false);
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };
    const [value, setValue] = useState(null);
    const min = null;
    const max = 100;
    const handleChange = event => {
        const value = Math.max(min, Math.min(max, Number(event?.target.value)));
        setValue(value);
    };
    const scrollBar = useRef();
    const [pageLoad, setPageLoad] = useState(true);
    const [addPromoCodeForm, setAddPromoCodeForm] = useState();
    const [deletePromoCode, setDeletePromoCode] = useState(false);
    const [addPromoCode, setAddPromoCode] = useState(false);
    // const [addValidationCalender, setAddValidationCalender] = useState(false);
    const [descreptionCount, setDescreptionCount] = useState(0);
    const [selectedApplicableValue, setSelectedApplicableValue] = useState('One time');
    const [selectedRecurrenceValue, setSelectedRecurrenceValue] = useState('Once');
    const [selectedCreteriaValue, setSelectedCreteriaValue] = useState('flatdiscount');
    const [addPromoCodeType, setAddPromoCodeType] = useState(null);
    const [managerPromoCodeData, setManagerPromoCodeData] = useState([]);
    const [promocodeCount, setPromocodeCount] = useState();
    const [managerId, setManagerId] = useState(null);
    const [disableEditForm, setDisableEditForm] = useState(false);
    const [incrementValue, setIncrementValue] = useState(1);
    const [sValidityDate, setSValidityDate] = useState(new Date());
    const incrementCounter = () => {
        setIncrementValue(incrementValue + 1);
    };
    const decrementCounter = () => {
        if (incrementValue > 1) {
            setIncrementValue(incrementValue - 1);
        }
    };
    const addPromoCodeFormValidationForm = {
        discount: {
            name: 'discount',
            validate: {
                required: {
                    value: true
                },
                pattern: {
                    value: Constant.REGEX.NUMBER,
                    message: 'Flat discount cannot be given more than product value criteria.'
                }
            }
        },
        amount: {
            name: 'amount',
            validate: {
                required: {
                    value: true,
                    message: 'Flat discount cannot be given more than product value criteria.'
                },
                pattern: {
                    value: Constant.REGEX.NUMBER,
                    message: 'Flat discount cannot be given more than product value criteria.'
                }
            }
        },
        promoCode: {
            name: 'promoCode',
            validate: {
                required: {
                    value: true,
                    message: ''
                },
                pattern: {
                    value: Constant.REGEX.ALPHANUMERICSPEICALCHARS,
                    message: ''
                }
            }
        },
        promoCodeDescreption: {
            name: 'promoCodeDescreption',
            validate: {
                required: {
                    value: true,
                    message: ''
                },
                pattern: {
                    value: Constant.REGEX.ALPHANUMERICSPEICALCHARS,
                    message: ''
                }
            }
        },
        validityDate: {
            name: 'validityDate',
            validate: {
                required: {
                    value: false,
                    message: 'Please Select Date'
                }
            }
        },
        minimumProductValue: {
            name: 'minimumProductValue',
            validate: {
                required: {
                    value: true,
                    message: ''
                },
                pattern: {
                    value: Constant.REGEX.NUMBER,
                    message: ''
                }
            }
        },
        minimumProductValueDiscount: {
            name: 'minimumProductValueDiscount',
            validate: {
                required: {
                    value: true,
                    message: ''
                },
                pattern: {
                    value: Constant.REGEX.NUMBER,
                    message: ''
                }
            }
        }
    };
    const handleSelectedAppicable = (event) => {
        setSelectedApplicableValue(event.target.value);
    };
    const handleSelectedRecurrence = (event) => {
        setSelectedRecurrenceValue(event.target.value);
    };
    const handleSelectedCreteria = (event) => {
        setSelectedCreteriaValue(event.target.value);
    };

    const editManagerPromoCodeHandle = (data) => {
        setAddPromoCode(true);
        setAddPromoCodeType(data);
        console.log('setAddPromoCodeType', addPromoCodeType);
    };

    const onFormSubmit = async (value) => {
        setBusy(true);
        if (managerId === null) {
            const params = {
                sApplicabletype: selectedApplicableValue
            };
            if (setSelectedApplicableValue === 'One time') {
                params.sPromocode = value.promoCode;
                params.sValiditydate = startDate;
                params.sPromoDes = value.promoCodeDescreption;
                params.sCriteria = selectedCreteriaValue;

                if (selectedCreteriaValue === 'discount') {
                    params.sDiscount = value.discount;
                    params.sMinproductval = value.minimumProductValueDiscount.toString();
                } else {
                    params.sAmount = value.amount;
                    params.sMinproductval = value.minimumProductValue.toString();
                }
            } else {
                params.sPromocode = value.promoCode;
                params.sValiditydate = startDate;
                params.sPromoDes = value.promoCodeDescreption;
                params.sRecurrence = selectedRecurrenceValue;
                if (selectedRecurrenceValue === 'Repeats') {
                    params.sRepeats = incrementValue;
                }
                params.sCriteria = selectedCreteriaValue;

                if (selectedCreteriaValue === 'discount') {
                    params.sDiscount = value.discount;
                    params.sMinproductval = value.minimumProductValueDiscount;
                } else {
                    params.sAmount = value.amount;
                    params.sMinproductval = value.minimumProductValue;
                }
            }
            console.log(params, 'params!!!');
            await instance.post('/promocode/addpromocode', params)
                .then((res) => {
                    setManagerId('');
                    setStartDate(null);
                    setAddPromoCode(false);
                    setTimeout(() => {
                        props.showToast({
                            message: strings.PROMO_ADDED_SUCCESS,
                            type: 'success'
                        });
                    }, 1000);
                    getPromocodeListApi();
                    setBusy(false);
                }).catch((e) => {
                });
        } else {
            const params = {
                sApplicabletype: selectedApplicableValue
            };
            if (setSelectedApplicableValue === 'One time') {
                params.sPromocode = value.promoCode;
                params.sValiditydate = startDate;
                params.sPromoDes = value.promoCodeDescreption;
                params.sCriteria = selectedCreteriaValue;

                if (selectedCreteriaValue === 'discount') {
                    params.sDiscount = value.discount;
                    params.sMinproductval = value.minimumProductValueDiscount.toString();
                } else {
                    params.sAmount = value.amount;
                    params.sMinproductval = value.minimumProductValue.toString();
                }
            } else {
                params.sPromocode = value.promoCode;
                params.sValiditydate = managerId.sValiditydate;
                params.sPromoDes = value.promoCodeDescreption;
                params.sRecurrence = selectedRecurrenceValue;
                if (selectedRecurrenceValue === 'Repeats') {
                    params.sRepeats = incrementValue;
                } else {
                    params.sRepeats = 0;
                }
                params.sCriteria = selectedCreteriaValue;

                if (selectedCreteriaValue === 'discount') {
                    params.sDiscount = value.discount;
                    params.sMinproductval = value.minimumProductValueDiscount.toString();
                } else {
                    params.sAmount = value.amount;
                    params.sMinproductval = value.minimumProductValue.toString();
                }
            }
            await instance.put(`/promocode/editpromocode?id=${managerId?._id}`, params)
                .then((res) => {
                    setManagerId(null);
                    setStartDate(null);
                    setAddPromoCode(false);
                    setTimeout(() => {
                        props.showToast({
                            message: strings.PROMO_EDIT_SUCCESS,
                            type: 'success'
                        });
                    }, 1000);
                    getPromocodeListApi();
                    setBusy(false);
                }).catch((e) => {
                });
        }
    };
    const [headerHeight, setHeaderHeight] = useState(0);
    const [SectionHeight, setSectionHeight] = useState(0);
    const [SearchHeight, setSearchHeight] = useState(0);

    useEffect(() => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSectionHeight(document.getElementsByClassName('sectionTitle')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchBoxDiv')[0]?.offsetHeight);
    }, []);
    const handleResize = () => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSectionHeight(document.getElementsByClassName('sectionTitle')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchBoxDiv')[0]?.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getPromocodeListApi();
    }, [searchText]);

    const getPromocodeListApi = async () => {
        let params = '';
        if (searchText.page) {
            params = `?page=${searchText.page}`;
        }
        if (searchText.search) {
            params += `&search=${searchText.search}`;
        }
        if (searchText.limit) {
            params += `&limit=${searchText.limit}`;
        }
        await instance.get(`/promocode/getpromocode${params}`)
            .then((res) => {
                setPromocodeCount(res.payload.promoCode);
                if (res.payload.getpromocode.length === 0) {
                    setPageLoad(false);
                }
                if (searchText.page === 1) {
                    setManagerPromoCodeData(res.payload.getpromocode);
                } else {
                    setManagerPromoCodeData(
                        managerPromoCodeData?.concat(res.payload.getpromocode)
                    );
                }
            }).catch((e) => {
            });
    };

    const deletePromoCodeApi = async () => {
        await instance.delete(`/promocode/deletepromocode/${managerId?._id}`)
            .then((res) => {
                getPromocodeListApi();
            }).catch((e) => {
            });
    };
    useEffect(() => {
    }, [busy]);

    const [flatvalue, setFlatvalue] = useState('');
    const [dissvalue, setDissvalue] = useState('');
    const [amountvalue, setAmountvalue] = useState('');
    const [dicountvalue, setDicountvalue] = useState('');
    console.log(flatvalue, 'flatvalue');
    return (
        <>
            <MetaTags>
                <title>Manage Promo Codes | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Manage all in-app promo and sales codes - set eligibility criteria, expiry dates, and other terms and conditions. " />
                <meta property="og:title" content="Manage Promo Codes | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Manage all in-app promo and sales codes - set eligibility criteria, expiry dates, and other terms and conditions.  " />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Box className='managerPromoCode' style={{ marginTop: headerHeight, height: dimensions.height - headerHeight }}>
                <Box className='managerPromoCodeContainer'>
                    <Box className='sectionTitle'>
                        <Typography variant='subtitle1' className='titleText'>Manage Promo Codes</Typography>
                    </Box>
                    <Box className='managerPromoCodeSearchRow'>
                        <SearchBox
                            placeholder='Search by Promo code'
                            value={searchText.search}
                            onSearch={(e) => {
                                setsearchText({ ...searchText, search: e, page: 1 });
                            }}
                            onClear={() => {
                                setsearchText({ ...searchText, search: '', page: 1 });
                            }}
                        />
                        <Button className='promoCodeButton' variant="outlined" startIcon={<i className='icon-plus-11'></i>} onClick={() => {
                            setManagerId(null); setAddPromoCode(true); setDescreptionCount(0); setAddPromoCodeType(null); setStartDate(null); setSelectedApplicableValue('One time'); setSelectedCreteriaValue('flatdiscount'); setSelectedRecurrenceValue('Once'); setIncrementValue(1);
                            setDisableEditForm(false);
                        }}>
                            Promo Code
                        </Button>
                    </Box>
                    <Scrollbars className='managerPromoCodeTableCard'
                        onScroll={() => {
                            if (Math.round(scrollBar?.current.getScrollTop() + scrollBar?.current.getClientHeight()) === scrollBar?.current.getScrollHeight()) {
                                if (pageLoad) {
                                    setsearchText({ ...searchText, page: searchText.page + 1 });
                                }
                            }
                        }}ref={scrollBar}
                        style={{ height: (dimensions.height - headerHeight - SectionHeight - SearchHeight - 60) }}>
                        <Table stickyHeader className='promoCodetable'>
                            <TableHead className='tableHead'>
                                <TableRow className='tableheadTr'>
                                    <TableCell>Promo Code ({promocodeCount})</TableCell>
                                    <TableCell className='productDetailsShowTitle'>Product Details</TableCell>
                                    <TableCell className='tableHideM hide1279'>Applicable For</TableCell>
                                    <TableCell className='tableHideM hide1279'>Discount Type</TableCell>
                                    <TableCell className='tableHideM'>Expiry Date</TableCell>
                                    <TableCell className='tableHideM'>Discount</TableCell>
                                    <TableCell className='tableHideM'>Min. Product Value</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='tableBody'>
                                {managerPromoCodeData.length > 0
                                    ? managerPromoCodeData.map((item, index) => {
                                        const data = moment(item?.sValiditydate).format('DD MMM YYYY');
                                        return (
                                            <TableRow className='tableBodyTr' key={index}>
                                                <TableCell className='firstCell'>
                                                    <Box>
                                                        <Typography variant="h5" className='interDentTitle'>{item.sPromocode}</Typography>
                                                        <Typography variant='body2' className='interSubtitle'>
                                                            {item.sPromoDes}
                                                        </Typography>
                                                        <Box className='productDetailsShowTitle show1279'>
                                                            <Box className='productDetailsShowRow'>
                                                                <Box className='productKeyTitle'>Applicable For - {item.sApplicabletype}</Box>
                                                            </Box>
                                                            <Box className='productDetailsShowRow'>
                                                                <Box className='productKeyTitle'>Discount Type - {item.sRecurrence === 'Repeats' ? item.sRepeats + ' Times' : item.sRecurrence}</Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className='productDetailsShowTitle'>
                                                    <Box className='productDetailsShowRow'>
                                                        <Box className='productKeyTitle'>Expiry Date</Box><Box className='productValueTitle'>{data}</Box>
                                                    </Box>
                                                    <Box className='productDetailsShowRow'>
                                                        <Box className='productKeyTitle'>Discount</Box> <Box className='productValueTitle'>{item.sDiscount}</Box>
                                                    </Box>
                                                    <Box className='productDetailsShowRow'>
                                                        <Box className='productKeyTitle'>Min. Product Value</Box> <Box className='productValueTitle'>£{item.sMinproductval}</Box>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className='tableHideM hide1279'>{item.sApplicabletype}</TableCell>
                                                <TableCell className='tableHideM hide1279'>{item.sRecurrence === 'Repeats' ? item.sRepeats + ' Times' : item.sRecurrence}</TableCell>
                                                <TableCell className='tableHideM'>{data}</TableCell>
                                                <TableCell className='tableHideM'>{item.sDiscount && item.sDiscount + '%'}{item.sAmount && `£${item.sAmount}`}</TableCell>
                                                <TableCell className='tableHideM'>£{item.sMinproductval}</TableCell>
                                                <TableCell>
                                                    <Chip label={item.sStatus} variant="outlined" className={item.sStatus === 'Active' ? 'activeStatus' : 'activeStatus expireStatus'} />
                                                </TableCell>
                                                <TableCell>
                                                    <Box className='groupBtn'>
                                                        <Button variant="text" className='editBtn' onClick={() => {
                                                            // eslint-disable-next-line max-len
                                                            // eslint-disable-next-line max-len
                                                            console.log('item--', item);
                                                            // addPromoCodeForm.setValue('validityDate', new Date(item?.sValiditydate).toString());
                                                            console.log(addPromoCodeForm);
                                                            setManagerId(item); editManagerPromoCodeHandle(item); setSelectedApplicableValue(item?.sApplicabletype); setStartDate(new Date(item?.sValiditydate)); setDicountvalue(item?.sDiscount);
                                                            setSelectedCreteriaValue(item?.sCriteria); setDissvalue(item?.sMinproductval); setSelectedRecurrenceValue(item?.sRecurrence); setIncrementValue(item.sRepeats > 1 ? item.sRepeats : 1); setDisableEditForm(true);
                                                        }}>Edit</Button>
                                                        {/* <Button variant="text" className='deleteBtn' onClick={() => { setDeletePromoCode(true); setManagerId(item); setStartDate(null); }}>Delete</Button> */}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                    : <TableRow><TableCell colSpan="9"><Box className='no-data'>{ searchText.search === '' ? 'No Promo Codes added' : 'No Such Promo Code Found' }</Box></TableCell></TableRow>
                                }
                            </TableBody>
                        </Table>
                    </Scrollbars>
                </Box>
            </Box>
            <ModalBox
                open={deletePromoCode}
                close={() => { setDeletePromoCode(false); setManagerId(null); setStartDate(null); }}
                title='Delete!'
                warningText={`${strings.ARE_YOU_SURE_DELETE_USER + ' "' + managerId?.sPromocode + '" ' + '?'}`}
                onSubmit={() => { setDeletePromoCode(false); deletePromoCodeApi(); setManagerId(null); setStartDate(null); }}
                button1='Yes, Delete'
            />
            <AddModal
                handleClose={() => {
                    setAddPromoCode(false);
                    setDescreptionCount('');
                }}
                aria-labelledby="customized-dialog-title"
                open={addPromoCode}
                className='addPromoCodeModel'
            >
                <Box className='addPromoCode'>
                    <HookForm
                        defaultForm={{}}
                        init={(form) => setAddPromoCodeForm(form)}
                        onSubmit={(e) => { onFormSubmit(e); console.log('submitFormData', e, managerId.sValiditydate); }}
                    >
                        {(formMethod) => {
                            return (
                                <>
                                    <Box className='addPromoCodeHeader'>
                                        <Typography variant='h5' className='title'>{ addPromoCodeType !== null ? strings.EDIT_PROMO_CODE : strings.ADD_PROMO_CODE}
                                            <Typography variant='span' className='icon-close clearIcon' onClick={() => {
                                                setManagerId(null); setAddPromoCode(false); setDescreptionCount(''); setStartDate(null); setSelectedApplicableValue('One time'); setSelectedCreteriaValue('flatdiscount'); setSelectedRecurrenceValue('Once'); setIncrementValue(1);
                                                setFlatvalue('');
                                                setDissvalue('');
                                                setAmountvalue('');
                                                setDicountvalue('');
                                            }}></Typography>
                                        </Typography>
                                    </Box>
                                    <Box className='addPromoCodeFormCard'>
                                        <Box className='addPrmoCodeCardLeft'>
                                            <Box className='radioButtonGroup'>
                                                <Typography variant='h6' className='title'>Applicable For</Typography>
                                                <Box className={disableEditForm ? 'radioBtnSingle disabled' : 'radioBtnSingle'}>
                                                    <CustomRadioCard
                                                        disableEditForm
                                                        icon='icon-clock'
                                                        label='One time'
                                                        value='One time'
                                                        onChange={(e) => {
                                                            handleSelectedAppicable(e);
                                                            setFlatvalue('');
                                                            setDissvalue('');
                                                            setAmountvalue('');
                                                            setDicountvalue('');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                        }}
                                                        checked={selectedApplicableValue === 'One time'}
                                                        disabled= {disableEditForm}
                                                    />
                                                    <CustomRadioCard
                                                        icon='icon-subscribe'
                                                        label='Subscription'
                                                        value='Subscription'
                                                        onChange={(e) => {
                                                            handleSelectedAppicable(e);
                                                            setFlatvalue('');
                                                            setDissvalue('');
                                                            setAmountvalue('');
                                                            setDicountvalue('');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                            formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                        }}
                                                        checked={selectedApplicableValue === 'Subscription'}
                                                        disabled= {disableEditForm}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className={disableEditForm ? 'formGroupField formGroupFieldFirst disabled' : 'formGroupField formGroupFieldFirst'}>
                                                <Typography variant='h6' className='label'>Promo Code Details</Typography>
                                                <CustomTextField
                                                    disabled= {disableEditForm}
                                                    requirePlaceHolder='Promo code'
                                                    variant='outlined'
                                                    autoFocus={false}
                                                    classText={managerId?.sPromocode ? '' : 'ActiveClass'}
                                                    inputProps={{ maxLength: 20 }}
                                                    onKeyPress = {(e) => e.key === ' ' && e.preventDefault()}
                                                    type='text'
                                                    formMethod={formMethod}
                                                    rules={addPromoCodeFormValidationForm.promoCode.validate}
                                                    name={addPromoCodeFormValidationForm.promoCode.name}
                                                    errors={formMethod?.formState.errors}
                                                    defaultValue = {managerId && managerId?.sPromocode ? managerId?.sPromocode : '' }
                                                />
                                            </Box>
                                            <Box className='formGroupField customDatePicker'>
                                                <Box className={disableEditForm ? 'customTextField custom-arrows disabled' : 'customTextField custom-arrows'}>
                                                    <Controller
                                                        render={({
                                                            field: { onChange, SelectedValue }
                                                        }) => (
                                                            <DatePicker
                                                                disabled= {disableEditForm}
                                                                defaultValue = {managerId && managerId?.sValiditydate ? managerId?.sValiditydate : '' }
                                                                selected={startDate}
                                                                minDate={new Date()}
                                                                dateFormatCalendar="MMM yyyy"
                                                                dateFormat="dd-MM-yyyy"
                                                                onChange={(date) => {
                                                                    setSValidityDate(date);
                                                                    // setManagerId({ ...managerId, sValiditydate: date });
                                                                    // formMethod.setValue('validityDate', date.toString());
                                                                    setStartDate(date);
                                                                    setTimeout(() => {
                                                                        console.log('formstate', formMethod, managerId, sValidityDate, date);
                                                                    }, 2000);
                                                                }}
                                                                onChangeRaw={handleDateChangeRaw}
                                                                name={addPromoCodeFormValidationForm.validityDate.name}
                                                            />
                                                        )}
                                                        control={formMethod.control}
                                                        errors={formMethod?.formState.errors}
                                                        formMethod={formMethod}
                                                        name={addPromoCodeFormValidationForm.validityDate.name}
                                                        rules={addPromoCodeFormValidationForm.validityDate.validate}
                                                    />
                                                    {!startDate && <label className='requiredField'>Validity Date<Typography component='span'>*</Typography></label>}
                                                    <Typography component='span' className='icon icon-calender'></Typography>
                                                </Box>
                                            </Box>
                                            <Box className={descreptionCount.length > 160 ? 'formGroupFieldTextArea errorBorder' : 'formGroupFieldTextArea'}>
                                                <CustomTextField
                                                    requirePlaceHolder='Description'
                                                    variant="standard"
                                                    value={descreptionCount}
                                                    autoFocus={false}
                                                    inputProps={{
                                                        disableUnderline: true,
                                                        maxLength: 160
                                                    }}
                                                    type='text'
                                                    // onKeyPress = {(e) => e.key === 'Enter' && e.preventDefault();}
                                                    onChange={(e) => {
                                                        console.log('formstatedesc', managerId);
                                                        setDescreptionCount(e.target.value.trimLeft());
                                                    }}
                                                    multiline
                                                    formMethod={formMethod}
                                                    rules={addPromoCodeFormValidationForm.promoCodeDescreption.validate}
                                                    name={addPromoCodeFormValidationForm.promoCodeDescreption.name}
                                                    errors={formMethod?.formState.errors}
                                                    defaultValue = {managerId && managerId?.sPromoDes ? managerId?.sPromoDes : '' }
                                                />
                                                <Typography className={descreptionCount.length > 159 ? 'promoCodeDescreptionCount red' : 'promoCodeDescreptionCount'}>{addPromoCodeType !== null ? managerId?.sPromoDes.length : descreptionCount.length }/160</Typography>
                                            </Box>
                                            <Box className='formGroupFieldTextAreaError'>
                                                {descreptionCount.length > 160 ? <Typography className='charLimit'>Character Limit Exceeded</Typography> : null}
                                            </Box>
                                        </Box>
                                        <Box className='addPrmoCodeCardRight'>
                                            {selectedApplicableValue !== 'One time'
                                                ? <>
                                                    <Box className='recurrenceSection'>
                                                        <Typography variant='h6' className='title'>Recurrence</Typography>
                                                        <Box className={disableEditForm ? 'radioBtn disabled' : 'radioBtn'}>
                                                            <CustomRadioButton
                                                                disabled={disableEditForm}
                                                                value='Once'
                                                                label='Once'
                                                                checked={selectedRecurrenceValue === 'Once'}
                                                                onChange={handleSelectedRecurrence}
                                                            />
                                                            <CustomRadioButton
                                                                disabled={disableEditForm}
                                                                value='Lifetime'
                                                                label='Lifetime'
                                                                checked={selectedRecurrenceValue === 'Lifetime'}
                                                                onChange={handleSelectedRecurrence}
                                                            />
                                                            <CustomRadioButton
                                                                disabled={disableEditForm}
                                                                value='Repeats'
                                                                label='Repeats'
                                                                checked={selectedRecurrenceValue === 'Repeats'}
                                                                onChange={handleSelectedRecurrence}
                                                            />
                                                        </Box>
                                                        {selectedRecurrenceValue === 'Repeats'
                                                            ? <Box className='howManyRepeat'>
                                                                <Typography variant='h6' className='promoCodeRepeatsTitle'>How many repeats?</Typography>
                                                                <ButtonGroup size="small" aria-label="small outlined button group" className='howManyIncrementDecrement'>
                                                                    <Button className='decrementBtn' onClick={decrementCounter}><span className="icon-minus-1"></span></Button>
                                                                    <Typography className='countText'>{incrementValue}</Typography>
                                                                    <Button className='increment' onClick={incrementCounter}><span className="icon-plus-1"></span></Button>
                                                                </ButtonGroup>
                                                            </Box>
                                                            : null}
                                                    </Box>
                                                    <Box className='setCreteriaSection'>
                                                        <Typography variant='h6' className='title'>Set Criteria</Typography>
                                                        <Box className={disableEditForm ? 'radioBtnGroup disabled' : 'radioBtnGroup'}>
                                                            <CustomRadioButton
                                                                value='flatdiscount'
                                                                label='Flat Discount'
                                                                onChange={(e) => {
                                                                    handleSelectedCreteria(e);
                                                                    setFlatvalue('');
                                                                    setDissvalue('');
                                                                    setAmountvalue('');
                                                                    setDicountvalue('');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                                }}
                                                                checked={selectedCreteriaValue === 'flatdiscount'}
                                                                disabled={disableEditForm}
                                                            />
                                                            <CustomRadioButton
                                                                disabled= {disableEditForm}
                                                                value='discount'
                                                                label='% Discount'
                                                                checked={selectedCreteriaValue === 'discount'}
                                                                onChange={(e) => {
                                                                    handleSelectedCreteria(e);
                                                                    setFlatvalue('');
                                                                    setDissvalue('');
                                                                    setAmountvalue('');
                                                                    setDicountvalue('');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                                    formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </>
                                                : <Box className='setCreteriaSection'>
                                                    <Typography variant='h6' className='title'>Set Criteria</Typography>
                                                    <Box className='radioBtnGroup'>
                                                        <CustomRadioButton
                                                            disabled= {disableEditForm}
                                                            value='flatdiscount'
                                                            label='Flat Discount'
                                                            onChange={(e) => {
                                                                console.log('selectedCreteriaValue', selectedCreteriaValue);
                                                                handleSelectedCreteria(e);
                                                                console.log('value before clearing.. Flat Discount.', formMethod.watch(addPromoCodeFormValidationForm.minimumProductValue.name));
                                                                formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                                setFlatvalue('');
                                                                setDissvalue('');
                                                                setAmountvalue('');
                                                                setDicountvalue('');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                            }}
                                                            checked={selectedCreteriaValue === 'flatdiscount'}
                                                        />
                                                        <CustomRadioButton
                                                            disabled= {disableEditForm}
                                                            value='discount'
                                                            label='% Discount'
                                                            onChange={(e) => {
                                                                handleSelectedCreteria(e);
                                                                console.log('value before clearing... Discount', formMethod.watch(addPromoCodeFormValidationForm.minimumProductValue.name));
                                                                formMethod.setValue(addPromoCodeFormValidationForm.amount.name, '');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.discount.name, '');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValue.name, '');
                                                                setDissvalue('');
                                                                setFlatvalue('');
                                                                setAmountvalue('');
                                                                setDicountvalue('');
                                                                formMethod.setValue(addPromoCodeFormValidationForm.minimumProductValueDiscount.name, '');
                                                                console.log('value after clearing and radio change...', formMethod.watch(addPromoCodeFormValidationForm.minimumProductValue.name), selectedCreteriaValue);
                                                            }}
                                                            checked={selectedCreteriaValue === 'discount'}
                                                        />
                                                    </Box>
                                                </Box>}
                                            <Box className={disableEditForm ? 'formGroupField formGroupThree disabled' : 'formGroupField formGroupThree'}>
                                                {selectedCreteriaValue !== 'discount'
                                                    ? <Box className='requiredstar'>
                                                        {!dissvalue &&
                                                             <label className='requiredField'>Minimum Product value <sup>*</sup> </label>
                                                        }
                                                        <CustomTextField
                                                            disabled= {disableEditForm}
                                                            placeholder= ""
                                                            variant='outlined'
                                                            autoFocus={false}
                                                            type='number'
                                                            numberMask={true}
                                                            classText='masking withSign'
                                                            onKeyPress = {(e) => e.key === 'e' && e.preventDefault()}
                                                            formMethod={formMethod}
                                                            rules={addPromoCodeFormValidationForm.minimumProductValue.validate}
                                                            name={addPromoCodeFormValidationForm.minimumProductValue.name}
                                                            errors={formMethod?.formState.errors}
                                                            onChange = {(e) => setDissvalue(e.target.value)}
                                                            // onChange = {(e) => setFlatvalue(e.target.value)}
                                                            defaultValue = {managerId && managerId?.sMinproductval ? managerId?.sMinproductval : ''}
                                                        />
                                                    </Box>
                                                    : <Box className='requiredstar'>
                                                        {/* {!managerId?.sMinproductval && */}
                                                        {!dissvalue &&
                                                        <label className='requiredField'>Minimum Product value <sup>*</sup> </label>
                                                        }
                                                        <CustomTextField
                                                            disabled= {disableEditForm}
                                                            placeholder= ""
                                                            variant='outlined'
                                                            autoFocus={false}
                                                            onKeyPress = {(e) => e.key === 'e' && e.preventDefault()}
                                                            numberMask={true}
                                                            classText='masking withSign'
                                                            type='number'
                                                            formMethod={formMethod}
                                                            rules={addPromoCodeFormValidationForm.minimumProductValueDiscount.validate}
                                                            name={addPromoCodeFormValidationForm.minimumProductValueDiscount.name}
                                                            errors={formMethod?.formState.errors}
                                                            defaultValue = {managerId && managerId?.sMinproductval ? managerId?.sMinproductval : ''}
                                                            onChange = {(e) => setDissvalue(e.target.value)}
                                                        />
                                                    </Box>
                                                }
                                            </Box>
                                            {selectedCreteriaValue !== 'discount'
                                                ? <Box className={formMethod.watch(addPromoCodeFormValidationForm.amount.name) === ''
                                                    ? 'formGroupField formGroupone empty discountField'
                                                    : 'formGroupField formGroupone notEmpty discountField'}>
                                                    <Box className={disableEditForm ? 'requiredstar disabled' : 'requiredstar'}>
                                                        {!amountvalue &&
                                                             <label className='requiredField'>Amount <sup>*</sup> </label>
                                                        }

                                                        <CustomTextField
                                                            disabled= {disableEditForm}
                                                            placeholder= ""
                                                            variant='outlined'
                                                            autoFocus={false}
                                                            discount={true}
                                                            type='number'
                                                            onKeyPress = {(e) => e.key === 'e' && e.preventDefault()}
                                                            formMethod={formMethod}
                                                            numberMask={true}
                                                            classText='masking withSign'
                                                            rules={{
                                                                required: {
                                                                    value: true,
                                                                    message: 'Amount can not be empty'
                                                                },
                                                                pattern: {
                                                                    value: Constant.REGEX.NUMBER,
                                                                    message: 'Flat discount cannot be given more than product value criteria.'
                                                                },
                                                                validate: {
                                                                    matchPassword: (value) => {
                                                                        console.log('value==>', value);
                                                                        return (value && parseInt(value) <= parseInt((formMethod.watch(addPromoCodeFormValidationForm.minimumProductValue.name)))) || 'Flat discount cannot be given more than product value criteria.';
                                                                    }
                                                                }
                                                            }}
                                                            name={addPromoCodeFormValidationForm.amount.name}
                                                            errors={formMethod?.formState.errors}
                                                            onChange={(e) => {
                                                                handleChange();
                                                                console.log('Inside onchange==>', e.target.value);
                                                                setAmountvalue(e.target.value);
                                                            }}
                                                            defaultValue = {managerId && managerId?.sAmount ? managerId?.sAmount : ''}
                                                        />
                                                    </Box>
                                                </Box>
                                                : <Box className={formMethod.watch(addPromoCodeFormValidationForm.discount.name) === ''
                                                    ? 'formGroupField formGroupTwo discount'
                                                    : 'formGroupField formGroupTwo discount'}>
                                                    <Box className={disableEditForm ? 'requiredstar disabled' : 'requiredstar'}>
                                                        {!dicountvalue &&
                                                             <label className='requiredField'>Discount <sup>*</sup> </label>
                                                        }

                                                        <CustomTextField
                                                            disabled= {disableEditForm}
                                                            variant='outlined'
                                                            autoFocus={false}
                                                            onKeyPress = {(e) => e.key === 'e' && e.preventDefault()}
                                                            type='number'
                                                            numberMask={true}
                                                            classText='masking'
                                                            value={value}
                                                            formMethod={formMethod}
                                                            rules={{
                                                                required: {
                                                                    value: true,
                                                                    message: 'Discount can not be empty'
                                                                },
                                                                pattern: {
                                                                    value: Constant.REGEX.NUMBER,
                                                                    message: 'Flat discount cannot be given more than product value criteria.'
                                                                },
                                                                validate: {
                                                                    matchPassword: (value) => {
                                                                        const disc = 100;
                                                                        console.log('valuediscount==>', value);
                                                                        return (value && parseInt(value) <= disc) || 'Discount cannot be given more than 100%.';
                                                                    }
                                                                }
                                                            }}
                                                            onChange={(e) => {
                                                                handleChange();
                                                                const discount = (parseInt(formMethod.watch(addPromoCodeFormValidationForm.discount.name)) * parseInt((formMethod.watch(addPromoCodeFormValidationForm.minimumProductValueDiscount.name))) / 100);
                                                                console.log(discount, '789451230123654789');
                                                                setDicountvalue(e.target.value);
                                                            }}
                                                            name={addPromoCodeFormValidationForm.discount.name}
                                                            errors={formMethod?.formState.errors}
                                                            defaultValue = {managerId && managerId?.sDiscount ? managerId?.sDiscount : ''}
                                                        />
                                                    </Box>
                                                </Box>}
                                        </Box>
                                    </Box>
                                    <Box className='addPromoCodeGroupButton'>
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            // disabled={!formMethod?.formState.isValid || !startDate || !formMethod?.formState.isDirty}
                                            btnTitle={addPromoCodeType !== null ? 'Update' : 'Add'}
                                            disabled={addPromoCodeType !== null ? !formMethod?.formState.isDirty : !formMethod?.formState.isValid || !startDate}
                                            loading={busy}
                                            className="submitButton"
                                        />
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='button'
                                            variant='outlined'
                                            color='primary'
                                            btnTitle='Cancel'
                                            className="outLineBtn"
                                            onClick={() => {
                                                setManagerId(null); setAddPromoCode(false); setDescreptionCount(''); setStartDate(null);
                                                setFlatvalue('');
                                                setDissvalue('');
                                                setAmountvalue('');
                                                setDicountvalue('');
                                            }}
                                        />
                                    </Box>
                                </>
                            );
                        }}
                    </HookForm>
                </Box>
            </AddModal>
        </>
    );
};
const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {
    showToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ManagerPromoCode));
