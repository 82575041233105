import React from 'react';
import { Box, Radio, Typography } from '@mui/material';

import './CustomRadioCard.scss';

const CustomRadioCard = (props) => {
    return (
        <Box className="RadioButton">
            <i className={props.icon} alt='iconCheck'></i>
            <Typography>{props.label}</Typography>
            <Radio
                checked={props.checked}
                onChange={props.onChange}
                value={props.value}
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
                disabled={props.disabled}
            />
            {props.children}
        </Box>

    );
};

export default CustomRadioCard;
