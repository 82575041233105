/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable n/handle-callback-err */
/* eslint-disable  */
import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Container, Fade, Menu, MenuItem, Select, Typography } from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { multilanguage } from "redux-multilanguage";
import { useSelector, connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars-2";
import { Controller } from "react-hook-form";
import Tab from "@mui/material/Tab";
import AWS from "aws-sdk";

import Loader from "../../components/Loader/Loader";
import AddModal from "../../components/AddModal/AddModal";
import HookForm from "../../components/HookForm/HookForm";
import ModalBox from "../../components/ModalBox/ModalBox";
import SearchBox from "../../components/SearchBox/SearchBox";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomCheckBox from "../../components/CustomCheckBox/CustomCheckBox";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomTextField from "../../components/CustomTextField/CustomTextFeild";
import CustomFileUpload from "../../components/CustomFileUpload/CustomFileUpload";
import CustomLoadingButton from "../../components/CustomLoadingButton/CustomLoadingButton";

import instance from "../../api/axios";
import Constant from "../../utils/constant";
import { setUser } from "../../redux/actions/authAction";
import { showToast } from "../../redux/actions/toastAction";
import useWindowDimension from "../../hooks/useWindowDimension";

import "./ManageProducts.scss";
import { MetaTags } from "react-meta-tags";
import CustomLabel from "../../components/CustomLabel/CustomLabel";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const ManageProducts = (props) => {
  const { user } = props;
  const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
  const dimensions = useWindowDimension();
  const [value, setValue] = useState(
    localStorage.getItem("product-brand") || "1"
  );
  const [deleteProductPopup, setDeleteProductPopup] = useState(false);
  const [disableProductPopup, setDisableProductPopup] = useState(false);
  const [addProductPopup, setAddProductPopup] = useState({
    type: false,
    value: "",
  });
  const [deleteBrandPopup, setdeleteBrandPopup] = useState(false);
  const [disableBrandPopup, setDisableBrandPopup] = useState(false);
  const [addBrandPopup, setAddBrandPopup] = useState({
    type: false,
    value: "",
  });
  const [pageLoad, setPageLoad] = useState(false);
  const [loaderone, setLoaderone] = useState(false);
  const [loadertwo, setLoadertwo] = useState(false);
  const [loaderthree, setLoaderthree] = useState(false);
  const [loaderfour, setLoaderfour] = useState(false);
  const [checkMonthlyTick, setCheckMonthlyTick] = useState(false);
  const [checkProductSubs, setCheckProductSubs] = useState(true);
  const [showImage, setShowImage] = useState("");
  const [showImage2, setShowImage2] = useState("");
  const [showImage3, setShowImage3] = useState("");
  const [showImage4, setShowImage4] = useState("");

  const handleMainTabs = (event, newValue) => {
    setValue(newValue);
  };

  const [productData, setProductData] = useState([]);
  const [productSearch, setProductSearch] = useState();
  const [brandData, setBrandData] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [branddropdownData, setBranddropdownData] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  console.log('branddropdownData--->', branddropdownData);
  const [productId, setProductId] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const { strings } = props;
  const [productImg1, setProductImg1] = useState("");
  const [productImg2, setProductImg2] = useState("");
  const [productImg3, setProductImg3] = useState("");
  const [productImg4, setProductImg4] = useState("");
  const [checkProductImg, setCheckProductImg] = useState([]);
  const [checkProductImg1, setCheckProductImg1] = useState("");
  const [checkProductImg2, setCheckProductImg2] = useState("");
  const [checkProductImg3, setCheckProductImg3] = useState("");
  const [checkProductImg4, setCheckProductImg4] = useState("");
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedColorForProduct, setSelectedColorForProduct] = useState("");
  const [selectedBrandColor, setSelectedBrandColor] = useState([]);
  const [availableBrushSizes, setAvailableBrushSizes] = useState([]);
  const [editBrandColor, setEditBrandColor] = useState([]);
  const [emptyProductColor, setEmptyProductColor] = useState(true);
  const [colorforEdit, setColorforEdit] = useState("");
  const selectedColorsForCard = ["#3FC9D5", "#505AE9", "#5299E3", '#5299E3', '#9B6181', '#36D325', '#5E8987', '#3B38D7', '#E5AF2F', '#F660D0',/* ... add 7 more colors */];
  const allColors = [
    '#3FC9D5', '#505AE9', '#5299E3', '#9B6181', '#36D325', '#5E8987', '#87625B', '#89DF73', '#A5EEEE', '#CEF10D',
    '#65DDF1', '#F757F9', '#B2CF51', '#E5430B', '#6D5C20', '#B0DB83', '#A13BB0', '#BE3FDB', '#EE7E49', '#F758D2',
    '#FB53F3', '#AAC60E', '#ACB8E8', '#F1D8DA', '#3CF7F0', '#D6E9AC', '#B6FB71', '#8762CE', '#E5537E', '#B8DDD6',
    '#6B9FC1', '#F7EE1A', '#CC6709', '#3B38D7', '#E5AF2F', '#F660D0', '#987066', '#34A620', '#4BDB33', '#749DA6',
    '#7D5CD4', '#90D054', '#3AA855', '#D06509', '#E57AA1', '#B48D13', '#C045AC', '#51F6F0', '#E9829D', '#32877F',
  ];
  const allBrushSizes = [
    {_id: "1", title: 'Size-1', value: 'Size-1', size: 'Size-1', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869194/MouthMap/Mobile/brush_Size-1.svg"},
    {_id: "2", title: 'Size-2', value: 'Size-2', size: 'Size-2', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869195/MouthMap/Mobile/brush_Size-2.svg"},
    {_id: "3", title: 'Size-3', value: 'Size-3', size: 'Size-3', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869195/MouthMap/Mobile/brush_Size-3.svg"},
    {_id: "4", title: 'Size-4', value: 'Size-4', size: 'Size-4', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869196/MouthMap/Mobile/brush_Size-4.svg"},
    {_id: "5", title: 'Size-5', value: 'Size-5', size: 'Size-5', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869197/MouthMap/Mobile/brush_Size-5.svg"},
    {_id: "6", title: 'Size-6', value: 'Size-6', size: 'Size-6', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869198/MouthMap/Mobile/brush_Size-6.svg"},
    {_id: "7", title: 'Size-7', value: 'Size-7', size: 'Size-7', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869201/MouthMap/Mobile/brush_Size-7.svg"},
    {_id: "8", title: 'Size-8', value: 'Size-8', size: 'Size-8', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869199/MouthMap/Mobile/brush_Size-8.svg"},
    {_id: "9", title: 'Size-9', value: 'Size-9', size: 'Size-9', urlImage: "https://res.cloudinary.com/zudu/image/upload/v1704869200/MouthMap/Mobile/brush_Size-9.svg"},
    {_id: "10", title: 'Size-10', value: 'Size-10', size: 'Size-10', urlImage: "https://res.cloudinary.com/dygllydcg/image/upload/v1698663488/MouthMap/Mobile/brushSize_5.svg"},
  ]

  const colorOptions = ["#3FC9D5", "#505AE9", "#5299E3", '#5299E3', '#9B6181', '#36D325', '#5E8987', '#3B38D7', '#E5AF2F', '#F660D0',]

  const handleColorSelect = (color) => {
    if (selectedColors.includes(color)) {
      // If color is already selected, remove it
      handleColorRemove(color);
    } else {
      // If color is not selected, add it
      if (selectedColors.length < 10) {
        setSelectedColors([...selectedColors, color]);
      }
    }
  };

  const handleColorRemove = (color) => {
    const updatedColors = selectedColors.filter((selectedColor) => selectedColor !== color);
    setSelectedColors(updatedColors);
  };


  const handleColorSelectTwo = (color) => {
    setAnchorEl(null)
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const addproduct = {
    productTitle: {
      value: "producttitle",
      validate: {
        required: {
          value: true,
          message: strings.PRODUCT_TITLE_REQUIRED,
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: strings.THIS_FIELD_CONTAIN_ALPHANUM,
        },
      },
    },
    price: {
      value: "price",
      validate: {
        required: {
          value: true,
          message: "Price is required",
        },
        pattern: {
          value: Constant.REGEX.NUMBER,
        },
      },
    },
    productbrand: {
      value: "productbrand",
      validate: {
        required: {
          value: true,
          message: "Description is required",
        },
      },
    },
    productdropdown: {
      value: "productdropdown",
      validate: {
        required: {
          value: true,
        },
      },
    },
    productdropdownSize: {
      value: "productdropdownSize",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };
  const addbrand = {
    name: {
      value: "productbrand",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.BRANDNAME,
          message: strings.SPECIAL_CHAR,
        },
      },
    },
  };
  const [busy, setBusy] = useState(false);
  const userData = useSelector((state) => state.auth);
  const { isCheckedBox } = userData;
  const [checkedBox, setCheckedBox] = useState(isCheckedBox);
  const [addProduct, setAddProduct] = useState();
  const [addBrand, setAddBrand] = useState();
  const [productText, setProductText] = useState({
    search: "",
    page: 1,
    limit: 20,
  });
  const scrollBar = useRef();
  const [brandText, setBrandText] = useState({
    search: "",
    page: 1,
    limit: 30,
  });
  const [activeTab, setActiveTab] = useState("");

  const onUpload1 = (acceptImage1) => {
    if (acceptImage1.length > 0) {
      setShowImage("");
      const file = acceptImage1[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");
      console.log("mimetype", mimetype);

      setLoaderone(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key:
            user?.id + "/" + Date.now().toString() + "." + arr[arr.length - 1],
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log('progressPercentage---', progressPercentage);
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          console.log(`upload done ----->>>>`, image.key, image);
          setLoaderone(false);
          setShowImage(image.key);
        },
        function (err) { }
      );
    } else {
      setLoaderone(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setProductImg1(event.target.result);
    };
    reader.readAsDataURL(acceptImage1[0]);
  };

  const onUpload2 = (acceptImage2) => {
    if (acceptImage2.length > 0) {
      setShowImage2("");
      const file = acceptImage2[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");
      console.log(mimetype);

      setLoadertwo(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key:
            user?.id + "/" + Date.now().toString() + "." + arr[arr.length - 1],
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          setLoadertwo(false);
          setShowImage2(image.key);
        },
        function (err) { }
      );
    } else {
      setLoadertwo(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setProductImg2(event.target.result);
    };
    reader.readAsDataURL(acceptImage2[0]);
  };

  const onUpload3 = (acceptImage3) => {
    if (acceptImage3.length > 0) {
      setShowImage3("");
      const file = acceptImage3[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");
      console.log(mimetype);

      setLoaderthree(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key:
            user?.id + "/" + Date.now().toString() + "." + arr[arr.length - 1],
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          setLoaderthree(false);
          setShowImage3(image.key);
        },
        function (err) { }
      );
    } else {
      setLoaderthree(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setProductImg3(event.target.result);
    };
    reader.readAsDataURL(acceptImage3[0]);
  };

  const onUpload4 = (acceptImage4) => {
    if (acceptImage4.length > 0) {
      setShowImage4("");
      const file = acceptImage4[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");
      console.log(mimetype);

      setLoaderfour(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key:
            user?.id + "/" + Date.now().toString() + "." + arr[arr.length - 1],
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          setLoaderfour(false);
          setShowImage4(image.key);
        },
        function (err) { }
      );
    } else {
      setLoaderfour(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setProductImg4(event.target.result);
    };
    reader.readAsDataURL(acceptImage4[0]);
  };

  const onFormSubmit = async (value) => {
    let brushData = allBrushSizes.filter((brush) => brush.size === value?.productdropdownSize);
    console.log(`onFormSubmitvalue ----->>>>`, value, selectedColorForProduct, brushData[0]?.urlImage);
    if (productId === undefined || productId === null) {
      setBusy(true);
      console.log(`brandData ----->>>>`, brandData);
      const brandIdd = brandData.find(
        (item) => item.sBrandName === value.productdropdown
        )?._id;
        console.log(`brandIdd ----->>>>`, brandIdd);
        const params = {
        oBrandId: brandIdd,
        sName: value.producttitle,
        sProductImage: [showImage, showImage2, showImage3, showImage4],
        nPrice: value.price,
        sDescription: value.productbrand,
        sMonthly_subScription: checkedBox,
        size: value?.productdropdownSize,
        color: selectedColorForProduct,
        brushUrlImage: brushData.length > 0 && brushData[0]?.urlImage
      };
      await instance
        .post("/products/product/", params)
        .then(async (res) => {
          setProductId(null);
          await branddropdown();
          setTimeout(() => {
            props.showToast({
              message: strings.PRODUCT_ADDED_SUCCESS,
              type: "success",
            });
            productList();
          }, 1000);
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    } else {
      const brandIdd = brandData.find(
        (item) => item.sBrandName === value.productdropdown
      )?._id;
      const params = {
        oBrandId: brandIdd,
        sName: value.producttitle,
        sProductImage: [showImage, showImage2, showImage3, showImage4],
        nPrice: value.price,
        sDescription: value.productbrand,
        sMonthly_subScription: checkedBox,
      };
      await instance
        .put(`/products/product/updateProduct?id=${productId._id}`, params)
        .then((res) => {
          setProductId(null);
          setShowImage("");
          setProductImg1(null);
          setShowImage2("");
          setProductImg2(null);
          setShowImage3("");
          setProductImg3(null);
          setShowImage4("");
          setProductImg4(null);
          setTimeout(() => {
            props.showToast({
              message: strings.PRODUCT_EDIT_SUCCESS,
              type: "success",
            });
          }, 100);
          productList();
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    }
  };
  const onFormSubmit2 = async (value) => {
    if (brandId === null || brandId === undefined) {
      setBusy(true);
      const params = {
        sBrandName: value.productbrand,
        brandColor: selectedColors
      };
      await instance
        .post("/brands/brand/", params)
        .then((res) => {
          setSelectedColors([]);
          setTimeout(() => {
            props.showToast({
              message: strings.BRAND_ADDED_SUCCESS,
              type: "success",
            });
            setBusy(false);
          }, 1000);
          brandList();
        })
        .catch((e) => {
          console.log(e);
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    } else {
      const params = {
        sBrandName: value.productbrand,
      };
      await instance
        .put(`/brands/brand/updateBrand?id=${brandId?._id}`, params)
        .then((res) => {
          setBrandId(null);
          brandList();
          setTimeout(() => {
            props.showToast({
              message: strings.BRAND_EDIT_SUCCESS,
              type: "success",
            });
          }, 1000);
        })
        .catch((e) => { });
    }
  };
  const addBrandApi = async (addData) => {
    const params = {
      sBrandName: addData,
    };
    await instance
      .post("/brands/brand/", params)
      .then((res) => {
        setTimeout(() => {
          props.showToast({
            message: strings.BRAND_ADDED_SUCCESS,
            type: "success",
          });
        }, 1000);
        brandList();
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  useEffect(() => {
    setActiveTab(localStorage.getItem("product-brand") || "1");
  }, []);

  useEffect(() => {
    if (activeTab === "1") {
      productList();
    } else {
      brandList();
    }
  }, [activeTab]);

  useEffect(() => { }, [busy, checkedBox]);
  useEffect(() => {
    productList();
  }, [productText, productSearch]);

  useEffect(() => {
    brandList();
  }, [brandText]);

  const productList = async () => {
    let params = "";
    if (productText.page) {
      params = `?page=${productText.page}`;
    }
    if (productText.search) {
      params += `&search=${productText.search}`;
    }
    if (productText.limit) {
      params += `&limit=${productText.limit}`;
    }
    await instance
      .get(`/products/allProducts${params}`)
      .then((res) => {
        // if(subscription === )
        setProductSearch(res.payload.productCount);
        if (res.payload.product.length === 0) {
          // setProductData([]);
          setPageLoad(false);
        }

        if (productText.page === 1) {
          setPageLoad(true);
          setProductData(res.payload.product);
        } else {
          // setProductData(res.payload.product);
          setProductData(productData?.concat(res.payload.product));
        }
      })
      .catch((e) => { });
  };
  const deleteProduct = async () => {
    await instance
      .delete(`/products/product/${productId._id}`)
      .then((res) => {
        setTimeout(() => {
          setBusy(false);
          props.showToast({
            message: res.message,
            type: "success",
          });
          productList();
        }, 100);
        // setProductData(productData.filter(item => item._id !== productId._id));
      })
      .catch((e) => { });
  };
  const statusProductApi = async (value) => {
    const params = {
      id: productId._id,
      sStatus: value,
    };
    await instance
      .put("/products/product/updateStatus", params)
      .then((res) => {
        setTimeout(() => {
          setBusy(false);
          props.showToast({
            message: res.message,
            type: "success",
          });
          productList();
        }, 100);
      })
      .catch((e) => {
        setTimeout(() => {
          setBusy(false);
          props.showToast({
            message: e.message,
            type: "error",
          });
        }, 100);
      });
  };

  const brandList = async () => {
    let params = "";
    if (brandText.page) {
      params = `?page=${brandText.page}`;
    }
    if (brandText.search) {
      params += `&search=${brandText.search}`;
    }
    if (brandText.limit) {
      params += `&limit=${brandText.limit}`;
    }
    await instance
      .get(`/brands/allBrands${params}`)
      .then((res) => {
        if (res.payload.brand.length === 0) {
          // setBrandData([]);
          setPageLoad(false);
        }

        if (brandText.page === 1) {
          setPageLoad(true);
          setBrandData(res.payload.brand);
        } else {
          setBrandData(brandData?.concat(res.payload.brand));
        }
        // setBrandData(res.payload.brand);
      })
      .catch((e) => { });
  };

  useEffect(() => {
    branddropdown();
  }, [brandData]);

  const branddropdown = async () => {
    await instance
      .get("/brands/allBrandList")
      .then((res) => {
        const brand = res.payload.brand.map((item) => {
          item.id = item._id;
          item.title = item.sBrandName;
          item.value = item.sBrandName;
          item.color = item.brandColor
          return item;
        });
        setAllBrands(res.payload.brand);
        setBranddropdownData(brand);
      })
      .catch((e) => { });
  };

  console.log(`allBrands ----->>>>`, allBrands);

  const deleteBrandApi = async () => {
    await instance
      .delete(`brands/brand/${brandId._id}`)
      .then((res) => {
        setBrandData(brandData.filter((item) => item._id !== brandId._id));
      })
      .catch((e) => { });
  };
  const statusBrandApi = async (value) => {
    const params = {
      id: brandId._id,
      sStatus: value,
    };
    await instance
      .put("/brands/brand/updateStatus/", params)
      .then((res) => {
        brandList();
      })
      .catch((e) => { });
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mainTitleHeight, setMainTitleHeight] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);
  const [searchBarRowHeight, setSearchBarRowHeight] = useState(0);
  useEffect(() => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setTabHeight(document.getElementsByClassName("tabWrap")[0]?.offsetHeight);
    setSearchBarRowHeight(
      document.getElementsByClassName("topbarWrap")[0]?.offsetHeight
    );
  }, []);
  const handleResize = () => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setTabHeight(document.getElementsByClassName("tabWrap")[0]?.offsetHeight);
    setSearchBarRowHeight(
      document.getElementsByClassName("topbarWrap")[0]?.offsetHeight
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  async function rgbToHex(rgb) {
    // Split the RGB values
    const rgbValues = rgb.match(/\d+/g);

    // Convert each value to hexadecimal and uppercase
    const hexValues = rgbValues.map((value) => {
      const hex = Number(value).toString(16).toUpperCase();
      return hex.length === 1 ? "0" + hex : hex;
    });

    // Combine the hexadecimal values
    const hexColor = "#" + hexValues.join("");

    return hexColor;
  }
  return (
    <>
      <MetaTags>
        <title>
          {" "}
          Manage Products | Mouthmap - Your Route To A Healthy Mouth{" "}
        </title>
        <meta
          name="description"
          content="Add and update products, brands, pricing, and content - all active content will be visible in-app immediately."
        />
        <meta
          property="og:title"
          content=" Manage Products | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content="Add and update products, brands, pricing, and content - all active content will be visible in-app immediately."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>
      <Box
        className="manageProducts commonWrapper"
        style={{
          marginTop: headerHeight,
          height: dimensions.height - headerHeight,
        }}
      >
        <Box>
          <Container>
            <Typography variant="h1" className="mainTitle">
              {strings.MANAGEPRODUCTS}
            </Typography>
          </Container>
        </Box>
        <Box>
          <Container>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  className="tabWrap"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <TabList onChange={handleMainTabs} aria-label="Product tabs">
                    <Tab
                      onClick={() => {
                        localStorage.setItem("product-brand", "1");
                      }}
                      label={strings.PRODUCTS}
                      value="1"
                    />
                    <Tab
                      onClick={() => {
                        localStorage.setItem("product-brand", "2");
                      }}
                      label={strings.BRANDS}
                      value="2"
                    />
                    {/* <Tab onClick={() => { brandList(); }} label={strings.BRANDS} value="2" /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box className="topbarWrap" display="flex">
                    <SearchBox
                      placeholder={strings.SEARCH_BY_NAME}
                      value={productText.search}
                      // disabled = { !productSearch === 0 ? 'false' : 'true'}
                      onSearch={(e) => {
                        setProductText({ ...productText, search: e, page: 1 });
                      }}
                      onClear={() => {
                        setProductText({ ...productText, search: "", page: 1 });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setAddProductPopup({ type: true, value: "Add" });
                        setSelectedBrandColor([]);
                        setAvailableBrushSizes([]);
                        setProductId(null);
                        setShowImage("");
                        setProductImg1(null);
                        setShowImage2("");
                        setProductImg2(null);
                        setShowImage3("");
                        setProductImg3(null);
                        setShowImage4("");
                        setProductImg4(null);
                        setCheckedBox(false);
                        setCheckProductSubs(false);
                      }}
                    >
                      <Typography
                        component="span"
                        className="icon-plus-11"
                      ></Typography>
                      {strings.ADD_PRODUCT}
                    </Button>
                  </Box>

                  <Scrollbars
                    onScroll={() => {
                      if (
                        Math.round(
                          scrollBar?.current.getScrollTop() +
                          scrollBar?.current.getClientHeight()
                        ) === scrollBar?.current.getScrollHeight()
                      ) {
                        if (pageLoad) {
                          setProductText({
                            ...productText,
                            page: productText.page + 1,
                          });
                        }
                      }
                    }}
                    ref={scrollBar}
                    style={{
                      height:
                        dimensions.height -
                        headerHeight -
                        mainTitleHeight -
                        tabHeight -
                        searchBarRowHeight -
                        45,
                    }}
                  >
                    <Box className="productList cardList">
                      {productData.length > 0 ? (
                        productData.map((value, index) => {
                          console.log(`productData ----->>>>`, productData);
                          return (
                            <CustomCard
                              // imageurl='https://mouthmap.s3.amazonaws.com/635f617905dbe689a8e557d0/1669268333579.jpeg'
                              imageurl={
                                process.env.REACT_APP_AWS_BASE_URL +
                                value.sProductImage[0]
                              }
                              key={index}
                              imagealt={value.sProductImage[0]}
                              category={
                                value.brandData[0]?.sStatus === "Inactive"
                                  ? "-"
                                  : value.brandData[0]?.sBrandName
                              }
                              title={value.sName}
                              color={value.color ? value.color : '#C75E9D'}
                              brushSize={value.size ? value.size : 'Size-1'}
                              status={value.sStatus}
                              checked={value.sStatus !== "Inactive"}
                              subscription={value.sMonthly_subScription}
                              price={"£" + value.nPrice}
                              deletePopup={() => {
                                setDeleteProductPopup(true);
                                setProductId(value);
                              }}
                              disablePopup={() => {
                                setDisableProductPopup(true);
                                setProductId(value);
                              }}
                              editPopup={() => {
                                setCheckProductSubs(true);
                                setEmptyProductColor(false);
                                setColorforEdit(value.color && value.color)
                                setProductId(value);
                                setShowImage(value.sProductImage[0]);
                                setShowImage2(value.sProductImage[1]);
                                setShowImage3(value.sProductImage[2]);
                                setShowImage4(value.sProductImage[3]);
                                // const newArray = value.sProductImage;
                                // setCheckProductImg(newArray);
                                setCheckProductImg1(value.sProductImage[0]);
                                setCheckProductImg2(value.sProductImage[1]);
                                setCheckProductImg3(value.sProductImage[2]);
                                setCheckProductImg4(value.sProductImage[3]);
                                setAddProductPopup({
                                  type: true,
                                  value: "Edit",
                                });
                                setCheckedBox(value.sMonthly_subScription);
                                setCheckMonthlyTick(
                                  value.sMonthly_subScription
                                );
                                addProduct?.setValue(
                                  "productTitle",
                                  value.sName
                                );
                                setTimeout(() => {
                                  console.log(
                                    "**",
                                    checkProductImg1,
                                    checkProductImg2,
                                    checkProductImg3,
                                    checkProductImg4
                                  );
                                }, 1500);
                              }}
                            />
                          );
                        })
                      ) : (
                        <Box className="no-data">
                          {productText.search === ""
                            ? "No Products Added"
                            : "No Such Products Found"}
                        </Box>
                      )}
                    </Box>
                  </Scrollbars>
                </TabPanel>

                <TabPanel value="2">
                  <Box className="topbarWrap" display="flex">
                    <SearchBox
                      placeholder={strings.SEARCH_BY_BRAND}
                      value={brandText.search}
                      onSearch={(e) => {
                        setBrandText({ ...brandText, search: e, page: 1 });
                      }}
                      onClear={() => {
                        setBrandText({ ...brandText, search: "", page: 1 });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setAddBrandPopup({ type: true, value: "Add" });
                        setBrandId(null);
                      }}
                    >
                      <Typography
                        component="span"
                        className="icon-plus-11"
                      ></Typography>
                      {strings.ADD_BRAND}
                    </Button>
                  </Box>
                  <Scrollbars
                    onScroll={() => {
                      if (
                        Math.round(
                          scrollBar?.current.getScrollTop() +
                          scrollBar?.current.getClientHeight()
                        ) === scrollBar?.current.getScrollHeight()
                      ) {
                        if (pageLoad) {
                          setBrandText({
                            ...brandText,
                            page: brandText.page + 1,
                          });
                        }
                      }
                    }}
                    ref={scrollBar}
                    style={{
                      height:
                        dimensions.height -
                        headerHeight -
                        mainTitleHeight -
                        tabHeight -
                        searchBarRowHeight -
                        30,
                    }}
                  >
                    <Box className="brandList cardList">
                      {brandData.length > 0 ? (
                        brandData.map((value, index) => {
                          let newArr1 = value?.brandColor?.length > 0 ? value?.brandColor : [];
                          let newArr2 = value?.usedBrandColor?.length > 0 ? value?.usedBrandColor : [];
                          let newarr = newArr1.concat(newArr2);
                          console.log(`value ----->>>>`, value);
                          return (
                            <CustomCard
                              key={index}
                              title={value.sBrandName}
                              status={value.sStatus}
                              price={value.price}
                              checked={value.sStatus !== "Inactive"}
                              selectedColors={newarr?.length > 0 ? newarr : selectedColorsForCard}
                              deletePopup={() => {
                                setdeleteBrandPopup(true);
                                setBrandId(value);
                              }}
                              disablePopup={() => {
                                setDisableBrandPopup(true);
                                setBrandId(value);
                              }}
                              editPopup={() => {
                                if (newarr?.length > 0) {
                                  console.log(`newarr ----->>>>`, newarr);
                                  setEditBrandColor(newarr);
                                } else {
                                  console.log(`newarr ----->>>>`, newarr);
                                }
                                setAddBrandPopup({ type: true, value: "Edit" });
                                setBrandId(value);
                              }}
                            />
                          );
                        })
                      ) : (
                        <Box className="no-data">
                          {brandText.search === ""
                            ? "No Brands Added"
                            : "No Such Brand Found"}
                        </Box>
                      )}
                    </Box>
                  </Scrollbars>
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </Box>
      </Box>
      <ModalBox
        className="deleteProductPopup"
        open={deleteProductPopup}
        close={() => {
          setDeleteProductPopup(false);
          setProductId(null);
        }}
        onSubmit={() => {
          setDeleteProductPopup(false);
          deleteProduct();
          setProductId(null);
        }}
        title={strings.DELETE2}
        warningText={`${strings.ARE_YOU_SURE_DELETE + ' "' + productId?.sName + '" ' + "?"
          }`}
        button1={strings.YES_DELETE}
      />
      <ModalBox
        className="disableProductPopup"
        open={disableProductPopup}
        close={() => {
          setSelectedBrandColor([]);
          setAvailableBrushSizes([]);
          setDisableProductPopup(false);
          setProductId(null);
        }}
        onSubmit={() => {
          setSelectedBrandColor([]);
          setAvailableBrushSizes([]);
          setProductId(null);
          setDisableProductPopup(false);
          if (productId?.sStatus === "Active") {
            statusProductApi("Inactive");
          } else {
            statusProductApi("Active");
          }
        }}
        title={
          productId?.sStatus !== "Inactive" ? strings.DISABLE : strings.ENABLE
        }
        warningText={
          productId?.sStatus !== "Inactive"
            ? `${strings.ARE_YOU_SURE_DISABLE_USER +
            ' "' +
            productId?.sName +
            '" ' +
            "?"
            }`
            : `${strings.ARE_YOU_SURE_ENABLE_USER + " " + productId?.sName + "?"
            }`
        }
        button1={
          productId?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
      <AddModal
        open={addProductPopup.type}
        close={() => {
          setSelectedColorForProduct("");
          setColorforEdit("");
          setSelectedBrandColor([]);
          setAvailableBrushSizes([]);
          setProductId(null);
          setAddProductPopup(false);
          setShowImage("");
          setProductImg1(null);
          setShowImage2("");
          setProductImg2(null);
          setShowImage3("");
          setProductImg3(null);
          setShowImage4("");
          setProductImg4(null);
        }}
        className="addPopup"
      >
        <Box sx={style} className="addModalWrap">
          <Typography id="modal-modal-title" variant="h5">
            {addProductPopup.value === "Add"
              ? strings.ADD_PRODUCT
              : strings.EDIT_PRODUCT}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setSelectedColorForProduct("");
                setColorforEdit("");
                setAddProductPopup(false);
                setProductId(null);
                setShowImage("");
                setProductImg1(null);
                setShowImage2("");
                setProductImg2(null);
                setShowImage3("");
                setProductImg3(null);
                setShowImage4("");
                setProductImg4(null);
              }}
            ></Typography>
          </Typography>
          <HookForm
            defaultForm={{
              subscription: isCheckedBox,
              productdropdownSize: productId && productId.size ? productId.size : ""
            }}
            init={(form) => setAddProduct(form)}
            onSubmit={(e) => {
              // setProductText({ ...productText, page: 1 });
              onFormSubmit(e);
              setAddProductPopup(false);
            }}
          >
            {/* { console.log('subscription', isCheckedBox) } */}
            {(formMethod) => {
              return (
                <>
                  <CustomTextField
                    variant="outlined"
                    autoFocus={false}
                    type="text"
                    placeholder={strings.PRODUCT_TITLE}
                    formMethod={formMethod}
                    inputProps={{ maxLength: 70 }}
                    name={addproduct.productTitle.value}
                    rules={addproduct.productTitle.validate}
                    errors={formMethod?.formState.errors}
                    classText="regularInput"
                    defaultValue={
                      productId && productId.sName ? productId.sName : ""
                    }
                  />
                  <Box className="imagesWrap" display="flex">
                    <Box className="uploadedImg">
                      {/* {productImg1 !== '' && <img src={productImg1} {...loader} width="110" height="110" /> } */}
                      <Typography
                        className={
                          showImage ? "icon-cancel" : "icon-cancel hid"
                        }
                        component="span"
                        onClick={() => {
                          setShowImage("");
                          setProductImg1(null);
                        }}
                      ></Typography>
                      <CustomFileUpload
                        onDrop={(acceptImage1) => {
                          onUpload1(acceptImage1);
                        }}
                        accept="image/jpeg,image/jpg,image/png"
                      >
                        {showImage ? (
                          <Box className="image1 image">
                            <img
                              src={
                                showImage
                                  ? process.env.REACT_APP_AWS_BASE_URL +
                                  showImage
                                  : ""
                              }
                            />
                            {loaderone && <Loader />}
                            {console.log(`process.env.REACT_APP_AWS_BASE_URL + showImage ----->>>>`, process.env.REACT_APP_AWS_BASE_URL +
                                  showImage)}
                          </Box>
                        ) : (
                          <Box className="image1 image">
                            {/* {showImage && <img src={showImage ? process.env.REACT_APP_AWS_BASE_URL + showImage : ''} alt=''></img>} */}
                            <Typography
                              component="span"
                              className="icon-thumbnail"
                            ></Typography>
                            <Typography component="div" className="uploadText">
                              {strings.UPLOAD}
                            </Typography>
                          </Box>
                        )}
                      </CustomFileUpload>
                    </Box>
                    <Box className="uploadedImg">
                      {/* {productImg1 !== '' && <img src={productImg1} {...loader} width="110" height="110" /> } */}
                      <Typography
                        className={
                          showImage2 ? "icon-cancel" : "icon-cancel hid"
                        }
                        component="span"
                        onClick={() => {
                          setShowImage2("");
                          setProductImg2(null);
                        }}
                      ></Typography>
                      <CustomFileUpload
                        onDrop={(acceptImage2) => {
                          onUpload2(acceptImage2);
                        }}
                        accept="image/jpeg,image/jpg,image/png"
                      >
                        {showImage2 ? (
                          <Box className="image1 image">
                            <img
                              src={
                                showImage2
                                  ? process.env.REACT_APP_AWS_BASE_URL +
                                  showImage2
                                  : ""
                              }
                            />
                            {loadertwo && <Loader />}
                          </Box>
                        ) : (
                          <Box className="image1 image">
                            {/* {showImage && <img src={showImage2 ? process.env.REACT_APP_AWS_BASE_URL + showImage2 : ''} alt=''></img>} */}
                            <Typography
                              component="span"
                              className="icon-thumbnail"
                            ></Typography>
                            <Typography component="div" className="uploadText">
                              {strings.UPLOAD}
                            </Typography>
                          </Box>
                        )}
                      </CustomFileUpload>
                    </Box>

                    <Box className="uploadedImg">
                      {/* {productImg1 !== '' && <img src={productImg1} {...loader} width="110" height="110" /> } */}
                      <Typography
                        className={
                          showImage3 ? "icon-cancel" : "icon-cancel hid"
                        }
                        component="span"
                        onClick={() => {
                          setShowImage3("");
                          setProductImg3(null);
                        }}
                      ></Typography>
                      <CustomFileUpload
                        onDrop={(acceptImage3) => {
                          onUpload3(acceptImage3);
                        }}
                        accept="image/jpeg,image/jpg,image/png"
                      >
                        {showImage3 ? (
                          <Box className="image1 image">
                            <img
                              src={
                                showImage3
                                  ? process.env.REACT_APP_AWS_BASE_URL +
                                  showImage3
                                  : ""
                              }
                            />
                            {loaderthree && <Loader />}
                          </Box>
                        ) : (
                          <Box className="image1 image">
                            <Typography
                              component="span"
                              className="icon-thumbnail"
                            ></Typography>
                            <Typography component="div" className="uploadText">
                              {strings.UPLOAD}
                            </Typography>
                          </Box>
                        )}
                      </CustomFileUpload>
                    </Box>

                    <Box className="uploadedImg">
                      <Typography
                        className={
                          showImage4 ? "icon-cancel" : "icon-cancel hid"
                        }
                        component="span"
                        onClick={() => {
                          setShowImage4("");
                          setProductImg4(null);
                        }}
                      ></Typography>
                      <CustomFileUpload
                        onDrop={(acceptImage4) => {
                          onUpload4(acceptImage4);
                        }}
                        accept="image/jpeg,image/jpg,image/png"
                      >
                        {showImage4 ? (
                          <Box className="image1 image">
                            <img
                              src={
                                showImage4
                                  ? process.env.REACT_APP_AWS_BASE_URL +
                                  showImage4
                                  : ""
                              }
                            />
                            {loaderfour && <Loader />}
                          </Box>
                        ) : (
                          <Box className="image1 image">
                            {/* {showImage && <img src={showImage4 ? process.env.REACT_APP_AWS_BASE_URL + showImage4 : ''} alt=''></img>} */}
                            <Typography
                              component="span"
                              className="icon-thumbnail"
                            ></Typography>
                            <Typography component="div" className="uploadText">
                              {strings.UPLOAD}
                            </Typography>
                          </Box>
                        )}
                      </CustomFileUpload>
                    </Box>
                  </Box>
                  <CustomTextField
                    placeholder={strings.PRICE}
                    variant="outlined"
                    autoFocus={false}
                    onKeyPress={(e) => e.key === "e" && e.preventDefault()}
                    numberMask={true}
                    classText="regularInput withSign masking"
                    type="number"
                    formMethod={formMethod}
                    name={addproduct.price.value}
                    rules={addproduct.price.validate}
                    errors={formMethod?.formState.errors}
                    defaultValue={
                      productId && productId.nPrice ? productId.nPrice : ""
                    }
                  />
                  <Box className="subscriptionBox">
                    <CustomCheckBox
                      label={strings.MONTHLY_SUBSCRIPTION}
                      checked={checkedBox}
                      disabled={checkProductSubs}
                      onCheckedChange={(checked) => {
                        console.log(
                          "formMethod?.formState",
                          formMethod?.formState,
                          checkProductSubs
                        );
                        setCheckedBox(checked);
                      }}
                    />
                  </Box>
                  <Controller
                    render={({
                      field: { onChange, SelectedValue },
                      // Console.log('SelectedValueSelectedValue', SelectedValue);
                    }) => (
                      <CustomDropdown
                        disabled={addProductPopup.value !== "Add" || !branddropdownData.length > 0}
                        mapData={branddropdownData}
                        multiple={false}
                        withCheckBox={false}
                        fieldPlaceHodler={strings.PRODUCT_BRAND}
                        addText="Add Brand"
                        btnTxt="Add"
                        customButton="true"
                        onAddClick={(addData) => addBrandApi(addData)}
                        onChange={(selectedValue) => {
                          onChange(selectedValue);
                          let selectedBrand = allBrands.filter((brand) => brand.sBrandName === selectedValue.target.value)
                          let arr = selectedBrand[0]?.brandColor ? selectedBrand[0]?.brandColor : []
                          let brushes = allBrushSizes.filter((brush) => !selectedBrand[0]?.usedSize?.includes(brush.size));
                          setAvailableBrushSizes(brushes);
                          setSelectedBrandColor(arr);
                          formMethod.setValue("productdropdownSize", "");
                          setEmptyProductColor(true);
                      }}
                        // SelectedValue = {SelectedValue}
                        // setSelectValue = {event.tar}
                        defaultValueBrand={
                          productId?.brandData && productId?.brandData[0]?._id
                            ? productId.brandData[0]?._id
                            : ""
                        }
                      />
                    )}
                    control={formMethod.control}
                    name={addproduct.productdropdown.value}
                    rules={addproduct.productdropdown.validate}
                    defaultValue={
                      productId?.brandData &&
                        productId?.brandData[0]?.sBrandName
                        ? productId.brandData[0]?.sBrandName
                        : ""
                    }
                  // defaultValue = {formMethod.watch(addproduct.brandData.sBrandName)}
                  />
                  <Box className="mainRow">
                    <Box className="leftDropdown">
                      {/* <CustomDropdown
                        mapData={brushSizes}
                        multiple={false}
                        withCheckBox={false}
                        fieldPlaceHodler="Select Brush Size"
                        customButton="false"
                        onChange={handleChange}
                        selectValue={selectedSize}
                      /> */}
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <CustomDropdown
                            sx={addProductPopup.value === "Edit" ? {
                              '& .MuiSelect-select': {
                                cursor: 'none !important',
                              }
                            } : {}}     
                            disabled={addProductPopup.value !== "Add" || !availableBrushSizes.length > 0}
                            mapData={availableBrushSizes}
                            multiple={false}
                            customButton={false}
                            fieldPlaceHodler={'Size'}
                            withCheckBox={false}
                            onChange={(item) => {
                              onChange(item);
                              console.log(`item.target.value ----->>>>`, item.target.value);
                            }}
                            defaultValueSize={value}
                          />
                        )}
                        control={formMethod.control}
                        name={addproduct.productdropdownSize.value}
                        rules={addproduct.productdropdownSize.validate}
                      />
                    </Box>
                    <Box className="rightDropdown hidecolor">
                      <CustomDropdown
                        sx={addProductPopup.value === "Edit" ? {
                          '& .MuiSelect-select': {
                            cursor: 'none !important',
                          }
                        } : {}}                      
                        colorforEdit={colorforEdit}
                        emptyProductColor={emptyProductColor}
                        disabled={addProductPopup.value !== "Add" || !selectedBrandColor.length > 0}
                        mapData={selectedBrandColor}
                        withCheckBox={false}
                        fieldPlaceHodler={'Colour'}
                        withColorOptions={true}
                        colorOptions={selectedBrandColor}
                        onChange={async (event) => {
                          setEmptyProductColor(false);
                          console.log('selected color', event?.target?.style?.backgroundColor);
                          let x = await rgbToHex(event?.target?.style?.backgroundColor);
                          setSelectedColorForProduct(x.toString())
                          console.log(`x ----->>>>`, x);
                        }}
                      />
                    </Box>
                  </Box>
                  <CustomTextField
                    variant="outlined"
                    autoFocus={false}
                    multiline
                    maxRows={4}
                    inputProps={{ maxLength: 2509 }}
                    placeholder={strings.DESCRIPTION}
                    formMethod={formMethod}
                    name={addproduct.productbrand.value}
                    rules={addproduct.productbrand.validate}
                    errors={formMethod?.formState.errors}
                    classText="regularInput txtarea"
                    defaultValue={
                      productId && productId.sDescription
                        ? productId.sDescription
                        : ""
                    }
                  />
                  <Box className="btnWrap" display="flex">
                    <CustomLoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      btnTitle={
                        addProductPopup.value === "Add"
                          ? strings.ADD
                          : strings.UPDATE
                      }
                      // loading={busy}
                      // onClick={() => setPopupState3(false)}
                      // disabled={!formMethod?.formState.isValid || !formMethod?.formState.isDirty}
                      // disabled={addProductPopup.value === 'Add' ? !formMethod?.formState.isValid || !showImage : !formMethod?.formState.isDirty}
                      // disabled={}

                      // disabled={checkedBox && (addProductPopup.value === 'Add' ? (!formMethod?.formState.isValid || !productImg1 || !productImg2 || !productImg3 || !productImg4) : (!formMethod?.formState.isDirty))}
                      disabled={
                        // !showImage ||
                        // !showImage2 ||
                        // !showImage3 ||
                        // !showImage4 ||
                        // (((checkProductImg1 === showImage &&
                        //   checkProductImg2 === showImage2 &&
                        //   checkProductImg3 === showImage3 &&
                        //   checkProductImg4 === showImage4) ||
                        //   (!showImage &&
                        //     !showImage2 &&
                        //     !showImage3 &&
                        //     !showImage4)) &&
                        //   (addProductPopup.value === "Add"
                        //     ? !formMethod?.formState.isValid
                        //     : !formMethod?.formState.isDirty))
                        
                        // disable add or edit button. 
                        addProductPopup.value === "Add"
                            ? !formMethod?.formState.isValid || !selectedColorForProduct || !(showImage || showImage2 || showImage3 || showImage4)
                            : (!formMethod?.formState.isDirty
                              && checkProductImg1 === showImage && checkProductImg2 === showImage2 && checkProductImg3 === showImage3 && checkProductImg4 === showImage4)
                              || !(showImage || showImage2 || showImage3 || showImage4)
                      }
                      // loading= {busy}
                      className="applyBtn"
                    />
                    <Button
                      onClick={() => {
                        setSelectedColorForProduct("");
                        setColorforEdit("");
                        setAddProductPopup(false);
                        setProductId(null);
                        setShowImage("");
                        setProductImg1(null);
                        setShowImage2("");
                        setProductImg2(null);
                        setShowImage3("");
                        setProductImg3(null);
                        setShowImage4("");
                        setProductImg4(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              );
            }}
          </HookForm>
        </Box>
      </AddModal>
      <ModalBox
        className="deleteBrandPopup"
        open={deleteBrandPopup}
        close={() => {
          setdeleteBrandPopup(false);
          setBrandId(null);
        }}
        onSubmit={() => {
          setdeleteBrandPopup(false);
          deleteBrandApi();
          setBrandId(null);
        }}
        title={strings.DELETE2}
        warningText={`${strings.ARE_YOU_SURE_DELETE_BRAND +
          ' "' +
          brandId?.sBrandName +
          '" ' +
          "?"
          }`}
        button1={strings.YES_DELETE}
      />
      <ModalBox
        className="disableBrandPopup"
        open={disableBrandPopup}
        close={() => {
          setDisableBrandPopup(false);
          setBrandId(null);
        }}
        onSubmit={() => {
          setBrandId(null);
          setDisableBrandPopup(false);
          if (brandId?.sStatus === "Active") {
            statusBrandApi("Inactive");
          } else {
            statusBrandApi("Active");
          }
        }}
        title={
          brandId?.sStatus !== "Inactive" ? strings.DISABLE : strings.ENABLE
        }
        warningText={
          brandId?.sStatus !== "Inactive"
            ? `${strings.ARE_YOU_SURE_DISABLE_USER +
            ' "' +
            brandId?.sBrandName +
            '" ' +
            "?"
            }`
            : `${strings.ARE_YOU_SURE_ENABLE_USER +
            " " +
            brandId?.sBrandName +
            "?"
            }`
        }
        button1={
          brandId?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
      <AddModal
        open={addBrandPopup.type}
        close={() => {
          setEditBrandColor(selectedColorsForCard);
          setSelectedColors([]);
          setBrandId(null);
          setAddBrandPopup(false);
        }}
      >
        <Box sx={style} className="addBrandModalWrap">
          <Typography id="modal-modal-title" variant="h5">
            {addBrandPopup.value === "Add"
              ? strings.ADD_PRODUCT_BRAND
              : strings.EDIT_PRODUCT_BRAND}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setSelectedColors([]);
                setAddBrandPopup(false);
                setBrandId(null);
              }}
            ></Typography>
          </Typography>
          <HookForm
            init={(form) => setAddBrand(addBrand)}
            onSubmit={(e) => {
              onFormSubmit2(e);
              setEditBrandColor(selectedColorsForCard)
              setAddBrandPopup(false);
            }}
          >
            {(formMethod) => {
              return (
                <>
                  <Box>
                    <CustomTextField
                      variant="outlined"
                      autoFocus={false}
                      type="paste"
                      placeholder={strings.PRODUCT_BRAND_NAME}
                      formMethod={formMethod}
                      inputProps={{ maxLength: 100 }}
                      name={addbrand.name.value}
                      rules={addbrand.name.validate}
                      errors={formMethod?.formState.errors}
                      classText="regularInput"
                      defaultValue={
                        brandId && brandId.sBrandName ? brandId.sBrandName : ""
                      }
                    />
                    <Box>

                      {
                        addBrandPopup.value === "Add"
                          ?
                          <><Typography className="brandTitle">Select brand Colours</Typography><CustomLabel label='Select 10 colours for the brand' /><div className="selectedColors">
                            {[...Array(10)].map((_, index) => (
                              <div
                                key={index}
                                className="selectedColorBox"
                                style={{ backgroundColor: selectedColors[index] ? selectedColors[index] : '#DFDFDF' }}
                              ></div>
                            ))}
                          </div><div className="colorPalette">
                              {allColors.map((color, index) => (
                                <div
                                  key={index}
                                  className={`colorBox ${selectedColors.includes(color) ? 'selectedBoxColor' : ''}`}
                                  style={{ backgroundColor: color }}
                                  onClick={() => handleColorSelect(color)}
                                >
                                </div>
                              ))}
                            </div></>
                          :
                          <div>
                            {/* <CustomLabel label='Selected colours for the brand' /> */}
                            <Box className="editColorBoxes">
                              {editBrandColor.map((color, index) => (
                                <div
                                  key={index}
                                  className="colorBox"
                                  style={{ backgroundColor: color, cursor:'default' }}
                                ></div>
                              ))}
                            </Box>
                          </div>
                      }
                    </Box>
                  </Box>
                  <Box className="btnWrap">
                    <CustomLoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      btnTitle={
                        addBrandPopup.value === "Add" ? "Add brand" : "Update"
                      }
                      // loading={busy}
                      disabled={
                        addBrandPopup.value === "Add"
                          ? !formMethod?.formState.isValid || selectedColors.length !== 10
                          : !formMethod?.formState.isValid ||
                          !formMethod?.formState.isDirty
                      }
                      className="applyBtn"
                    />
                    <Button
                      onClick={() => {
                        setSelectedColors([]);
                        setAddBrandPopup(false);
                        setBrandId(null);
                      }}
                    >
                      {strings.CANCEL}
                    </Button>
                  </Box>
                </>
              );
            }}
          </HookForm>
        </Box>
      </AddModal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth?.user,
  };
};
const mapDispatchToProps = {
  showToast,
  setUser,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(ManageProducts));
