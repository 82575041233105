/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import { Box, Button, Chip, IconButton, Tab, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useNavigate, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { multilanguage } from 'redux-multilanguage';

import ModalBox from '../../components/ModalBox/ModalBox';
import SearchBox from '../../components/SearchBox/SearchBox';
import ControlledSwitches from '../../components/SwitchToggler/SwitchToggler';

import instance from '../../api/axios';
import useWindowDimension from '../../hooks/useWindowDimension';

import './UserManagementDetails.scss';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { MetaTags } from 'react-meta-tags';

const UserManagementDetails = (props) => {
    const { strings } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const dimensions = useWindowDimension();
    const [tabbingValue, setTabbingValue] = useState('1');
    const [searchText, setSearchText] = useState({
        search: ''
    });
    const [searchData, setSearchData] = useState({
        search: ''
    });
    const [deleteUserListData, setDeleteUserListData] = useState(false);
    const [disableUserData, setDisableUserData] = useState(false);
    const [singleItem, setSingleItem] = useState();
    const data = location.state;
    console.log('user-manage-detail-data', data);
    const [disableUser, setDisableUser] = useState(data?.sStatus);
    const today = new Date(data?.createdAt);
    const [userManagementOnetimeListing, setUserManagementOnetimeListing] = useState([]);
    const [userManagementSubcribeListing, setUserManagementSubcribeListing] = useState([]);
    const [userManagementUnSubcribeListing, setUserManagementUnSubcribeListing] = useState([]);
    console.log(userManagementUnSubcribeListing, 'userManagementUnSubcribeListing');

    useEffect(() => {
        oneTimeApiList();
    }, [searchText]);

    useEffect(() => {
        subscribeListAPi();
    }, [searchData]);

    useEffect(() => {
        unSubscribeListAPi();
    }, [searchData]);

    const oneTimeApiList = async () => {
        console.log('userid', data._id);
        let params = `?userId=${data._id}`;
        if (searchText.search) {
            params += `&search=${searchText.search}`;
        }
        await instance.get(`/user/oneTimeProduct${params}`)
            .then((res) => {
                console.log('oneTimeApiList', res);
                setUserManagementOnetimeListing(res.payload.myOrder);
            }).catch((e) => {
                console.log(e);
            });
    };

    const subscribeListAPi = async () => {
        let params = `?userId=${data._id}`;
        if (searchData.search) {
            params += `&search=${searchData.search}`;
        }
        await instance.get(`/user/subScribeProduct${params}`)
            .then((res) => {
                console.log(res);
                setUserManagementSubcribeListing(res.payload.myOrder);
            }).catch((e) => {
                console.log(e);
            });
    };
    const unSubscribeListAPi = async () => {
        let params = `?userId=${data._id}`;
        if (searchData.search) {
            params += `&search=${searchData.search}`;
        }
        await instance.get(`/user/unSubScribeProduct${params}`)
            .then((res) => {
                console.log(res);
                setUserManagementUnSubcribeListing(res.payload.myOrder);
            }).catch((e) => {
                console.log(e);
            });
    };
    const [headerHeight, setHeaderHeight] = useState(0);
    const [SearchHeight, setSearchHeight] = useState(0);
    const [backToButtonHeight, setbackToButtonHeight] = useState(0);
    const [tabHeight, setTabHeight] = useState(0);
    const [topSelectedUserDataHeight, setTopSelectedUserDataHeight] = useState(0);
    useEffect(() => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchBoxDiv')[0]?.offsetHeight);
        setTabHeight(document.getElementsByClassName('tabWrap')[0]?.offsetHeight);
        setTopSelectedUserDataHeight(document.getElementsByClassName('topSelectedUserData')[0]?.offsetHeight);
        setbackToButtonHeight(document.getElementsByClassName('backToButton')[0]?.offsetHeight);
    }, []);
    const handleResize = () => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchBoxDiv')[0]?.offsetHeight);
        setTabHeight(document.getElementsByClassName('tabWrap')[0]?.offsetHeight);
        setTopSelectedUserDataHeight(document.getElementsByClassName('topSelectedUserData')[0]?.offsetHeight);
        setbackToButtonHeight(document.getElementsByClassName('backToButton')[0]?.offsetHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    const userDeleteApi = async () => {
        await instance.delete(`/user/user/${data._id}`)
            .then((res) => {
                setTimeout(() => {
                    navigate(-1);
                });
            }).catch((e) => {
                console.log(e);
            });
    };
    const userStatusApi = async (value) => {
        const params = {
            id: data._id,
            sStatus: value
        };
        await instance.put('/user/user/updateStatus/', params)
            .then((res) => {
                console.log('delete', res);
            }).catch((e) => {
                console.log('delete', e);
            });
    };
    const downloadPDFGroups = (data) => {
        const input = document.getElementById('test');
        html2canvas(input)
            .then((canvas) => {
                const imgWidth = 208;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                const imgData = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                // pdf.output('dataurlnewwindow');
                pdf.save('download.pdf');
            });
    };

    return (
        <>
            <MetaTags>
                <title> User Management Details | Mouthmap - Your Route To A Healthy Mouth </title>
                <meta
                    name="description"
                    content="See all current app users and view their subscription status, regular clinic, date of registration, and lots more. "
                />
                <meta
                    property="og:title"
                    content=" User Management Details | Mouthmap - Your Route To A Healthy Mouth"
                />
                <meta
                    property="og:description"
                    content="See all current app users and view their subscription status, regular clinic, date of registration, and lots more."
                />
                <meta
                    property="og:image"
                    content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
                />
            </MetaTags>
            <Scrollbars className='oneTimeSubcribe' style={{ marginTop: headerHeight, height: dimensions.height - headerHeight }}>
                <Box className='mainContainer'>
                    <Box className='backToButton'>
                        <Button
                            onClick={() => navigate(-1)}
                            variant="text"
                            startIcon={<i className='icon-arrow-left'></i>}>
                            Back
                        </Button>
                    </Box>
                    <Box className='topSelectedUserData'>
                        <Box className='leftSecletedData'>
                            <Typography className='userShortName' variant='body2'>{data.sFirstName?.charAt(0) + data.sLastName?.charAt(0)}</Typography>
                            <Box className='userFullDetails'>
                                <Box className='userActiveStatus'>
                                    <Typography className='' variant='body2'>{data.sFirstName + ' ' + data.sLastName}</Typography>
                                    <Chip label={disableUser} variant="outlined" className={disableUser === 'Active' ? 'activeStatus' : 'activeStatus inactive'} />
                                </Box>
                                <Box className='userIdInformation'>
                                    <Box className='userIdInformationColmun'>
                                        <Typography className='titleKey' variant='body2'>Email :</Typography>
                                        <Typography className='titleValue' variant='body2'>{data?.sEmail}</Typography>
                                    </Box>
                                    <Box className='userIdInformationColmun'>
                                        <Typography className='titleKey' variant='body2'>Registered On :</Typography>
                                        <Typography className='titleValue' variant='body2'>{today.toGMTString().substring(5, 16)}</Typography>
                                    </Box>
                                    <Box className='userIdInformationColmun'>
                                        <Typography className='titleKey' variant='body2'>Clinician Code :</Typography>
                                        <Typography className='titleValue' variant='body2'>{data.sClinicianCode ? data.sClinicianCode : 'N011'}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className='rightSelectedDataStatusActive'>
                            <Button className='deleteBtn' variant="text" onClick={() => setDeleteUserListData(true)}>Delete</Button>
                            <ControlledSwitches
                                checked={(disableUser !== 'Inactive')}
                                onClick={() => { setDisableUserData(true); }}
                            />
                        </Box>
                    </Box>
                    <Box className='usermanagementTabbing' >
                        <TabContext value={tabbingValue}>
                            <Box className='tabWrap desktopsizeTab' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {/* ==============DesktopSizeTabbingStart================== */}
                                <TabList onChange={(event, newValue) => setTabbingValue(newValue) || console.log('event====>', event, newValue)} aria-label="Product tabs">
                                    <Tab label='One-time Purchases' value="1" />
                                    <Tab label='Subscriptions' value="2" />
                                </TabList>
                                {/* ==============DesktopSizeTabbingEnd================== */}
                            </Box>
                            <Box className='tabWrap mobilesizeShow' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {/* ==============MobileSizeTabbingStart================== */}
                                <TabList onChange={(event, newValue) => setTabbingValue(newValue)} aria-label="Product tabs">
                                    <Tab label='One-time Purchases' value="1" />
                                    <Tab label='Subscriptions' value="2" />
                                </TabList>
                                {/* ==============MobileSizeTabbingEnd================== */}
                            </Box>
                            <TabPanel value='1'>
                                <SearchBox
                                    placeholder='Search by Product name, Order Id'
                                    value={searchText.search}
                                    onSearch={(e) => {
                                        setSearchText({ ...searchText, search: e, page: 1 });
                                    }}
                                    onClear={() => {
                                        setSearchText({ ...searchText, search: '', page: 1 });
                                    }}
                                />
                                <Box className='userManagementSubscribedListing' >
                                    <Scrollbars className='userManagementOntimeDataListing' style={{ height: (dimensions.height - headerHeight - SearchHeight - tabHeight - backToButtonHeight - topSelectedUserDataHeight - 105) }}>
                                        <Table stickyHeader className='UserManagementtable' id='test'>
                                            <TableHead className='tableHeader'>
                                                <TableRow className='tableHeaderRow' >
                                                    <TableCell className='tableHeaderColumn'>Product Name</TableCell>
                                                    <TableCell className='tableHeaderColumn' >Order Id</TableCell>
                                                    <TableCell className='tableHeaderColumn'>Price</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tableBody'>
                                                {userManagementOnetimeListing.length > 0
                                                    ? userManagementOnetimeListing.map((item, index) => {
                                                        return (
                                                            <TableRow className='tableBodyRow' key={`tablerow${index}`}>
                                                                <TableCell className='tableBodyColumn'>
                                                                    <Box className='listingImageBoxRow'>
                                                                        <Box className='listingImageBoxRow'>
                                                                            <img src={process.env.REACT_APP_AWS_BASE_URL + item.products.prodctData.sProductImage[0]} alt='listingImage' className='image' />
                                                                        </Box>
                                                                        <Typography className='brushColor' style={{ backgroundColor: item.products.prodctData.color ? item.products.prodctData.color : 'black' }}></Typography>
                                                                        <Typography className='productTitleName'>{item.products.prodctData.sName}</Typography>
                                                                        <Typography className='productSize'>{item.products.prodctData.size ? item.products.prodctData.size : '-'}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className='tableBodyColumn' >{item.sOrderNo}</TableCell>
                                                                <TableCell className='tableBodyColumn'>
                                                                    <Box className='downloadOnetimeData'>
                                                                        <Typography className='productTitle'>£{item.products.prodctData.nPrice}</Typography>
                                                                        <IconButton color="primary" aria-label="upload picture" onClick={() => { setSingleItem(item); downloadPDFGroups(); }} className='downloadFile' component="label">
                                                                            <i className='icon-download'></i>
                                                                        </IconButton>
                                                                    </Box>

                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : <TableRow><TableCell colSpan="5"><Box className='no-data'>{searchText.search === '' ? 'Nothing to show yet' : 'No Subscribed Product Added'}</Box></TableCell></TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Scrollbars>
                                </Box>
                            </TabPanel>
                            <TabPanel value='2'>
                                <SearchBox
                                    placeholder='Search by Product name, Order Id'
                                    value={searchData.search}
                                    onSearch={(e) => {
                                        setSearchData({ ...searchData, search: e, page: 1 });
                                    }}
                                    onClear={() => {
                                        setSearchData({ ...searchData, search: '', page: 1 });
                                    }}
                                />
                                <Box className='userManagementSubscribedListing'>
                                    <Typography className='pastSubscribesTitle'>Subscriptions</Typography>
                                    <Scrollbars className='userManagementOntimeDataListing' style={{ height: 302 }}>
                                        <Table stickyHeader className='UserManagementtable'>
                                            <TableHead className='tableHeader'>
                                                <TableRow className='tableHeaderRow'>
                                                    <TableCell className='tableHeaderColumn'>Product Name</TableCell>
                                                    <TableCell className='tableHeaderColumn'>Order Id</TableCell>
                                                    <TableCell className='tableHeaderColumn'>Price</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tableBody'>
                                                {userManagementSubcribeListing.length > 0
                                                    ? userManagementSubcribeListing.map((item, index) => {
                                                        console.log('helloo subscribeee', userManagementSubcribeListing);
                                                        return (
                                                            <TableRow className='tableBodyRow' key={index}>
                                                                <TableCell className='tableBodyColumn'>
                                                                    <Box className='listingImageBoxRow'>
                                                                        <Box className='listingImageBoxRow'>
                                                                            <img src={process.env.REACT_APP_AWS_BASE_URL + item.products.prodctData.sProductImage[0]} alt='listingImage' className='image' />
                                                                        </Box>
                                                                        <Typography className='brushColor' style={{ backgroundColor: item.products.prodctData.color ? item.products.prodctData.color : 'black' }}></Typography>
                                                                        <Typography className='productTitleName'>{item.products.prodctData.sName}</Typography>
                                                                        <Typography className='productSize'>{item.products.prodctData.size ? item.products.prodctData.size : '-'}</Typography>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className='tableBodyColumn'>{item.sOrderNo}</TableCell>
                                                                <TableCell className='tableBodyColumn'>{item.products.prodctData.nPrice}</TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                                    : <TableRow><TableCell colSpan="5"><Box className='no-data'>{searchText.search === '' ? 'Nothing to show yet' : 'No Subscribed Product Added'}</Box></TableCell></TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </Scrollbars>
                                </Box>
                                <Box className='pastSubscribesSection'>
                                    <Typography className='pastSubscribesTitle'>Past Subscriptions</Typography>
                                    <Box className='userManagementPastSubscribeListing'>
                                        <Scrollbars className='userManagementOntimeDataListing' style={{ height: 240 }}>
                                            <Table stickyHeader className='UserManagementtable'>
                                                <TableHead className='tableHeader'>
                                                    <TableRow className='tableHeaderRow'>
                                                        <TableCell className='tableHeaderColumn'>Product Name</TableCell>
                                                        <TableCell className='tableHeaderColumn'>Order Id</TableCell>
                                                        <TableCell className='tableHeaderColumn'>Price</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody className='tableBody'>
                                                    {userManagementUnSubcribeListing.length > 0
                                                        ? userManagementUnSubcribeListing.map((item, index) => {
                                                            return (
                                                                <TableRow className='tableBodyRow' key={index}>
                                                                    <TableCell className='tableBodyColumn'>
                                                                        <Box className='listingImageBoxRow'>
                                                                            <Box className='listingImageBoxRow'>
                                                                                <img src={process.env.REACT_APP_AWS_BASE_URL + item.products.prodctData.sProductImage[0]} alt='listingImage' className='image' />
                                                                            </Box>
                                                                            <Typography className='brushColor' style={{ backgroundColor: item.products.prodctData.color ? item.products.prodctData.color : 'black' }}></Typography>
                                                                            <Typography className='productTitleName'>{item.products.prodctData.sName}</Typography>
                                                                            <Typography className='productSize'>{item.products.prodctData.size ? item.products.prodctData.size : '-'}</Typography>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell className='tableBodyColumn'>{item.sOrderNo}</TableCell>
                                                                    <TableCell className='tableBodyColumn'>{item.products.prodctData.nPrice}</TableCell>
                                                                </TableRow>
                                                            );
                                                        })
                                                        : <TableRow><TableCell colSpan="5"><Box className='no-data'>{searchText.search === '' ? 'Nothing to show yet' : 'No Subscribed Product Added'}</Box></TableCell></TableRow>
                                                        // : 'No Such User Found'
                                                    }
                                                </TableBody>
                                            </Table>
                                        </Scrollbars>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </Box>
            </Scrollbars>
            <ModalBox
                open={deleteUserListData}
                close={() => setDeleteUserListData(false)}
                title='Delete!'
                warningText={`${strings.ARE_YOU_SURE_DELETE_USER + ' ' + data.sFirstName + ' ' + data.sLastName + '?'}`}
                onSubmit={() => { setDeleteUserListData(false); userDeleteApi(); }}
                button1='Yes, Delete'
            />
            <ModalBox
                open={disableUserData}
                close={() => setDisableUserData(false)}
                title={disableUser !== 'Inactive' ? strings.DISABLE : strings.ENABLE}
                warningText={disableUser !== 'Inactive' ? `${strings.ARE_YOU_SURE_DISABLE_USER + ' ' + data?.sFirstName + ' ' + data?.sLastName + '?'}` : `${strings.ARE_YOU_SURE_ENABLE_USER + ' ' + data?.sFirstName + ' ' + data?.sLastName + '?'}`}
                onSubmit={() => { setDisableUserData(false); if (disableUser === 'Active') { setDisableUser('Inactive'); userStatusApi('Inactive'); } else { setDisableUser('Active'); userStatusApi('Active'); } }}
                button1={disableUser !== 'Inactive' ? strings.YES_DISABLE : strings.YES_ENABLE}
            />
            <Table stickyHeader className='UserManagementtable' id='test' >
                <TableHead className='tableHeader'>
                    <TableRow className='tableHeaderRow' >
                        <TableCell className='tableHeaderColumn'>Product Name</TableCell>
                        <TableCell className='tableHeaderColumn' >Order Id</TableCell>
                        <TableCell className='tableHeaderColumn'>Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className='tableBody'>
                    <TableRow className='tableBodyRow'>
                        <TableCell className='tableBodyColumn'>
                            <Box className='listingImageBoxRow'>
                                <Box className='listingImageBoxRow'>
                                    <img src={process.env.REACT_APP_AWS_BASE_URL + singleItem?.products?.prodctData?.sProductImage[0]} alt='listingImage' className='image' />
                                </Box>
                                <Typography className='productTitleName'>{singleItem?.products?.prodctData?.sName}</Typography>
                            </Box>
                        </TableCell>
                        <TableCell className='tableBodyColumn' >{singleItem?.sOrderNo}</TableCell>
                        <TableCell className='tableBodyColumn'>
                            <Box className='downloadOnetimeData'>
                                <Typography className='productTitle'>{singleItem?.products?.prodctData?.nPrice}</Typography>
                            </Box>

                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
};

export default multilanguage(UserManagementDetails);
