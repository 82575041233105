import React from 'react';
import { multilanguage } from 'redux-multilanguage';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Box, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import './CustomArticle.scss';

const CustomArticle = (props) => {
    const { strings } = props;
    const label = { inputProps: { 'aria-label': 'Switch' } };
    const deletePopup = (e) => {
        e.preventDefault();
        props.deletePopup();
    };
    return (
        <Card sx={{ maxWidth: 398 }} className={props.pin === true ? 'cardWrap pinned' : 'cardWrap notPinned'}>
            <Box className='rightWrap'>
                <CardContent className='cardContent'>
                    <Box className='cardTop'>
                        <Typography variant='h3' className='title'>{props.title}</Typography>
                        <Typography component="span" className="icon-pin"></Typography>
                        <Typography component="span" className="icon-drag"></Typography>
                        <Typography component="span" className={props.pinLoading ? 'pin-status disabled' : 'pin-status'} onClick={props.pin === true ? () => props.articleUnpin() : () => props.articlePin() }>{props.pin === true ? 'Unpin' : 'Pin'}</Typography>
                    </Box>
                </CardContent>
                <CardActions className="cardBottom">
                    <Typography gutterBottom variant="subtitle2" className='category' component="div">
                        <Box className="smallTitle">{strings.CATEGORY}</Box>
                        <Typography component="div">{props.category}</Typography>
                    </Typography>
                    <Typography variant="subtitle2" component="div" className={props.status === 'Active' ? 'active' : 'inactive'}>
                        <Box className="smallTitle">{strings.STATUS}</Box>
                        <Typography component="div" className="statusText">{props.status}</Typography>
                    </Typography>
                    <Box className={'rightSide'}>
                        {/* <Box className={props.pin === false ? 'rightSide' : 'rightSide hid'}> */}
                        <Button size="small" onClick={props.editPopup}>{strings.EDIT}</Button>
                        <Button size="small" className='deleteBtn' onClick={(e) => deletePopup(e)} >{strings.DELETE}</Button>
                        <Switch {...label} checked={props?.checked} disableTouchRipple onClick={(e) => props.disablePopup(e)} />
                    </Box>
                </CardActions>
            </Box>
        </Card>
    );
};

export default multilanguage(CustomArticle);
