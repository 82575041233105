import React, { useState, useEffect } from 'react';
import { Checkbox, ListItemText, TextField, Typography } from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/material';

import './CustomDropdown.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    disablePortal: true
};
const CustomDropdown = (props) => {
    const [selectValue, setSelectValue] = useState([]);
    const [addBtn, setAddBtn] = useState(false);
    const [addValue, setAddValue] = useState('');
    const [displayColorBox, setDisplayColorBox] = useState(false);
    const [selectedColorBox, setSelectedColorBox] = useState('');
    // console.log(selectValue);
    const handleChange = (event) => {
        // console.log('sadsadsdasad');
        setSelectValue([...event.target.value]);
        props.onChange && props.onChange(event);
    };

    const handleColorChange = (event) => {
        const selectedColor = event.target.style.backgroundColor;
        setSelectValue([selectedColor]);
        props.onChange && props.onChange(event);
        props.onClose && props.onClose();
        setDisplayColorBox(true);
        setSelectedColorBox(selectedColor);
        console.log('selectedColorBox', selectedColorBox);
    };

    useEffect(() => {
        if (props.defaultValueBrand && props.defaultValueBrand.length > 0) {
            const eventsFound = props.mapData.filter(event =>
                props.defaultValueBrand.includes(event._id)
            );
            const categoryIdArray = eventsFound.map(({ sBrandName }) => sBrandName);
            // console.log(categoryIdArray);
            setSelectValue(categoryIdArray);
        }
    }, [props.defaultValueBrand]);
    useEffect(() => {
        console.log('props.defaultValueSize ----->>>>', props.defaultValueSize);
        if (props.defaultValueSize !== undefined) {
            setSelectValue(props.defaultValueSize);
            // console.log('dad', props);
            // const eventsFound = props.mapData.find((data) => {
            //     console.log('ssss ----->>>>', data);
            //     return data.value.toLowerCase() === props.defaultValueSize.toLowerCase();
            // });

            // console.log('eventsFound ----->>>>', eventsFound);
            // eventsFound && setSelectValue(eventsFound.value);
        }
        // if (props.defaultValueSize && props.defaultValueSize.length > 0) {
        //     const eventsFound = props.mapData.filter(event =>
        //         props.defaultValueSize.includes(event._id)
        //     );
        //     const categoryIdArray = eventsFound.map(({ size }) => size);
        //     // console.log(categoryIdArray);
        //     setSelectValue(categoryIdArray);
        // }
    }, [props.defaultValueSize]);
    useEffect(() => {
        console.log(props.defaultValuecat);
        if (props.defaultValuecat && props.defaultValuecat.length > 0) {
            // props.mapData
            const eventsFound = props.mapData.filter(event =>
                props.defaultValuecat.includes(event.value)
            );
            const categoryIdArray = eventsFound.map(({ sCategoryName }) => sCategoryName);
            console.log(categoryIdArray);
            setSelectValue(categoryIdArray);
        }
    }, [props.defaultValuecat]);
    useEffect(() => {
        if (props.defaultValuepro && props.defaultValuepro.length > 0) {
            // props.mapData
            const eventsFound = props.mapData.filter(event =>
                props.defaultValuepro.includes(event._id)
            );
            const categoryIdArray = eventsFound.map(({ sName }) => sName);
            console.log(categoryIdArray);
            setSelectValue(categoryIdArray);
        }
    }, [props.defaultValuepro]);
    return (
        <div>
            <FormControl sx={{ m: 1, width: 300, ...props.sx }} className={props.withCheckBox === false ? 'customSelect ' + props.customClass : 'customSelect withCheckBox ' + props.customClass}>
                {/* <Dialog disableEnforceFocus > */}
                <Select
                    disabled={props.disabled}
                    value={selectValue}
                    displayEmpty
                    required={props.required}
                    multiple={props.multiple}
                    onChange={handleChange}
                    onClose={() => setAddBtn(false)}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                    renderValue={(selected) => {
                        console.log('selected ----->>>>', selected);
                        if (selected.length === 0 || props.emptyProductColor) {
                            if (props.withColorOptions === true) {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {
                                            props.colorforEdit
                                                ? <div style={{ backgroundColor: props.colorforEdit, opacity: 0.5, height: 18, width: 120 }}></div>
                                                : <>
                                                    <div style={{ height: 12, width: 12, borderRadius: 50, backgroundColor: '#C0C6CC', marginRight: 4 }}></div>
                                                    <div>{props.fieldPlaceHodler}</div>
                                                </>
                                        }
                                    </div>
                                );
                            } else {
                                return props.fieldPlaceHodler;
                            }
                        }
                        return (
                            props.withColorOptions
                                ? <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {displayColorBox && (
                                        <div
                                            className='selectedColorBox'
                                            style={{ backgroundColor: props.colorforEdit ? props.colorforEdit : selectedColorBox, height: 18, width: 120 }}
                                        />
                                    )}
                                    {/* {!props.multiple === true ? selected : selected.join(', ')} */}
                                </div>
                                : !props.multiple === true ? selected : selected.join(', ')
                        );
                    }}
                    className={selectValue.length === 0 ? 'placeholder' : 'selected'}
                >
                    {props.withCheckBox === false
                        ? props.mapData.map((value) => (
                            <MenuItem
                                tabIndex={addBtn ? false : 1}
                                key={value.id}
                                value={value.value}
                                className={addBtn && 'disabled'}
                            >
                                {value.title}
                                {/* {value.sStatus} */}
                            </MenuItem>
                        ))
                        : props.mapData.map((value) => (
                            <MenuItem disableTouchRipple key={value._id} value={value.title} className={addBtn && 'disabled'} tabIndex={addBtn ? false : 1}>
                                <Checkbox disableTouchRipple checked={selectValue.indexOf(value.id) > -1} />
                                <ListItemText primary={value.title} />
                            </MenuItem>
                        ))
                    }
                    {props.withColorOptions && (
                        <div className="selectedColorPalette">
                            {props.colorOptions.map((color, index) => (
                                <div
                                    key={index}
                                    className='selectColorBox'
                                    style={{ backgroundColor: color, height: 30, width: 54 }}
                                    onClick={handleColorChange}
                                ></div>
                            ))}
                        </div>
                    )}
                    {props.customButton === 'true' &&
                        <Box>
                            {!addBtn
                                ? <Box onClick={() => setAddBtn(true)} className="selectBtn">
                                    <Typography component="span" className='icon-plus-square2'></Typography>
                                    <Typography component="span" className="addbtn-text">{props.addText}</Typography>
                                </Box>
                                : <Box className='input_addbtn selectBtn'>
                                    <TextField id="outlined-basic" onChange={(e) => {
                                        // setGetValue(e.target.value);
                                        setAddValue(e.target.value);
                                    }} placeholder="Enter new category" name="Outlined" variant="outlined" />
                                    <button onClick={() => { setAddBtn(false); props.onAddClick && props.onAddClick(addValue); }} className='textfiled_addbtn' tabindex="0" type="button">{props.btnTxt}
                                    </button>
                                </Box>
                            }
                        </Box>
                    }
                    {props.children}
                </Select>
                {/* </Dialog> */}
                {props.requirePlaceHolder && <label className='requiredField'>{props.requirePlaceHolder}</label>}
                <Typography component="span" className="icon-chevron-down"></Typography>
            </FormControl>
        </div>
    );
};

export default CustomDropdown;
