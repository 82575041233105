import React, { useState, useEffect, useRef } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { multilanguage } from 'redux-multilanguage';
import { useNavigate } from 'react-router-dom';

import Header from '../../layout/Admin/Header/Header';
import SearchBox from '../../components/SearchBox/SearchBox';
import CustomDropdown from '../../components/CustomDropdown/CustomDropdown';

import instance from '../../api/axios';
import useWindowDimension from '../../hooks/useWindowDimension';

import './ViewOrder.scss';
import { MetaTags } from 'react-meta-tags';
import moment from 'moment';

const ViewOrder = (props) => {
    const dimensions = useWindowDimension();
    const navigate = useNavigate();
    const [selectDateRadio, setSelectDateRadio] = useState('');
    const [selectProduct, setSelectProduct] = useState('');
    const [selectBrand, setSelectBrand] = useState('');
    const [searchText, setSearchText] = useState({
        search: '',
        page: 1,
        limit: 12
    });
    const scrollBar = useRef();
    const [pageLoad, setPageLoad] = useState(true);
    const [viewOrderListing, setViewOrderListing] = useState([]);
    const [orderProduct, setOrderProduct] = useState([]);
    const [branddropdownData, setBranddropdownData] = useState([]);
    const [orderNotFoundMsg, setOrderNotFoundMsg] = useState(false);
    const SelectTimeType = [
        {
            id: '1',
            title: 'All Time',
            value: 'All Time',
            value1: 'All_Time'
        },
        {
            id: '2',
            title: 'Last Week',
            value: 'Last Week',
            value1: '7day'
        },
        {
            id: '3',
            title: 'Last Month',
            value: 'Last Month',
            value1: 'lastmonth'
        }
    ];
    const [headerHeight, setHeaderHeight] = useState(0);
    const [mainTitleHeight, setMainTitleHeight] = useState(0);
    const [searchHeight, setSearchHeight] = useState(0);
    const [filterHeight, setFilterHeight] = useState(0);
    useEffect(() => {
        setMainTitleHeight(document.getElementsByClassName('mainTitle')[0]?.offsetHeight);
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchTopSection')[0]?.offsetHeight);
        setFilterHeight(document.getElementsByClassName('filterSection')[0]?.offsetHeight);
    }, []);
    const handleResize = () => {
        setMainTitleHeight(document.getElementsByClassName('mainTitle')[0]?.offsetHeight);
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
        setSearchHeight(document.getElementsByClassName('searchTopSection')[0]?.offsetHeight);
        setFilterHeight(document.getElementsByClassName('filterSection')[0]?.offsetHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        orderListApi();
    }, [searchText, selectDateRadio, selectProduct, selectBrand]);

    useEffect(() => {
        productList();
        branddropdown();
    }, []);

    const productList = async () => {
        await instance.get('/products/allProductList')
            .then((res) => {
                const product = res.payload.product.map((item) => {
                    item.id = item._id;
                    item.title = item.sName;
                    item.value = item.sName;
                    return item;
                });
                setOrderProduct(product);
            }).catch((e) => {
                setTimeout(() => {
                    props.showToast({
                        message: e.response.data.message,
                        type: 'error'
                    });
                }, 1000);
            });
    };
    const branddropdown = async () => {
        await instance.get('/brands/allBrandList')
            .then((res) => {
                const brand = res.payload.brand.map((item) => {
                    item.id = item._id;
                    item.title = item.sBrandName;
                    item.value = item.sBrandName;
                    return item;
                });
                setBranddropdownData(brand);
            }).catch((e) => {
            });
    };

    const orderListApi = async () => {
        let params = '';
        if (searchText.page) {
            params = `?page=${searchText.page}`;
        }
        if (searchText.limit) {
            params += `&limit=${searchText.limit}`;
        }
        if (searchText.search) {
            params += `&search=${searchText.search}`;
        }
        if (selectDateRadio) {
            params += `&key=${selectDateRadio}`;
        }
        if (selectProduct) {
            params += `&product=${selectProduct}`;
        }
        if (selectBrand) {
            params += `&brand=${selectBrand}`;
        }
        await instance.get(`/order/viewOrderList${params}`)
            .then((res) => {
                console.log('res', res);
                if (res.payload.myOrder.length === 0) {
                    setViewOrderListing(res.payload.myOrder);
                    setPageLoad(false);
                }
                if (searchText.page === 1) {
                    setViewOrderListing(res.payload.myOrder);
                    console.log('page1OrderListing', viewOrderListing, res.payload.myOrder);
                } else {
                    setViewOrderListing(
                        viewOrderListing?.concat(res.payload.myOrder)
                    );
                }
                console.log('vieworderlisting', viewOrderListing);
            }).catch((e) => {
                console.log('view order catch', e);
            });
    };
    return (
        <>
            <MetaTags>
                <title>Orders | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="View client order history, download order summaries, and see which brands and products are the most popular." />
                <meta property="og:title" content="Orders | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="View client order history, download order summaries, and see which brands and products are the most popular. " />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Box className='viewOrderWrapper' style={{ marginTop: headerHeight }}>
                <Header />
                <Box className='viewOrderContainer'>
                    <Typography variant='h1' className='mainTitle'>View Orders</Typography>
                    <Box className='searchTopSection'>
                        <SearchBox
                            placeholder='User name, Order Id'
                            value={searchText.search}
                            setOrderNotFoundMsg={setOrderNotFoundMsg}
                            onSearch={(e) => {
                                if (e) {
                                    setOrderNotFoundMsg(true);
                                } else {
                                    setOrderNotFoundMsg(false);
                                }
                                console.log('searchhhhhhh', e);
                                setSearchText({ ...searchText, search: e, page: 1 });
                            }}
                            onClear={() => {
                                setSearchText({ ...searchText, search: '', page: 1 });
                            }}
                        />
                    </Box>
                    <Box className='filterSection'>
                        <Box className='filterDropRow'>
                            <Box className='filterDataTitle'>
                                <Typography className='title'>Filter</Typography>
                            </Box>
                            <Box className='filterDropDownColumn'>
                                <CustomDropdown
                                    withCheckBox={true}
                                    mapData={orderProduct}
                                    onChange={(e) => { setSelectProduct(e.target.value); console.log(e.target.value, '--'); if (e.target?.value?.length === 0) { setOrderNotFoundMsg(false); } else { setOrderNotFoundMsg(true); } }}
                                    fieldPlaceHodler='Select Products'
                                    multiple={true}
                                />
                            </Box>
                            <Box className='filterDropDownColumn'>
                                <CustomDropdown
                                    withCheckBox={true}
                                    mapData={branddropdownData}
                                    onChange = {(e) => {
                                        setSelectBrand(e.target.value);
                                        console.log(e.target.value);
                                        console.log('sdfsdf', e.target.value.length, e.target.value.length > 0);
                                        if (e.target?.value?.length > 0) {
                                            setOrderNotFoundMsg(true);
                                        } else {
                                            setOrderNotFoundMsg(false);
                                        }
                                    }}
                                    fieldPlaceHodler='Select Brands'
                                    multiple={true}
                                />
                            </Box>
                            <Box className='filterDropDownColumn'>
                                <CustomDropdown
                                    withCheckBox={false}
                                    multiple={false}
                                    mapData={SelectTimeType}
                                    onChange = {(e) => {
                                        if (e.target?.value) {
                                            setOrderNotFoundMsg(true);
                                        } else {
                                            setOrderNotFoundMsg(false);
                                        }
                                        console.log(e, 'e');
                                        const data = SelectTimeType.filter((a) => a.value === e.target.value);
                                        setSelectDateRadio(data[0].value1);
                                    }}
                                    fieldPlaceHodler='All Time'
                                />
                                {console.log('selectDateRadio', selectDateRadio)}
                            </Box>
                        </Box>
                    </Box>
                    <Scrollbars className='viewOrderListing'
                        onScroll={() => {
                            if (Math.round(scrollBar?.current.getScrollTop() + scrollBar?.current.getClientHeight()) === scrollBar?.current.getScrollHeight()) {
                                if (pageLoad) {
                                    setSearchText({ ...searchText, page: searchText.page + 1 });
                                }
                            }
                        }}ref={scrollBar}
                        style={{ height: (dimensions.height - headerHeight - mainTitleHeight - searchHeight - filterHeight - 60) }}>
                        <Table stickyHeader className='table'>
                            <TableHead className='tableHeader'>
                                <TableRow className='tableHeaderRow'>
                                    <TableCell className='tableHeaderColumn'>Order ID</TableCell>
                                    <TableCell className='tableHeaderColumn'>Order Date</TableCell>
                                    <TableCell className='tableHeaderColumn'>Ordered By</TableCell>
                                    <TableCell className='tableHeaderColumn'>Total Price</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className='tableBody'>
                                {console.log('%%', orderNotFoundMsg)}
                                {console.log('viewOrderListing ----->>>>', viewOrderListing)}
                                {viewOrderListing.length > 0
                                    ? viewOrderListing.map((item, index) => {
                                        console.log('item...', item);
                                        // let total_price = 0;
                                        // item.products.forEach((data) => {
                                        //     total_price += (data.sPrice * data.sQuantity);
                                        // });
                                        console.log('orderitem', viewOrderListing);
                                        // const today = new Date(item?.orderPlacedDate);
                                        console.log('->', item?.orderPlacedDate);
                                        return (
                                            <TableRow className='tableBodyRow' key={index} onClick={() => navigate('/orders/orderdetails', { state: item })}>
                                                <TableCell className='tableBodyColumn'> {'#' + item?.orderDetail?.sOrderNo}</TableCell>
                                                <TableCell className='tableBodyColumn'>{item?.orderDetail?.orderPlacedDate !== undefined ? moment(item?.orderDetail?.orderPlacedDate).format('DD MMM YYYY') : '-' }</TableCell>
                                                <TableCell className='tableBodyColumn'>
                                                    <Box className='orderByName'>
                                                        <Typography variant='body2' className='shortName'>{item?.userDetail?.sFirstName?.charAt(0) + item?.userDetail?.sLastName?.charAt(0)}</Typography>
                                                        <Typography variant='body2' className='fullName'>{item?.userDetail?.sFirstName + ' ' + item?.userDetail?.sLastName}</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell className='tableBodyColumn'>{item?.orderDetail?.sGrandAmount ? '£' + (item.orderDetail.sGrandAmount).toFixed(2) : '-'}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                    : orderNotFoundMsg
                                        ? <TableRow className='tableBodyRow' >
                                            <TableCell colspan="4" className='tableBodyColumn emptyCell' style={{ textAlign: 'center' }}>No Data Found</TableCell>
                                        </TableRow>
                                        : <TableRow className='tableBodyRow' >
                                            <TableCell colspan="4" className='tableBodyColumn emptyCell' style={{ textAlign: 'center' }}>No orders received yet</TableCell>
                                        </TableRow>
                                }
                                {/* {viewOrderListing.map((item, index) => {
                                const today = new Date(item.createdAt);
                                return (
                                    <TableRow className='tableBodyRow' key={index} onClick={() => navigate('/orderdetails', { state: item })}>
                                        <TableCell className='tableBodyColumn'> {'#' + item.sOrderNo}</TableCell>
                                        <TableCell className='tableBodyColumn'>{today.toGMTString().substring(5, 16)}</TableCell>
                                        <TableCell className='tableBodyColumn'>
                                            <Box className='orderByName'>
                                                <Typography variant='body2' className='shortName'>{item.userData.sFirstName?.charAt(0) + item.userData.sLastName?.charAt(0)}</Typography>
                                                <Typography variant='body2' className='fullName'>{item.userData.sFirstName + ' ' + item.userData.sLastName}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell className='tableBodyColumn'>{item.sDeliveryPrice}</TableCell>
                                    </TableRow>
                                );
                            })} */}
                            </TableBody>
                        </Table>
                    </Scrollbars>
                </Box>
            </Box>
        </>
    );
};

export default multilanguage(ViewOrder);
