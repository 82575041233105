import React from 'react';
import Dropzone from 'react-dropzone';

const CustomFileUpload = (props) => {
    const { multiple, onDrop, setRef, accept } = props;
    console.log(multiple);
    return (
        <div className={`dropzoneCustom ${props.error ? 'error' : ''}`}>
            <Dropzone accept={accept} multiple={false} onDrop={(acceptImage1, acceptImage2, acceptImage3, acceptImage4) => { onDrop && onDrop(acceptImage1, acceptImage2, acceptImage3, acceptImage4); }} ref={setRef}>
                {({ getRootProps, getInputProps }) => (
                    <section>
                        <div {...getRootProps()} className="txtDropzone">
                            <input {...getInputProps()} />
                            {props.children}
                        </div>
                    </section>
                )}
            </Dropzone>
        </div>
    );
};
export default CustomFileUpload;
