import { React } from 'react';
import { multilanguage } from 'redux-multilanguage';
import { Modal } from '@material-ui/core';
import { connect } from 'react-redux';

import { showToast } from '../../redux/actions/toastAction';

import './AddModal.scss';

const AddModal = (props) => {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={props.className}
            >
                {props.children}
            </Modal>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};
const mapDispatchToProps = {
    showToast
};
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(AddModal));
