import * as React from 'react';
import { Box, Switch } from '@mui/material';

import './SwitchToggler.scss';

export default function ControlledSwitches (props) {
    return (
        <Box component='span' className='switchTogglerWrapper'>
            <Switch
                checked={props.checked}
                onChange={props.handleChangeEvent}
                inputProps={{ 'aria-label': 'controlled' }}
                onClick={props.onClick}
            />
        </Box>
    );
}
