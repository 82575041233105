import React from 'react';
import { Box } from '@mui/material';

import './CustomLabel.scss';
const CustomLabel = (props) => {
    return (
        <Box className='customLabelTextStyle'>
            {props.label}
        </Box>
    );
};
export default CustomLabel;
