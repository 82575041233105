/* eslint-disable  */
import {
  Box,
  Button,
  CardContent,
  Container,
  Tab,
  Typography,
  Card,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useEffect, useState, useRef } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { multilanguage } from "redux-multilanguage";
import ReactDragListView from "react-drag-listview";
import Scrollbars from "react-custom-scrollbars-2";
import { Controller } from "react-hook-form";
import { connect } from "react-redux";
import AWS from "aws-sdk";

import Loader from "../../components/Loader/Loader";
import ModalBox from "../../components/ModalBox/ModalBox";
import AddModal from "../../components/AddModal/AddModal";
import HookForm from "../../components/HookForm/HookForm";
import SearchBox from "../../components/SearchBox/SearchBox";
import VideoCard from "../../components/VideoCard/VideoCard";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomArticle from "../../components/CustomArticle/CustomArticle";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomTextField from "../../components/CustomTextField/CustomTextFeild";
import CustomFileUpload from "../../components/CustomFileUpload/CustomFileUpload";
import EditorStyledToolbar from "../../components/CustomTextEditor/CustomTextEditor";
import CustomLoadingButton from "../../components/CustomLoadingButton/CustomLoadingButton";

import instance from "../../api/axios";
import Constant from "../../utils/constant";
import { setUser } from "../../redux/actions/authAction";
import { showToast } from "../../redux/actions/toastAction";
import useWindowDimension from "../../hooks/useWindowDimension";
import "./PatientsEducation.scss";
import { ContentState, convertToRaw, convertFromRaw } from "draft-js";
import { MetaTags } from "react-meta-tags";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const PatientsEducation = (props) => {
  const { strings, user } = props;
  const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
  const dimensions = useWindowDimension();
  const [editorCustomClass, setEditorCustomClass] = useState("");
  const [rawData, setRawData] = useState("");

  console.log("rawData000", rawData.blocks?.[0].text);
  console.log("rawData001", rawData.blocks?.[0].text);

  const [editorText, setEditorText] = useState("");

  const [value, setValue] = useState(localStorage.getItem("tab") || "1");
  const [deleteArticlePopup, setDeleteArticlePopup] = useState(false);
  const [disableArticlePopup, setDisableArticlePopup] = useState(false);
  const [addArticlePopup, setAddArticlePopup] = useState({
    type: false,
    value: "",
  });
  const [pinArticlePopup, setPinArticlePopup] = useState({
    type: false,
    value: "",
  });
  const [productImg1, setProductImg1] = useState("");
  const [busy, setBusy] = useState(false);
  const [addArticle, setAddArticle] = useState();
  const [videoModal, setVideoModal] = useState(false);
  const [addVideo, setAddVideo] = useState(false);
  const [addVideoModal, setAddVideoModal] = useState({
    type: false,
    value: "",
  });
  const [deleteVideoPopup, setDeleteVideoPopup] = useState(false);
  const [disableVideoPopup, setDisableVideoPopup] = useState(false);
  const [addCategory, setAddCategory] = useState({ type: false, value: "" });
  const [newCategory, setNewCategory] = useState();
  const [deleteCategoryPopup, setDeleteCategoryPopup] = useState(false);
  const [disableCategoryPopup, setDisableCategoryPopup] = useState(false);
  const [articleData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [tabCategoryData, setTabCategoryData] = useState([]);
  const [categorydropdownData, setCategorydropdownData] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [videoId, setVideoId] = useState(null);
  const [articleId, setArticleId] = useState(null);
  const [selectedArtical, setSelectedArtical] = useState([]);
  const [pinLoading, setPinLoading] = useState(false);
  const [unpinLoading, setUnpinLoading] = useState(false);
  const [articleObject, setArticleObject] = useState({
    search: "",
    page: 1,
    limit: 18,
  });
  const [videoObject, setVideoObject] = useState({
    search: "",
    page: 1,
    limit: 16,
  });
  const [categoryObject, setCategoryObject] = useState({
    search: "",
    page: 1,
    limit: 30,
  });
  const [articlePinObject, setArticlePinObject] = useState({
    search: "",
    page: 1,
    limit: 18,
  });
  const scrollBar = useRef();
  const [videoProductData, setVideoProductData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showImage, setShowImage] = useState();
  const [pageLoad, setPageLoad] = useState(true);
  const [activeTab, setActiveTab] = useState("");
  const [valueUnpinData, setValueUnpinData] = useState([]);
  const [checkArticleDescription, setCheckArticleDescription] = useState("");
  const [checkArticleImgField, setCheckArticleImgField] = useState();
  const [checkArticleCatField, setCheckArticleCatField] = useState([]);
  const [searchpopupModel, SetsearchpopupModel] = useState(false);
  const [allArticle,setAllArticle]=useState([])
  if (addArticle !== undefined) {
    console.log(
      addArticle.getValues(),
      "addArticle",
      addArticle.formState.defaultValues,
      addArticle.formState.dirtyFields
    );
  }
  useEffect(() => {
    console.log("articleId::::", articleId);
  }, [articleId]);

  /* eslint-disable */

  const handleArticleCategory = (event, newValue) => {
    setCategory(event.target.value);
  };
  const [productType, setProductType] = useState("Select product (optional)");
  const handleProductDropdown = (event, newValue) => {
    setProductType(event.target.value);
  };
  const [articleDataList, setArticleDataList] = useState([]);
  const [articleDataListPin, setArticleDataListPin] = useState([]);
  const [articleDataListUnpin, setArticleDataListUnpin] = useState([]);
  const onUpload1 = (acceptImage1) => {
    if (acceptImage1.length > 0) {
      setShowImage("");
      const file = acceptImage1[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");

      setLoader(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key:
            user?.id + "/" + Date.now().toString() + "." + arr[arr.length - 1],
          Body: file,
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          setLoader(false);

          setShowImage(image?.key);
        },
        function (err) {}
      );
    } else {
      setLoader(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      setProductImg1(event.target.result);
    };
    reader.readAsDataURL(acceptImage1[0]);
  };

  const onAddArticleFormSubmit = async (value) => {
    if (articleId === null || articleId === undefined) {
      setShowImage("");
      setProductImg1(null);
      let eventsFound = tabCategoryData.filter((event) =>
        selectedArtical?.includes(event.sCategoryName)
      );
      let categoryIdArray = eventsFound.map(({ _id }) => _id);
      let productId = videoProductData.find(
        (item) => item.sName == value.productname
      )?._id;
      const params = {
        sCategoryId: categoryIdArray,
        oProductId: productId,
        sArticleName: value.article_title,
        sArticleImage: showImage,
        // sDescription: editorText,
        sDescription: rawData.blocks?.map((i)=>i.text).join("\n"),
        sPin: "false",
      };
      await instance
        .post("/patients/article/", params)
        .then((res) => {
          setBusy(true);
          setTimeout(() => {
            props.showToast({
              message:
                addArticlePopup.value === "Add"
                  ? strings.ARTICLE_ADDED
                  : strings.ARTICLE_UPDATED,
              type: "success",
            });
            setBusy(false);
          }, 1000);
          articleListApi();
          getAllArticasAPI();
        })
        .catch((e) => {
          console.log(`e ----->>>>`, e);
          setTimeout(() => {
            props.showToast({
              message: e.response.data.error,
              type: "error",
            });
          }, 1000);
        });
    } else {
      let eventsFound = tabCategoryData.filter((event) =>
        selectedArtical.includes(event.sCategoryName)
      );
      let categoryIdArray = eventsFound.map(({ _id }) => _id);
      let productId = videoProductData.find(
        (item) => item.sName == value.productname
      )?._id;
      const params = {
        sCategoryId: categoryIdArray,
        oProductId: productId,
        sArticleName: value.article_title,
        sArticleImage: showImage,
        // sDescription: 'Practicing good dental care from infancy to adulthood can help a person keep their teeth and gums healthy. Brushing and flossing daily, not smoking, eating a ... ',
        // sDescription: editorText,
        sDescription: rawData.blocks?.map((i)=>i.text).join("\n"),
      };
      await instance
        .put(`/patients/article/updateArticle/?id=${articleId?._id}`, params)
        .then((res) => {
          setBusy(true);
          setArticleId(null);
          setProductImg1(null);
          setShowImage("");
          setTimeout(() => {
            props.showToast({
              message:
                addArticlePopup.value === "Add"
                  ? strings.ARTICLE_ADDED
                  : strings.ARTICLE_UPDATED,
              type: "success",
            });
            setBusy(false);
          }, 1000);
          articleListApi();
          getAllArticasAPI();
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    }
  };
  const onAddVideoFormSubmit = async (value) => {
    if (videoId === null || videoId === undefined) {
      setBusy(true);
      let categoryId = tabCategoryData.find(
        (item) => item.sCategoryName == value.Video_category
      )?._id;
      let productId = videoProductData.find(
        (item) => item.sName == value.videoproduct
      )?._id;

      const params = {
        sCategoryId: categoryId,
        oProductId: productId,
        sVideoName: value.Videotitle,
        sVideoLink: value.Videolink,
      };
      await instance
        .post("/patients/patientVideo/", params)
        .then((res) => {
          setTimeout(() => {
            props.showToast({
              message:
                addVideoModal.value === "Add"
                  ? strings.VIDEO_ADDED
                  : strings.VIDEO_UPDATED,
              type: "success",
            });
            setBusy(false);
          }, 1000);
          videoListApi();
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    } else {
      let categoryId = tabCategoryData.find(
        (item) => item.sCategoryName == value.Video_category
      )?._id;
      let productId = videoProductData.find(
        (item) => item.sName == value.videoproduct
      )?._id;
      const params = {
        sCategoryId: categoryId,
        oProductId: productId,
        sVideoName: value.Videotitle,
        sVideoLink: value.Videolink,
      };
      await instance
        .put(
          `/patients/patientVideo/updatePatientVideo?id=${videoId._id}`,
          params
        )
        .then((res) => {
          setVideoId(null);
          setTimeout(() => {
            props.showToast({
              message:
                addVideoModal.value === "Add"
                  ? strings.VIDEO_ADDED
                  : strings.VIDEO_UPDATED,
              type: "success",
            });
            videoListApi();
            setVideoData(
              videoData.map((data) => {
                if (data._id === res.payload._id) {
                  /* eslint-disable */
                  data["sVideoName"] = res.payload.sVideoName;
                  // data['CategoryName'] = res.payload.CategoryName;
                  data["sStatus"] = res.payload.sStatus;
                }
                return data;
              })
            );
            setBusy(false);
          }, 1000);
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    }
  };
  const onAddCategoryFormSubmit = async (value) => {
    if (categoryValue === null || categoryValue === undefined) {
      setBusy(true);
      const params = {
        sCategoryName: value.Category,
      };
      await instance
        .post("/patients/category/", params)
        .then((res) => {
          setTimeout(() => {
            props.showToast({
              message:
                addCategory.value === "Add"
                  ? strings.CATEGORY_ADDED
                  : strings.CATEGORY_UPDATED,
              type: "success",
            });
            categoryListApi();
            setBusy(false);
          }, 1000);
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    } else {
      const params = {
        sCategoryName: value.Category,
      };
      await instance
        .put(
          `/patients/category/updateCategory?id=${categoryValue._id}`,
          params
        )
        .then((res) => {
          setCategoryValue(null);
          setTimeout(() => {
            props.showToast({
              message:
                addCategory.value === "Add"
                  ? strings.CATEGORY_ADDED
                  : strings.CATEGORY_UPDATED,
              type: "success",
            });
            setTabCategoryData(
              tabCategoryData.map((data) => {
                if (data._id === res.payload._id) {
                  data.sCategoryName = res.payload.sCategoryName;
                  data.sStatus = res.payload.sStatus;
                }
                return data;
              })
            );
            setBusy(false);
          }, 1000);
        })
        .catch((e) => {
          setTimeout(() => {
            props.showToast({
              message: e.response.data.message,
              type: "error",
            });
          }, 1000);
        });
    }
  };
  const addCategoryApi = async (addData) => {
    const params = {
      sCategoryName: addData,
    };
    await instance
      .post("/patients/category/", params)
      .then((res) => {
        categoryListApi();
        setTimeout(() => {
          props.showToast({
            message: res.response.data.message,
            type: "error",
          });
        }, 1000);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  const handleMainTabs = (event, newValue) => {
    setValue(newValue);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const addarticle = {
    photo: {
      value: "photo",
      validation: {
        required: {
          value: true,
          message: strings.ARTICLE_TITLE_REQUIRED,
        },
      },
    },
    editor: {
      value: "editor",
      validation: {
        required: {
          value: true,
          message: strings.ARTICLE_TITLE_REQUIRED,
        },
        validate: (val) => {
          if (val.trim() === "") {
            return false;
          } else {
            const valx = JSON.parse(val);
            return valx.blocks.some((i) => i.text.trim() !== "");
          }
        },
      },
    },
    articleTitle: {
      value: "article_title",
      validate: {
        required: {
          value: true,
          message: strings.ARTICLE_TITLE_REQUIRED,
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: strings.THIS_FIELD_CONTAIN_ALPHANUM,
        },
      },
    },
    productname: {
      name: "productname",
      value: "productname",
      validate: {
        required: {
          value: false,
        },
      },
    },
    articledescription: {
      name: "articledesc",
      value: "article_description",
      validate: {
        required: {
          value: true,
          message: strings.ARTICLE_TITLE_REQUIRED,
        },
      },
    },
    articlecategory: {
      name: "articlecategory",
      value: "article_category",

      validates: {
        required: {
          value: false,
          message: strings.ARTICLE_TITLE_REQUIRED,
        },
        validate: (val) => {
          console.log(val, "fieldArrayValues");
          return val.length > 0;
          // return true;
        },
      },
    },
    // articledesc: {
    //     name: 'articledesc',
    //     value: 'articledesc',
    //     validate: {
    //         required: {
    //             value: true
    //         }
    //     }
    // },
    productbrand: {
      value: "productbrand",
      validate: {
        required: {
          value: true,
          message: strings.PRODUCT_DESCRIPTION_REQUIRED,
        },
      },
    },
  };
  const addvideodata = {
    videoTitle: {
      value: "Videotitle",
      validate: {
        required: {
          value: true,
          message: strings.VIDEO_TITLE_REQUIRED,
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: strings.THIS_FIELD_CONTAIN_ALPHANUM,
        },
      },
    },
    videoLink: {
      value: "Videolink",
      validate: {
        required: {
          value: true,
          message: strings.VIDEO_LINK_REQUIRED,
        },
        pattern: {
          value: Constant.REGEX.VIDEOURL,
          message: strings.VALID_URL,
        },
      },
    },
    videocategory: {
      name: "Video_category",
      validate: {
        required: {
          value: true,
        },
      },
    },
    videoproduct: {
      name: "videoproduct",
      validate: {
        required: {
          value: false,
        },
      },
    },
  };
  const videoCategoryText = {
    category: {
      value: "Category",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: strings.THIS_FIELD_CONTAIN_ALPHANUM,
        },
      },
    },
  };
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      if (toIndex < 3 && toIndex > -1) {
        console.log("index--", fromIndex, toIndex);
        const dataFrom = articleDataListPin[fromIndex];
        const dataTo = articleDataListPin[toIndex];
        articleDataListPin[toIndex] = dataFrom;
        articleDataListPin[fromIndex] = dataTo;
        const paramsData = {
          article1: articleDataListPin[fromIndex],
          article2: articleDataListPin[toIndex],
          position2: toIndex + 1,
          position1: fromIndex + 1,
        };
        instance
          .put("/patients/changeArticlePinOrder", paramsData)
          .then((res) => {
            console.log("res-->", res);
          })
          .catch((error) => {
            console.log("error", error);
          });
        setArticleDataListPin([...articleDataListPin]);
      } else {
        setArticleDataListPin([...articleDataListPin]);
      }
    },
    nodeSelector: ".cardWrap",
    handleSelector: ".icon-drag",
  };
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mainTitleHeight, setMainTitleHeight] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);
  const [searchBarRowHeight, setSearchBarRowHeight] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const [deviceWidth, setDeviceWidth] = useState(0);

  useEffect(() => {
    setActiveTab(localStorage.getItem("tab") || "1");
  }, []);

  useEffect(() => {
    if (activeTab === "1") {
      articleListApi();
      getAllArticasAPI();
    } else if (activeTab == "2") {
      productList();
      videoListApi();
    } else {
      categoryListApi();
    }
  }, [activeTab]);

  useEffect(() => {
    articleListApi();
    getAllArticasAPI();
  }, [articleObject]);

  useEffect(() => {
    articleListApi();
    getAllArticasAPI();
  }, [articlePinObject]);

  useEffect(() => {
    videoListApi();
    productList();
  }, [videoObject]);

  useEffect(() => {
    categoryListApi();
  }, [categoryObject]);

  const getAllArticasAPI = async () => {
  
    await instance
      .get(`/patients/allArticle`)
      .then((res) => {
        if (res.payload.product.length === 0) {
          setPageLoad(false);
        }
        setAllArticle([...new Set(res.payload.product)])
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response?.data?.message,
            type: "error",
          });
        }, 1000);
      });
  };


  const articleListApi = async () => {
    let params = "";
    if (articleObject.page) {
      params = `?page=${articleObject.page}`;
    }
    if (articleObject.search) {
      params += `&search=${articleObject.search}`;
    }
    if (articleObject.limit) {
      params += `&limit=${articleObject.limit}`;
    }
    if (articlePinObject.search) {
      params += `&search=${articlePinObject.search}`;
    }

    await instance
      .get(`/patients/allArticle${params}`)
      .then((res) => {
        console.log("in response");
        if (res.payload.product.length === 0) {
          // setArticleDataList([]);
          setPageLoad(false);
        }

        if (articleObject.page === 1) {
          console.log("first page");
          const data = res.payload.product.filter((item) => item.sPin === true);
          console.log("--data", data);
          const sortedData = data.sort((a, b) => a.pinOrder - b.pinOrder);
          console.log("sortedData", sortedData);

          setArticleDataList(res.payload.product);
          console.log(searchpopupModel, "searchpopupModel!!!!!");
          if (!searchpopupModel) {
            setArticleDataListPin(sortedData);
            // setArticleDataListPin(res.payload.product.filter(item => item.sPin === true));
            setArticleDataListUnpin(
              res.payload.product.filter((item) => item.sPin === false)
            );
          }
        } else {
          console.log("second page");
          setArticleDataList(articleDataList?.concat(res.payload.product));
          setArticleDataListPin(
            articleDataListPin?.concat(
              res.payload.product.filter((item) => item.sPin === true)
            )
          );
          setArticleDataListUnpin(
            articleDataListUnpin?.concat(
              res.payload.product.filter((item) => item.sPin === false)
            )
          );
        }
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response?.data?.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const articleDeleteApi = async () => {
    await instance
      .delete(`/patients/article/${articleId._id}`)
      .then((res) => {
        articleListApi();
        getAllArticasAPI();
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const statusArticleApi = async (value) => {
    const params = {
      id: articleId._id,
      sStatus: value,
    };
    await instance
      .post("/patients/article/updateArticle/", params)
      .then((res) => {
        articleListApi();
        getAllArticasAPI();
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const pinArticleApi = async () => {
    setPinLoading(true);
    const params = {
      pinId: localStorage.getItem("PIN ID"),
      unPinId: localStorage.getItem("UN PIN ID"),
    };
    await instance
      .put("/patients/updatePinStatus", params)
      .then((res) => {
        setTimeout(() => {
          articleListApi();
          getAllArticasAPI();
        }, 500);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.data.message,
            type: "error",
          });
        }, 1000);
      });
    setTimeout(() => {
      setPinLoading(false);
    }, 1000);
  };
  const UnpinArticleApi = async () => {
    setUnpinLoading(true);
    setPinLoading(true);
    const params = {
      pinId: localStorage.getItem("PIN ID"),
      unPinId: localStorage.getItem("UN PIN ID"),
    };
    await instance
      .put("/patients/updateunPinStatus", params)
      .then((res) => {
        setTimeout(() => {
          articleListApi();
          getAllArticasAPI();
        }, 500);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.data.message,
            type: "error",
          });
        }, 1000);
      });
    setTimeout(() => {
      setUnpinLoading(false);
      setPinLoading(false);
    }, 1000);
  };
  const productList = async () => {
    await instance
      .get("/products/allProductList")
      .then((res) => {
        strings.CATEGORY_ADDED;
        const product = res.payload.product.map((item) => {
          item.id = item._id;
          item.title = item.sName;
          item.value = item.sName;
          return item;
        });
        product.unshift({
          id: "",
          title: "Select",
          value: "",
        });
        console.log("product--", product);
        setVideoProductData(product);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const videoListApi = async () => {
    let params = "";
    if (videoObject.page) {
      params = `?page=${videoObject.page}`;
    }
    if (videoObject.search) {
      params += `&search=${videoObject.search}`;
    }
    if (videoObject.limit) {
      params += `&limit=${videoObject.limit}`;
    }
    await instance
      .get(`/patients/allVideo${params}`)
      .then((res) => {
        if (res.payload.patientVideo.length === 0) {
          setPageLoad(false);
        }

        if (videoObject.page === 1) {
          setVideoData(res.payload.patientVideo);
        } else {
          setVideoData(videoData?.concat(res.payload?.patientVideo));
        }
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  const videoDelteApi = async () => {
    await instance
      .delete(`/patients/patientVideo/${videoId._id}`)
      .then((res) => {
        setVideoData(videoData.filter((item) => item._id !== videoId._id));
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const statusVideoApi = async (value) => {
    const params = {
      id: videoId._id,
      sStatus: value,
    };
    await instance
      .post("/patients/patientVideo/updatePatientVideo/", params)
      .then((res) => {
        videoListApi();
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const categoryListApi = async () => {
    let params = "";
    if (categoryObject.page) {
      params = `?page=${categoryObject.page}`;
    }
    if (categoryObject.search) {
      params += `&search=${categoryObject.search}`;
    }
    if (categoryObject.limit) {
      params += `&limit=${categoryObject.limit}`;
    }
    await instance
      .get(`/patients/allCategory${params}`)
      .then((res) => {
        if (res.payload.category.length === 0) {
          // setTabCategoryData([]);
          setPageLoad(false);
        }

        if (categoryObject.page === 1) {
          setTabCategoryData(res.payload.category);
        } else {
          setTabCategoryData(tabCategoryData?.concat(res.payload.category));
        }
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  useEffect(() => {
    categorydropdown();
  }, [tabCategoryData]);
  const categorydropdown = async () => {
    await instance
      .get("/patients/allCategoryList")
      .then((res) => {
        const category = res.payload.category.map((item) => {
          item.id = item._id;
          item.title = item.sCategoryName;
          item.value = item.sCategoryName;
          return item;
        });
        setCategorydropdownData(category);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  const categoryDelteApi = async () => {
    await instance
      .delete(`/patients/category/${categoryValue._id}`)
      .then((res) => {
        setTabCategoryData(
          tabCategoryData.filter((item) => item._id !== categoryValue._id)
        );
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };
  const statusCategoryApi = async (value) => {
    const params = {
      id: categoryValue._id,
      sStatus: value,
    };
    await instance
      .post("/patients/category/updateCategory/", params)
      .then((res) => {
        categoryListApi();
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: e.response.data.message,
            type: "error",
          });
        }, 1000);
      });
  };

  useEffect(() => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setTabHeight(document.getElementsByClassName("tabWrap")[0]?.offsetHeight);
    setSearchBarRowHeight(
      document.getElementsByClassName("topbarWrap")[0]?.offsetHeight
    );
    setDeviceWidth(document.getElementsByClassName("App")[0]?.offsetWidth);
    setArticleDataList(articleData);
  }, []);

  useEffect(() => {
    const _contentState = ContentState.createFromText(editorText);
    // console.log("_contentState", _contentState);
    const raw = convertToRaw(_contentState);
    console.log("raw::-->", raw);
    setRawData(raw);
  }, [editorText]);

  return (
    <>
      <MetaTags>
        <title>
          Education & Content | Mouthmap - Your Route To A Healthy Mouth
        </title>
        <meta
          name="description"
          content="Manage app content from within the Admin Portal: post, view, and update articles, categories, images, and more."
        />
        <meta
          property="og:title"
          content=" Education & Content | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content="Manage app content from within the Admin Portal: post, view, and update articles, categories, images, and more."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>
      <Box
        className="manageProducts patientsEducationPage commonWrapper"
        style={{
          marginTop: headerHeight,
          height: dimensions.height - headerHeight,
        }}
      >
        <Box>
          <Container>
            <Typography variant="h1" className="mainTitle">
              {strings.PATIENTS_EDUCATION}
            </Typography>
          </Container>
        </Box>
        <Box>
          <Container>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box
                  className="tabWrap"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <TabList onChange={handleMainTabs} aria-label="Product tabs">
                    <Tab
                      value="1"
                      onClick={() => {
                        localStorage.setItem("tab", "1");
                        productList();
                      }}
                      label={strings.ARTICLE}
                    />
                    <Tab
                      value="2"
                      onClick={() => {
                        localStorage.setItem("tab", "2");
                      }}
                      label={strings.VIDEO}
                    />
                    <Tab
                      value="3"
                      onClick={() => {
                        localStorage.setItem("tab", "3");
                      }}
                      label={strings.MANAGE_CATEGORIES}
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Box className="topbarWrap" display="flex">
                    <SearchBox
                      placeholder={strings.ARTICLE_TITLE}
                      value={articleObject.search}
                      onSearch={(e) => {
                        console.log(e, "e");
                        setArticleObject({
                          ...articleObject,
                          search: e,
                          page: 1,
                        });
                      }}
                      onClear={() => {
                        setArticleObject({
                          ...articleObject,
                          search: "",
                          page: 1,
                        });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setAddArticlePopup({ type: true, value: "Add" });
                        setArticleId(null);
                        setShowImage("");
                        setProductImg1(null);
                        setSelectedArtical([]);
                        setEditorText("");
                        setRawData("");
                      }}
                    >
                      <Typography
                        component="span"
                        className="icon-plus-11"
                      ></Typography>
                      {strings.ARTICLE}
                    </Button>
                  </Box>
                  {articleDataListPin.length > 0 ||
                  articleDataListUnpin.length > 0 ? (
                    <>
                      <Box className="list1">
                        <ReactDragListView
                          lineClassName="cardListRow"
                          {...dragProps}
                        >
                          <Box className="articleList cardList">
                            {articleDataListPin.map((value, index) => {
                              console.log(value);
                              return (
                                <CustomArticle
                                  pinLoading={pinLoading}
                                  key={index}
                                  pinOrder={value.pinOrder}
                                  category={
                                    value.CategoryName[0]?.sCategoryName
                                  }
                                  title={value.sArticleName}
                                  status={value.sStatus}
                                  subscription={value.subscription}
                                  price={value.price}
                                  pin={value.sPin}
                                  checked={value.sStatus !== "Inactive"}
                                  deletePopup={() => {
                                    setDeleteArticlePopup(true);
                                    setArticleId(value);
                                  }}
                                  disablePopup={() => {
                                    setDisableArticlePopup(true);
                                    setArticleId(value);
                                  }}
                                  articlePin={() => {
                                    setArticleId(value);
                                    setPinArticlePopup({
                                      type: true,
                                      value: true,
                                    });
                                  }}
                                  articleUnpin={() => {
                                    localStorage.setItem(
                                      "UN PIN ID",
                                      value._id
                                    );
                                    if (allArticle.filter((i)=>i.sPin===true).length >= 3) {
                                    setPinArticlePopup({
                                      type: true,
                                      value: false,
                                    });
                                    } else {
                                      setArticleId(value);
                                    }
                                  }}
                                  editPopup={() => {
                                    console.log("value", value);
                                    let temp = [];
                                    value.CategoryName.forEach((element) => {
                                      temp.push(element.sCategoryName);
                                    });
                                    setSelectedArtical(temp);
                                    setCheckArticleCatField(temp);
                                    setEditorText(
                                      value?.sDescription
                                        .replace(/&lt;/g, "<")
                                        .replace(/&gt;/g, ">")
                                        .replace(/&amp;/g, "&")
                                        .replace(/&nbsp;/g, " ")
                                        .replace(/<p[^>]*>/g, "")
                                        .replace(/<\/p>/g, "")
                                    );
                                    // setEditorText(value?.sDescription)
                                    setEditorCustomClass("changedText");
                                    setArticleId(value);
                                    setShowImage(value?.sArticleImage);
                                    setCheckArticleImgField(
                                      value?.sArticleImage
                                    );
                                    // setCheckArticleDescription(value?.sDescription);
                                    setAddArticlePopup({
                                      type: true,
                                      value: "Edit",
                                    });
                                    console.log(
                                      "checkfield",
                                      showImage,
                                      checkArticleImgField
                                    );
                                    // addArticle?.setValue('article_title', value?.sArticleName, { shouldDirty: false });
                                  }}
                                />
                              );
                            })}
                          </Box>
                        </ReactDragListView>
                      </Box>
                      <Scrollbars
                        // onScroll={() => {
                        //     if (Math.round(scrollBar?.current.getScrollTop() + scrollBar?.current.getClientHeight()) === scrollBar?.current.getScrollHeight()) {
                        //         if (pageLoad) {
                        //             setArticleObject({ ...articleObject, page: articleObject.page + 1 });
                        //         }
                        //     }
                        // }}
                        ref={scrollBar}
                        style={{
                          height:
                            dimensions.height -
                            headerHeight -
                            mainTitleHeight -
                            tabHeight -
                            listHeight -
                            searchBarRowHeight -
                            228,
                        }}
                      >
                        <Box className="articleList cardList">
                          {
                            articleDataListUnpin.map((value, index) => {
                              // console.log("value.sDescription", value?.sDescription);
                              return (
                                <CustomArticle
                                  key={index}
                                  pinLoading={pinLoading}
                                  category={
                                    value.CategoryName[0]?.sCategoryName
                                  }
                                  title={value.sArticleName}
                                  status={value.sStatus}
                                  subscription={value.subscription}
                                  price={value.price}
                                  pin={value.sPin}
                                  checked={value.sStatus !== "Inactive"}
                                  deletePopup={() => {
                                    setDeleteArticlePopup(true);
                                    setArticleId(value);
                                  }}
                                  disablePopup={() => {
                                    setDisableArticlePopup(true);
                                    setArticleId(value);
                                  }}
                                  articlePin={() => {
                                    localStorage.setItem("PIN ID", value._id);
                                    setArticleId(value);
                                    // if (articleDataListPin.length >= 3) {
                                    if (allArticle.filter((i)=>i.sPin===true).length >= 3) {
                                      setPinArticlePopup({
                                        type: true,
                                        value: true,
                                      });
                                    } else {
                                      pinArticleApi();
                                    }
                                  }}
                                  articleUnpin={() => {
                                    setArticleId(value);
                                    setPinArticlePopup({
                                      type: true,
                                      value: false,
                                    });
                                  }}
                                  editPopup={() => {
                                    console.log("value--", value);
                                    let temp = [];
                                    value.CategoryName.forEach((element) => {
                                      temp.push(element.sCategoryName);
                                    });
                                    console.log("abc", temp);
                                    setSelectedArtical(temp);
                                    setCheckArticleCatField(temp);
                                    setEditorText(
                                      value?.sDescription
                                        .replace(/&lt;/g, "<")
                                        .replace(/&gt;/g, ">")
                                        .replace(/&amp;/g, "&")
                                        .replace(/&nbsp;/g, " ")
                                        .replace(/<p[^>]*>/g, "")
                                        .replace(/<\/p>/g, "")
                                    );
                                    // setRawData(JSON.parse(value?.sDescription))
                                    setEditorCustomClass("changedText");
                                    setArticleId(value);
                                    setShowImage(value?.sArticleImage);
                                    // setCheckArticleDescription(value?.sDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ').replace(/<p[^>]*>/g, '').replace(/<\/p>/g, ''));
                                    setCheckArticleImgField(
                                      value?.sArticleImage
                                    );
                                    console.log(
                                      "checkfield",
                                      showImage,
                                      checkArticleImgField
                                    );
                                    setAddArticlePopup({
                                      type: true,
                                      value: "Edit",
                                    });
                                    addArticle?.setValue(
                                      "article_title",
                                      value?.sArticleName
                                    );
                                  }}
                                />
                              );
                            })
                            // : <Box className='no-data'>{articleObject.search === '' ? 'No Article Added' : 'No Such Article Found'}</Box>
                          }
                        </Box>
                      </Scrollbars>
                    </>
                  ) : (
                    <Box className="no-data">
                      {articleObject.search === ""
                        ? "No Article Added"
                        : "No Such Article Found"}
                    </Box>
                  )}
                  {/* <Box className='articleList cardList'>
                                            {articleDataListUnpin.map((value, index) => {
                                                return (<CustomArticle
                                                    key={index}
                                                    category={value.CategoryName[0]?.sCategoryName}
                                                    title={value.sArticleName}
                                                    status={value.sStatus}
                                                    subscription={value.subscription}
                                                    price={value.price}
                                                    pin={value.sPin}
                                                    checked={(value.sStatus !== 'Inactive')}
                                                    deletePopup={() => {
                                                        setDeleteArticlePopup(true);
                                                        setArticleId(value);
                                                    }}
                                                    disablePopup={() => {
                                                        setDisableArticlePopup(true);
                                                        setArticleId(value);
                                                    }}
                                                    articlePin={() => {
                                                        setArticleId(value);
                                                        setPinArticlePopup({ type: true, value: true })
                                                    }}
                                                    articleUnpin={() => {
                                                        setArticleId(value);
                                                        setPinArticlePopup({ type: true, value: false });
                                                    }}
                                                    editPopup={() => {
                                                        let temp = [];
                                                        value.CategoryName.forEach(element => {
                                                            temp.push(element.sCategoryName)
                                                        });
                                                        setSelectedArtical(temp);
                                                        setEditorText(value?.sDescription)
                                                        setEditorCustomClass('changedText');
                                                        setArticleId(value);
                                                        setShowImage(value?.sArticleImage)
                                                        setAddArticlePopup({ type: true, value: 'Edit' })
                                                        addArticle?.setValue('articleTitle', value?.sArticleName);
                                                    }}
                                                />
                                                );
                                            })
                                                // : <Box className='no-data'>{ articleObject.search === '' ? 'No Article Added' : 'No Such Article Found' }</Box>
                                            }
                                        </Box> */}
                </TabPanel>
                <TabPanel value="2">
                  <Box className="topbarWrap" display="flex">
                    <SearchBox
                      placeholder={strings.VIDEO_TITLE}
                      value={videoObject.search}
                      onSearch={(e) => {
                        setVideoObject({ ...videoObject, search: e, page: 1 });
                      }}
                      onClear={() => {
                        setVideoObject({ ...videoObject, search: "", page: 1 });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setAddVideoModal({ type: true, value: "Add" });
                        setVideoId(null);
                      }}
                    >
                      <Typography
                        component="span"
                        className="icon-plus-11"
                      ></Typography>
                      {strings.VIDEO}
                    </Button>
                  </Box>
                  <Scrollbars
                    onScroll={() => {
                      if (
                        Math.round(
                          scrollBar?.current.getScrollTop() +
                            scrollBar?.current.getClientHeight()
                        ) === scrollBar?.current.getScrollHeight()
                      ) {
                        if (pageLoad) {
                          setVideoObject({
                            ...videoObject,
                            page: videoObject.page + 1,
                          });
                        }
                      }
                    }}
                    ref={scrollBar}
                    style={{
                      height:
                        dimensions.height -
                        headerHeight -
                        mainTitleHeight -
                        tabHeight -
                        searchBarRowHeight -
                        45,
                    }}
                  >
                    <Box className="cardList videoList">
                      {videoData.length > 0 ? (
                        videoData.map((value, index) => {
                          return (
                            <>
                              <VideoCard
                                key={index}
                                imageurl={value.sVideoLink}
                                imagealt={value.sVideoLink}
                                onClickPlay={value.sVideoLink}
                                category={value.categoryData[0]?.sCategoryName}
                                title={value.sVideoName}
                                status={value.sStatus}
                                checked={value.sStatus !== "Inactive"}
                                deletePopup={() => {
                                  setDeleteVideoPopup(true);
                                  setVideoId(value);
                                }}
                                disablePopup={() => {
                                  setDisableVideoPopup(true);
                                  setVideoId(value);
                                }}
                                // openVideoModal={() => {
                                //     setVideoModal(true);
                                //     setVideoId(value);
                                // }}
                                editPopup={() => {
                                  setVideoId(value);
                                  setAddVideoModal({
                                    type: true,
                                    value: "Edit",
                                  });
                                  addVideo.setValue(
                                    "videoTitle",
                                    value.sVideoName
                                  );
                                }}
                                className="videoPopup"
                              />
                            </>
                          );
                        })
                      ) : (
                        <Box className="no-data">
                          {videoObject.search === ""
                            ? "No Video Added"
                            : "No Such Video Found"}
                        </Box>
                      )}
                    </Box>
                  </Scrollbars>
                </TabPanel>
                <TabPanel value="3">
                  <Box className="topbarWrap" display="flex">
                    <SearchBox
                      placeholder={strings.CATEGORY_TITLE}
                      value={categoryObject.search}
                      onSearch={(e) => {
                        setCategoryObject({
                          ...categoryObject,
                          search: e,
                          page: 1,
                        });
                      }}
                      onClear={() => {
                        setCategoryObject({
                          ...categoryObject,
                          search: "",
                          page: 1,
                        });
                      }}
                    />
                    <Button
                      onClick={() => {
                        setAddCategory({ type: true, value: "Add" });
                        setCategoryValue(null);
                      }}
                    >
                      <Typography
                        component="span"
                        className="icon-plus-11"
                      ></Typography>
                      {strings.CATEGORY}
                    </Button>
                  </Box>
                  <Scrollbars
                    onScroll={() => {
                      if (
                        Math.round(
                          scrollBar?.current.getScrollTop() +
                            scrollBar?.current.getClientHeight()
                        ) === scrollBar?.current.getScrollHeight()
                      ) {
                        if (pageLoad) {
                          setCategoryObject({
                            ...categoryObject,
                            page: categoryObject.page + 1,
                          });
                        }
                      }
                    }}
                    ref={scrollBar}
                    style={{
                      height:
                        dimensions.height -
                        headerHeight -
                        mainTitleHeight -
                        tabHeight -
                        searchBarRowHeight -
                        45,
                    }}
                  >
                    <Box className="cardList categoryVideoList">
                      {tabCategoryData.length > 0 ? (
                        tabCategoryData.map((value, index) => {
                          return (
                            <>
                              <CustomCard
                                key={index}
                                title={value.sCategoryName}
                                status={value.sStatus}
                                checked={value.sStatus !== "Inactive"}
                                deletePopup={() => {
                                  setDeleteCategoryPopup(true);
                                  setCategoryValue(value);
                                }}
                                disablePopup={() => {
                                  setDisableCategoryPopup(true);
                                  setCategoryValue(value);
                                }}
                                openVideoModal={() => setAddCategory(true)}
                                editPopup={() => {
                                  setAddCategory({ type: true, value: "Edit" });
                                  setCategoryValue(value);
                                }}
                                className="videoCategoryCard"
                              />
                            </>
                          );
                        })
                      ) : (
                        <Box className="no-data">
                          {categoryObject.search === ""
                            ? "No Category Added"
                            : "No Such Category Found"}
                        </Box>
                      )}
                    </Box>
                  </Scrollbars>
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </Box>
      </Box>
      <ModalBox
        className="deleteArticlePopup"
        open={deleteArticlePopup}
        close={() => {
          setDeleteArticlePopup(false);
          setArticleId(null);
          setShowImage("");
          setProductImg1(null);
        }}
        onSubmit={() => {
          setDeleteArticlePopup(false);
          articleDeleteApi();
          setArticleId(null);
          setShowImage("");
          setProductImg1(null);
        }}
        title={strings.DELETE2}
        warningText={`${
          strings.ARE_YOU_SURE_DELETE_USER +
          ' "' +
          articleId?.sArticleName +
          '" ' +
          "?"
        }`}
        button1={strings.YES_DELETE}
      />
      <ModalBox
        className="disableArticlePopup"
        open={disableArticlePopup}
        close={() => {
          setDisableArticlePopup(false);
          setArticleId(null);
          setShowImage("");
          setProductImg1(null);
        }}
        onSubmit={() => {
          setArticleId(null);
          setShowImage("");
          setProductImg1(null);
          setDisableArticlePopup(false);
          if (articleId?.sStatus === "Active") {
            statusArticleApi("Inactive");
          } else {
            statusArticleApi("Active");
          }
        }}
        title={
          articleId?.sStatus !== "Inactive" ? strings.DISABLE : strings.ENABLE
        }
        warningText={
          articleId?.sStatus !== "Inactive"
            ? `${
                strings.ARE_YOU_SURE_DISABLE_USER +
                ' "' +
                articleId?.sArticleName +
                '" ' +
                "?"
              }`
            : `${
                strings.ARE_YOU_SURE_ENABLE_USER +
                " " +
                articleId?.sArticleName +
                "?"
              }`
        }
        button1={
          articleId?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
      <AddModal
        open={addArticlePopup.type}
        close={() => {
          setArticleId(null);
          setShowImage("");
          setProductImg1(null);
          setAddArticlePopup(false);
          setEditorText("");
        }}
        className="addArticlePopup"
      >
        <Box sx={style} className="addModalWrap addArticle">
          <Typography id="modal-modal-title" variant="h5">
            {addArticlePopup.value === "Add"
              ? strings.ADD_ARTICLE
              : strings.EDIT_ARTICLE}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setAddArticlePopup(false);
                setArticleId(null);
                setShowImage("");
                setProductImg1(null);
                setEditorText("");
              }}
            ></Typography>
          </Typography>
          <HookForm
            init={(form) => {
              setAddArticle(form);
            }}
            defaultForm={{
              article_title: articleId?.sArticleName || "",
              photo: articleId?.sArticleImage || "",
              article_category: selectedArtical || [],
              editor: articleId?.sDescription || "",
              productname: "",
            }}
            onSubmit={(e) => {
              onAddArticleFormSubmit(e);
              setAddArticlePopup(false);
            }}
          >
            {(formMethod) => {
              return (
                <>
                  <Box display="flex" className="addArticleTop">
                    <Box className="imagesWrap" display="flex">
                      <Box className="uploadedImg">
                        <Typography
                          className={
                            showImage ? "icon-cancel" : "icon-cancel hid"
                          }
                          component="span"
                          onClick={() => {
                            setShowImage("");
                            setProductImg1(null);
                          }}
                        ></Typography>

                        <Controller
                          render={({ field: { onChange, value } }) => (
                            <CustomFileUpload
                              onDrop={(acceptImage1) => {
                                onUpload1(acceptImage1);
                                onChange(acceptImage1[0].name);
                              }}
                              accept="image/jpeg,image/jpg,image/png"
                            >
                              {showImage ? (
                                <Box className="image1 image">
                                  <img
                                    src={
                                      showImage
                                        ? process.env.REACT_APP_AWS_BASE_URL +
                                          showImage
                                        : ""
                                    }
                                  ></img>
                                  {loader && <Loader />}
                                </Box>
                              ) : (
                                <Box className="image1 image">
                                  <Typography
                                    component="span"
                                    className="icon-thumbnail"
                                  ></Typography>
                                  <Typography
                                    component="div"
                                    className="uploadText"
                                  >
                                    {strings.UPLOAD}
                                  </Typography>
                                </Box>
                              )}
                            </CustomFileUpload>
                          )}
                          control={formMethod.control}
                          name={addarticle.photo.value}
                          defaultValue={articleId?.sArticleImage}
                          rules={addarticle.photo.validates}
                        />
                      </Box>
                    </Box>
                    <Box className="articleTopRight">
                      <CustomTextField
                        variant="outlined"
                        autoFocus={false}
                        type="text"
                        requirePlaceHolder={strings.ARTICLE_TITLE}
                        formMethod={formMethod}
                        inputProps={{ maxLength: 100 }}
                        name={addarticle.articleTitle.value}
                        rules={addarticle.articleTitle.validate}
                        errors={formMethod?.formState.errors}
                        classText="regularInput"
                        // defaultValue={articleId?.sArticleName ? articleId?.sArticleName : ''}
                      />
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <CustomDropdown
                            mapData={categorydropdownData}
                            multiple={true}
                            customButton="true"
                            customClass="requiredSelect"
                            fieldPlaceHodler={strings.ARTICLE_CATEGORY}
                            withCheckBox="true"
                            addText={strings.ADD_CATEGORY}
                            btnTxt={strings.ADD}
                            onAddClick={(addData) => addCategoryApi(addData)}
                            // onChange={(e) => setSelectedArtical([...e.target.value])}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedArtical([...e.target.value]);
                            }}
                            defaultValuecat={value}
                          />
                        )}
                        multiple={true}
                        withCheckBox="true"
                        control={formMethod.control}
                        name={addarticle.articlecategory.value}
                        // defaultValue={JSON.stringify(selectedArtical)}
                        rules={addarticle.articlecategory.validates}
                      />
                    </Box>
                  </Box>
                  <Controller
                    render={({ field: { onChange } }) => (
                      <CustomDropdown
                        mapData={videoProductData}
                        multiple={false}
                        customButton="false"
                        fieldPlaceHodler={strings.SELECT_PRODUCT}
                        withCheckBox={false}
                        addText={strings.ADD_CATEGORY}
                        btnTxt={strings.ADD}
                        onChange={onChange}
                        defaultValuepro={
                          articleId?.ProductName &&
                          articleId.ProductName[0]?._id
                            ? articleId.ProductName[0]?._id
                            : ""
                        }
                      />
                    )}
                    control={formMethod.control}
                    name={addarticle.productname.value}
                    rules={addarticle.productname.validate}
                    defaultValue={
                      articleId?.ProductName && articleId.ProductName[0]?.sName
                        ? articleId.ProductName[0]?.sName
                        : ""
                    }
                  />
                  <Box
                    className={
                      editorText.length > 2500
                        ? "customTextField customEditorWrap hasError "
                        : "customTextField customEditorWrap"
                    }
                  >
                    <Box className="inputControlStyle">
                      <Controller
                        render={({ field: { onChange, value } }) => (
                          <EditorStyledToolbar
                            value={rawData}
                            class={editorCustomClass}
                            placeholder={strings.DESCRIPTION}
                            defaultContentState={rawData}
                            onChange={(e) => {
                              console.log(
                                "eblock",
                                e.blocks,
                                formMethod?.formState.isDirty
                              );
                              console.log("???????", e);
                              onChange(JSON.stringify(e));
                              setRawData(e);
                              // setEditorText(e.blocks[0].text);
                              setEditorCustomClass("changedText");
                              // defaultValue = {editorText}
                              // defaultValue={articleId?.sDescription}
                            }}
                          />
                        )}
                        control={formMethod.control}
                        name={addarticle.editor.value}
                        rules={addarticle.editor.validation}
                        defaultValue={articleId?.sDescription || ""}
                      />
                    </Box>
                    <Typography
                      component="span"
                      className={
                        editorText.length > 2500 ? "errorMsg show" : "errorMsg"
                      }
                    >
                      {strings.MORE_THAN_2500}
                    </Typography>
                  </Box>
                  <Box className="btnWrap" display="flex">
                    <CustomLoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      btnTitle={
                        addArticlePopup.value === "Add"
                          ? strings.ADD
                          : strings.UPDATE
                      }
                      loading={busy}
                      // disabled={addArticlePopup.value === 'Add' ? !formMethod?.formState.isValid || !showImage || !editorText || editorText.length > 2500 : !formMethod?.formState.isDirty && checkArticleDescription === editorText &&
                      //     JSON.stringify(checkArticleCatField) === JSON.stringify(selectedArtical) && (!showImage || checkArticleImgField === showImage)}
                      disabled={
                        addArticlePopup.value === "Add"
                          ? !(formMethod?.formState.isValid && showImage)
                          : !(
                              formMethod?.formState.isValid &&
                              formMethod?.formState.isDirty &&
                              showImage
                            )
                      }
                      className="applyBtn"
                    />
                    <Button
                      onClick={() => {
                        setAddArticlePopup(false);
                        setArticleId(null);
                        setShowImage("");
                        setProductImg1(null);
                        setEditorText("");
                      }}
                      className="cancelVideoButton"
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              );
            }}
          </HookForm>
        </Box>
      </AddModal>
      <AddModal
        open={pinArticlePopup.type}
        close={() => setPinArticlePopup(false)}
      >
        <Box
          sx={style}
          className={
            pinArticlePopup.value === true
              ? "addModalWrap articlePin articleUnpin"
              : "addModalWrap articlePin"
          }
        >
          <Typography id="modal-modal-title" variant="h5">
            {pinArticlePopup.value === true
              ? strings.SELECT_ARTICLE_TO_UNPIN
              : strings.SELECT_ARTICLE_TO_PIN}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setPinArticlePopup(false);
                setArticlePinObject({
                  ...articlePinObject,
                  search: "",
                  page: 1,
                });
              }}
            ></Typography>
          </Typography>
          <Typography component="p">
            {pinArticlePopup.value === false
              ? strings.TO_UNPIN_ARTICLE
              : strings.TO_PIN_ARTICLE}{" "}
            "
            <Typography className="articleNamePop" component="span">
              {articleId?.sArticleName}
            </Typography>
            ",
            {pinArticlePopup.value === false
              ? " " + strings.PLEASE_PIN_ANYONE
              : " " + strings.PLEASE_UNPIN_ANYONE}
          </Typography>
          {pinArticlePopup.value === false ? (
            <SearchBox
              placeholder={strings.ARTICLE_TITLE}
              value={articlePinObject.search}
              onSearch={(e) => {
                SetsearchpopupModel(true);
                setArticlePinObject({
                  ...articlePinObject,
                  search: e,
                  page: 1,
                });
              }}
              onClear={() => {
                SetsearchpopupModel(false);

                setArticlePinObject({
                  ...articlePinObject,
                  search: "",
                  page: 1,
                });
              }}
            />
          ) : (
            ""
          )}
          <Box className="articleList popup">
            {allArticle.length > 0 ? (
            // {articleDataList.length > 0 ? (
              <>
                <Scrollbars style={{ height: 347 }}>
                  {allArticle.map((value, index) => {
                  // {articleDataList.map((value, index) => {
                    return (
                      <>
                        {!pinArticlePopup.value &&
                          !value.sPin && (
                            // value.sPin === false && (
                            <Card
                              className="cardWrap pinCard notPinned"
                              key={index}
                            >
                              <Box className="rightWrap">
                                <CardContent className="cardContent">
                                  <Box className="cardTop">
                                    <Typography variant="h3" className="title">
                                      {value.sArticleName}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      className={
                                        unpinLoading
                                          ? "pin-status notPinned disabled"
                                          : "pin-status notPinned"
                                      }
                                      onClick={() => {
                                        localStorage.setItem(
                                          "PIN ID",
                                          value._id
                                        );
                                        UnpinArticleApi();
                                        setPinArticlePopup(false);
                                      }}
                                    >
                                      {" "}
                                      Pin
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Box>
                            </Card>
                          )}
                        {pinArticlePopup.value &&
                          value.sPin && (
                            // value.sPin === true && (
                            <Card className="cardWrap pinCard pinned">
                              <Box className="rightWrap">
                                <CardContent className="cardContent">
                                  <Box className="cardTop">
                                    <Typography variant="h3" className="title">
                                      {value.sArticleName}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      className="icon-pin"
                                    ></Typography>
                                    <Typography
                                      component="span"
                                      className={
                                        pinLoading
                                          ? "pin-status pinned disabled"
                                          : "pin-status pinned"
                                      }
                                      onClick={() => {
                                        localStorage.setItem(
                                          "UN PIN ID",
                                          value._id
                                        );
                                        pinArticleApi();
                                        setPinArticlePopup(false);
                                      }}
                                    >
                                      Unpin
                                    </Typography>
                                  </Box>
                                </CardContent>
                              </Box>
                            </Card>
                          )}
                      </>
                    );
                  })}
                </Scrollbars>
              </>
            ) : (
              <Box className="no-data">
                {articleObject.search === ""
                  ? "No Such Article Found"
                  : "No Such Article Found"}
              </Box>
            )}
          </Box>
        </Box>
      </AddModal>

      {/* <AddModal open={unpinArticlePopup} close={() => setUnpinArticlePopup(false)}>
                <Box sx={style} className="addModalWrap articlePin">
                    <Typography id="modal-modal-title" variant="h5">
                        {strings.SELECT_ARTICLE_TO_UNPIN}
                        <Typography component='span' className='icon-close clearIcon' onClick={() => setUnpinArticlePopup(false)}></Typography>
                    </Typography>
                    <Typography component="p">{strings.TO_PIN_ARTICLE} "<Typography className="articleNamePop" component="span">{strings.AWESOME_ORAL}</Typography>", {strings.PLEASE_UNPIN_ANYONE}</Typography>
                    <Box className='articleList popup'>
                        {articleData.map((value, index) => {
                            return (
                                <>
                                    {value.pin === 'Unpin' &&
                                        <Card sx={{ maxWidth: 398 }} className="cardWrap pinCard pinned">
                                            <Box className='rightWrap'>
                                                <CardContent className='cardContent'>
                                                    <Box className='cardTop'>
                                                        <Typography variant='h3' className='title'>{value.title}</Typography>
                                                        <Typography component="span" className="icon-pin"></Typography>
                                                        <Typography component="span" className="pin-status pinned">{value.sPin }</Typography>
                                                    </Box>
                                                </CardContent>
                                            </Box>
                                        </Card>
                                    }
                                </>
                            );
                        })}
                    </Box>
                </Box>
            </AddModal> */}
      <ModalBox
        className="deleteArticlePopup"
        open={deleteVideoPopup}
        close={() => {
          setDeleteVideoPopup(false);
          setVideoId(null);
        }}
        onSubmit={() => {
          setDeleteVideoPopup(false);
          videoDelteApi();
          setVideoId(null);
        }}
        title={strings.DELETE2}
        warningText={`${
          strings.ARE_YOU_SURE_DELETE_USER +
          ' "' +
          videoId?.sVideoName +
          ' " ' +
          "?"
        }`}
        button1={strings.YES_DELETE}
      />
      <ModalBox
        className="disableArticlePopup"
        open={disableVideoPopup}
        close={() => {
          setDisableVideoPopup(false);
          setVideoId(null);
        }}
        onSubmit={() => {
          setVideoId(null);
          setDisableVideoPopup(false);
          if (videoId?.sStatus === "Active") {
            statusVideoApi("Inactive");
          } else {
            statusVideoApi("Active");
          }
        }}
        title={
          videoId?.sStatus !== "Inactive" ? strings.DISABLE : strings.ENABLE
        }
        warningText={
          videoId?.sStatus !== "Inactive"
            ? `${
                strings.ARE_YOU_SURE_DISABLE_USER +
                ' "' +
                videoId?.sVideoName +
                '" ' +
                "?"
              }`
            : `${
                strings.ARE_YOU_SURE_ENABLE_USER +
                " " +
                videoId?.sVideoName +
                "?"
              }`
        }
        button1={
          videoId?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
      <AddModal
        open={videoModal}
        close={() => {
          setVideoModal(false);
        }}
        className="videoPopup"
      >
        <CardContent className="cardContent">
          <Box sx={style} className="videoWrap">
            <video width="700" controls>
              <source src={videoId?.sVideoLink} type="video/mp4" />
            </video>
          </Box>
        </CardContent>
      </AddModal>
      <AddModal
        open={addVideoModal.type}
        close={() => {
          setVideoId(null);
          setAddVideoModal(false);
        }}
        className="addVideoModal"
      >
        <Box sx={style} className="addModalWrap addVideo">
          <Typography id="modal-modal-title" variant="h5">
            {addVideoModal.value === "Add"
              ? strings.ADD_VIDEO
              : strings.EDIT_VIDEO}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setAddVideoModal(false);
                setVideoId(null);
              }}
            ></Typography>
          </Typography>
          <HookForm
            init={(form) => setAddVideo(form)}
            onSubmit={(e) => {
              onAddVideoFormSubmit(e);
              setAddVideoModal(false);
            }}
          >
            {(formMethod) => {
              return (
                <>
                  <Box className="addVideoTop">
                    <CustomTextField
                      variant="outlined"
                      autoFocus={false}
                      type="text"
                      requirePlaceHolder={strings.VIDEO_TITLE}
                      formMethod={formMethod}
                      inputProps={{ maxLength: 100 }}
                      name={addvideodata.videoTitle.value}
                      rules={addvideodata.videoTitle.validate}
                      errors={formMethod?.formState.errors}
                      classText="regularInput"
                      defaultValue={
                        videoId && videoId.sVideoName ? videoId.sVideoName : ""
                      }
                    />
                    <Controller
                      render={({ field: { onChange } }) => (
                        <CustomDropdown
                          mapData={categorydropdownData}
                          multiple={false}
                          customButton="false"
                          customClass="requiredSelect"
                          fieldPlaceHodler={strings.SELECT_VIDEO_CATEGORY}
                          withCheckBox={false}
                          onChange={onChange}
                          defaultValuecat={
                            videoId?.categoryData &&
                            videoId?.categoryData[0]?.sCategoryName
                              ? videoId?.categoryData[0]?.sCategoryName
                              : ""
                          }
                        />
                      )}
                      control={formMethod.control}
                      name={addvideodata.videocategory.name}
                      rules={addvideodata.videocategory.validate}
                      defaultValue={
                        videoId?.categoryData &&
                        videoId?.categoryData[0]?.sCategoryName
                          ? videoId?.categoryData[0]?.sCategoryName
                          : ""
                      }
                      // defaultValue={videoId?.categoryData && videoId?.categoryData[0]?.sCategoryName ? videoId?.categoryData[0]?.sCategoryName : ''}
                      // defaultValue={videoId?.categoryData && videoId?.categoryData[0]?.sCategoryName ? videoId?.categoryData[0]?.sCategoryName : ''}
                    />
                  </Box>
                  <Controller
                    render={({ field: { onChange } }) => (
                      <CustomDropdown
                        mapData={videoProductData}
                        multiple={false}
                        customButton="false"
                        fieldPlaceHodler={strings.SELECT_PRODUCT}
                        withCheckBox={false}
                        onChange={onChange}
                        defaultValuepro={
                          videoId?.ProductName && videoId?.ProductName[0]?._id
                            ? videoId?.ProductName[0]?._id
                            : ""
                        }
                      />
                    )}
                    control={formMethod.control}
                    name={addvideodata.videoproduct.name}
                    defaultValue={
                      videoId?.ProductName && videoId?.ProductName[0]?.sName
                        ? videoId?.ProductName[0]?.sName
                        : ""
                    }
                  />
                  <CustomTextField
                    variant="outlined"
                    autoFocus={false}
                    type="text"
                    requirePlaceHolder={strings.VIDEO_LINK}
                    formMethod={formMethod}
                    name={addvideodata.videoLink.value}
                    rules={addvideodata.videoLink.validate}
                    errors={formMethod?.formState.errors}
                    classText="regularInput"
                    defaultValue={
                      videoId && videoId.sVideoLink ? videoId.sVideoLink : ""
                    }
                  />
                  <Box className="btnWrap" display="flex">
                    <CustomLoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      btnTitle={
                        addVideoModal.value === "Add"
                          ? strings.ADD
                          : strings.UPDATE
                      }
                      // loading={busy}
                      disabled={
                        addVideoModal.value === "Add"
                          ? !formMethod?.formState.isValid
                          : !formMethod?.formState.isDirty
                      }
                      className="applyBtn"
                    />
                    <Button
                      onClick={() => {
                        setAddVideoModal(false);
                        setVideoId(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              );
            }}
          </HookForm>
        </Box>
      </AddModal>
      <ModalBox
        className="deleteCategoryPopup"
        open={deleteCategoryPopup}
        close={() => {
          setDeleteCategoryPopup(false);
          setCategoryValue(null);
        }}
        onSubmit={() => {
          setDeleteCategoryPopup(false);
          categoryDelteApi();
          setCategoryValue(null);
        }}
        title={strings.DELETE2}
        warningText={`${
          strings.ARE_YOU_SURE_DELETE_USER_CATEGORY +
          ' "' +
          categoryValue?.sCategoryName +
          '" ' +
          strings.category
        }`}
        button1={strings.YES_DELETE}
      />
      <ModalBox
        className="disableCategoryPopup"
        open={disableCategoryPopup}
        close={() => {
          setDisableCategoryPopup(false);
          setCategoryValue(null);
        }}
        onSubmit={() => {
          setCategoryValue(null);
          setDisableCategoryPopup(false);
          if (categoryValue?.sStatus === "Active") {
            statusCategoryApi("Inactive");
          } else {
            statusCategoryApi("Active");
          }
        }}
        title={
          categoryValue?.sStatus !== "Inactive"
            ? strings.DISABLE
            : strings.ENABLE
        }
        warningText={
          categoryValue?.sStatus !== "Inactive"
            ? `${
                strings.ARE_YOU_SURE_DISABLE_USER +
                ' "' +
                categoryValue?.sCategoryName +
                '" ' +
                "?"
              }`
            : `${
                strings.ARE_YOU_SURE_ENABLE_USER +
                " " +
                categoryValue?.sCategoryName +
                "?"
              }`
        }
        button1={
          categoryValue?.sStatus !== "Inactive"
            ? strings.YES_DISABLE
            : strings.YES_ENABLE
        }
      />
      <AddModal
        open={addCategory.type}
        close={() => {
          setCategoryValue(null);
          setAddCategory(false);
        }}
        className="addVideoModal"
      >
        <Box sx={style} className="addModalWrap addCategory">
          <Typography id="modal-modal-title" variant="h5">
            {addCategory.value === "Add"
              ? strings.ADD_CATEGORY
              : strings.EDIT_CATEGORY}
            <Typography
              component="span"
              className="icon-close clearIcon"
              onClick={() => {
                setAddCategory(false);
                setCategoryValue(null);
              }}
            ></Typography>
          </Typography>
          <HookForm
            init={(form) => setNewCategory(newCategory)}
            onSubmit={(e) => {
              onAddCategoryFormSubmit(e);
              setAddCategory(false);
            }}
          >
            {(formMethod) => {
              return (
                <>
                  <CustomTextField
                    variant="outlined"
                    autoFocus={false}
                    type="text"
                    placeholder={strings.CATEGORY}
                    formMethod={formMethod}
                    inputProps={{ maxLength: 100 }}
                    name={videoCategoryText.category.value}
                    rules={videoCategoryText.category.validate}
                    errors={formMethod?.formState.errors}
                    classText="regularInput"
                    defaultValue={
                      categoryValue && categoryValue.sCategoryName
                        ? categoryValue.sCategoryName
                        : ""
                    }
                  />
                  <Box className="btnWrap" display="flex">
                    <CustomLoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      btnTitle={
                        addCategory.value === "Add"
                          ? strings.ADD_CATEGORY
                          : strings.UPDATE
                      }
                      // loading={busy}
                      disabled={
                        addCategory.value === "Add"
                          ? !formMethod?.formState.isValid
                          : !formMethod?.formState.isValid ||
                            !formMethod?.formState.isDirty
                      }
                      className="applyBtn"
                    />
                    <Button
                      onClick={() => {
                        setAddCategory(false);
                        setCategoryValue(null);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </>
              );
            }}
          </HookForm>
        </Box>
      </AddModal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth?.user,
  };
};
const mapDispatchToProps = {
  showToast,
  setUser,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(PatientsEducation));
