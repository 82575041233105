import React from 'react';
import './FilterRadioButton.scss';

const FilterRadioButton = (props) => {
    const { id, changed, value, isSelected, disabled, uri, label } = props;

    return (
        <div className='RadioButton'>
            <img src={props.image} alt=''/>
            <input id={id}
                onChange={changed}
                value={value}
                type='radio'
                checked={isSelected}
                disabled={disabled}
                uri={uri} />
            <label htmlFor={id} className='lable'>{label}</label>
        </div>
    );
};

export default FilterRadioButton;
