import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { multilanguage } from 'redux-multilanguage';
import { Grid } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';

import HookForm from '../../../../components/HookForm/HookForm';
import CustomLoadingButton from '../../../../components/CustomLoadingButton/CustomLoadingButton';

import instance from '../../../../api/axios';
import Constant from '../../../../utils/constant';
import { showToast } from '../../../../redux/actions/toastAction';
import { setUser } from '../../../../redux/actions/authAction';
import useWindowDimension from '../../../../hooks/useWindowDimension';

import './VerificationOtp.scss';
import { MetaTags } from 'react-meta-tags';
const VerificationOtp = (props) => {
    const { strings } = props;
    const Ref = useRef(null);
    const history = useNavigate();
    const dimensions = useWindowDimension();
    const [busy, setBusy] = useState(false);
    const [forgotPass, setForgotPass] = useState();
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState('00:00');

    const handleChange = (otp) => {
        setOtp(otp);
    };
    const onFormSubmit = async (value) => {
        console.log('value==>', otp);
        setBusy(true);
        const params = {
            id: localStorage.getItem('VERIFY_ID'),
            OTP: otp
        };
        await instance.post('/auth/verifyOTP', params)
            .then((res) => {
                setTimeout(() => {
                    setBusy(false);
                    history('/resetpassword');
                }, 3000);
            }).catch((e) => {
                setTimeout(() => {
                    setBusy(false);
                    setOtp('');
                    props.showToast({
                        message: e.response.data.message,
                        type: 'error'
                    });
                }, 3000);
            });
    };
    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    };
    const startTimer = (e) => {
        const { total, minutes, seconds } = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':' + (seconds > 9 ? seconds : '0' + seconds)
            );
        } else {
            setTimer(false);
        }
    };
    const clearTimer = (e) => {
        setTimer('01:00');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000);
        Ref.current = id;
    };
    const getDeadTime = () => {
        const deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 60);
        return deadline;
    };
    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);
    const onClickReset = async () => {
        const params = {
            id: localStorage.getItem('VERIFY_ID')
        };
        await instance.post('/auth/resendOtp', params)
            .then((res) => {
                props.showToast({
                    message: strings.LINK_TO_OTP,
                    type: 'success'
                });
                setTimeout(() => {
                    setBusy(false);
                }, 3000);
                clearTimer(getDeadTime());
            }).catch((e) => {
                setTimeout(() => {
                    setBusy(false);
                    props.showToast({
                        message: e.response.data.message,
                        type: 'error'
                    });
                }, 3000);
            });
    };
    return (
        <>
            <MetaTags>
                <title>Verification Otp | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Verification Otp your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:title" content="Verification Otp | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Verification Otp your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Grid container component="main" className="VerificationOtpMain" style={{ height: dimensions.height + 'px' }}>
                <Grid item xs={6} className="signinLeft">
                    <Box className='centerContent' align='center'>
                        <img src={Constant.IMAGESURL.LOGO} className="logo" alt="Logo" width='336' height='287' />
                    </Box>
                </Grid>
                <Grid item xs={6} className="signinRight" style={{ height: dimensions.height + 'px' }}>
                    <img src={Constant.IMAGESURL.LOGO_TEXT} className="logo" alt="Logo2" width='167' height='92' />
                    <Box className='upperContent'>
                        <Typography className='title'>OTP Verification</Typography>
                        <Typography className='description'>Please enter the 6 digit code sent to your registered email address</Typography>
                    </Box>
                    <HookForm
                        defaultForm={{}}
                        init={(form) => setForgotPass(forgotPass)}
                        onSubmit={(e) => onFormSubmit(e)}
                    >
                        {(formMethod) => {
                            return (
                                <Fragment>
                                    <Box className='formGroupField'>
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6}
                                            isInputNum={true}
                                            separator={<span>-</span>}
                                            inputStyle={{ width: 40, height: 40 }}
                                        />
                                    </Box>
                                    <Box className='submitBtn'>
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            btnTitle={strings.SUBMIT}
                                            loading={busy}
                                            disabled={!otp}
                                            className="submitButton"
                                        />
                                    </Box>
                                    <div className='backLink'>Resend OTP in :<Button style={{ display: timer ? 'none' : 'block' }} onClick={onClickReset} className='redirectLinkText'>Resend</Button><Typography className='timerTextStyle'>{ timer }</Typography></div>
                                </Fragment>
                            );
                        }}
                    </HookForm>
                </Grid>
            </Grid>
        </>

    );
};

const mapStateToProps = (state) => {
    return {
        user: state.auth?.user
    };
};

const mapDispatchToProps = {
    showToast,
    setUser
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(VerificationOtp));
