import React, { useState, useEffect } from 'react';
import { multilanguage } from 'redux-multilanguage';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';

import { hideToast } from '../../redux/actions/toastAction';

import './ToastComponent.scss';

const ToastComponent = (props) => {
    const { toast: { type, message, show } } = props;
    const [showToast, setShowToast] = useState(false);

    useEffect(() => {
        setShowToast(show);
    }, [show]);

    return (
        <div className='tostMain'>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={showToast} autoHideDuration={3000} onClose={() => {
                setShowToast(false);
                setTimeout(() => {
                    props.hideToast();
                }, 1000);
            }}>
                <Alert icon={type === 'success' ? <i className='icon icon-check' /> : <i className='icon icon-close' />} onClose={() => props.hideToast()} severity={type === 'success' ? 'success' : 'error'}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        toast: state.toastReducer.toast
    };
};

const mapDispatchToProps = {
    hideToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ToastComponent));
