import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import PageNotFound from '../../pages/PageNotFound/PageNotFound';
// import PageNotFound from '../../pages/PageNotFound/PageNotFound';
import { AdminLayoutRoute } from '../../routes';

import Header from './Header/Header';
/* eslint-disable */
const UserLayout = (props) => {
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(true);
    const history = useNavigate();
    let { pathname } = location;

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('access_token') ? true : false;
        setIsLogin(isLoggedIn);
    }, []);

    const authenticateRoute = () => {
        pathname = pathname.replace("/login", "/")
        let routeIndex = AdminLayoutRoute.findIndex(route => pathname == route.path);
        if(pathname !== "/" && routeIndex !== -1) {
            const Component = AdminLayoutRoute[routeIndex].component;
            return isLogin ? Component: <Navigate to = "/login" replace={true} />
        } else {
            return <PageNotFound />
        }
    }


    return (
        <div className='main'>
            {isLogin && <Header {...props} />} 
            <Routes>
                {AdminLayoutRoute.map((route, index) => {
                    return <Route
                        key={index}
                        exact={route.exact}
                        path={route.path}
                        // element={route.component}
                        element={authenticateRoute()}
                    />;
                })}
            </Routes>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(UserLayout);
