/* eslint-disable */
import React, { useEffect, useState } from "react";
import { multilanguage } from "redux-multilanguage";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { connect, useDispatch } from "react-redux";
import { showToast } from "../../../redux/actions/toastAction";

import { createTheme, ThemeProvider, styled } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { Close } from "@material-ui/icons";
import MuiAppBar from "@mui/material/AppBar";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import CustomTextField from "../../../components/CustomTextField/CustomTextFeild";
import HookForm from "../../../components/HookForm/HookForm";
import CustomLoadingButton from "../../../components/CustomLoadingButton/CustomLoadingButton";
import CustomFileUpload from "../../../components/CustomFileUpload/CustomFileUpload";
import Constant from "../../../utils/constant";
import "./Header.scss";
import SearchBox from "../../../components/SearchBox/SearchBox";
import instance from "../../../api/axios";
import { setUser } from "../../../redux/actions/authAction";
import AWS from "aws-sdk";
import { io } from "socket.io-client";
import popUp from "./popUp.mp3";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
});

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const Header = (props) => {
  const { strings, user } = props;
  const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
  const history = useNavigate();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [productImg1, setProductImg1] = useState();
  const [userProfileForm, setProfileForm] = useState();
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  const [passwordShown, setPasswordShown] = useState("text");
  const [passwordShown2, setPasswordShown2] = useState("text");
  const [editableField, setEditableField] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showImage, setShowImage] = useState();
  const [activeMenu, setActiveMenu] = useState("");
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const [notification, setNotification] = useState([]);
  const [params, setParams] = useState("");
  const audio = new Audio(popUp);
  // console.log("audio", audio.play());

  useEffect(() => {
    setSocket(io("https://mouthmap-staging-api.herokuapp.com"));
    // setSocket(io("http://localhost:8003"));

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("newUserOnBoarded", (user) => {
        setNotification((prevNotifications) => [user, ...prevNotifications]);
        console.log("user", user);
        audio.play();
      });
    }
    if (socket) {
      socket.on("newOrderPlaced", (user) => {
        setNotification((prevNotifications) => [user, ...prevNotifications]);
        console.log("user", user);
        audio.play();
      });
    }
    if (socket) {
      socket.on("subscriptionIsDue", (user) => {
        setNotification((prevNotifications) => [user, ...prevNotifications]);
        console.log("user", user);
        audio.play();
      });
    }
    if (socket) {
      socket.on("cancelTheSubscription", (user) => {
        setNotification((prevNotifications) => [user, ...prevNotifications]);
        console.log("user", user);
        audio.play();
      });
    }
  }, [socket]);
  useEffect(() => {
    if (searchText) {
      setParams(`?search=${searchText}`);
    } else {
      setParams("");
    }
  }, [searchText]);

  const fetchNotification = async () => {
    try {
      const response = await instance.get(
        `/adminNotification/getNotifications${params}`
      );
      setNotification(response.payload);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, [params]);

  const userProfileFormValidation = {
    userName: {
      name: "userName",
      validate: {
        required: {
          value: true,
          message: "",
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: "",
        },
      },
    },
    userEmail: {
      name: "userEmail",
      validate: {
        required: {
          value: true,
          message: "",
        },
        pattern: {
          value: Constant.REGEX.EMAIL,
          message: "",
        },
      },
    },
  };
  const adminChnagePasswordValidationForm = {
    oldPassword: {
      name: "oldPassword",
      validate: {
        required: {
          value: true,
          message: strings.OLDPASSWORD_REQUIRED,
        },
      },
    },
    newPassword: {
      name: "newPassword",
      validate: {
        required: {
          value: true,
          message: strings.NEWPASSWORD_REQUIRED,
        },
        validate: {
          length: (value) =>
            (value && value.length >= 8 && value && value.length <= 64) || "",
          hasSpecialChar: (value) =>
            (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
          hasNumbers: (value) =>
            (value && value.match(Constant.REGEX.NUMBER)) !== null,
          hasUpperLowercase: (value) =>
            value && value.match(Constant.REGEX.UPPERCASELOWERCASE) !== null,
        },
      },
    },
    confirmPassword: {
      name: "confirmPassword",
      validate: {
        required: {
          value: true,
          message: strings.CONFIRMPASSWORD_REQUIRED,
        },
        pattern: {
          value: Constant.REGEX.ALPHANUMERIC,
          message: "",
        },
      },
    },
  };
  const multiErrorFields = [
    { length: "Minimum 8 characters" },
    { hasSpecialChar: "Minimum 1 special character" },
    { hasNumbers: "Minimum 1 numerical character" },
    { hasUpperLowercase: "Minimum 1 uppercase & lowercase character" },
  ];
  function formatDateTime(timestamp) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(timestamp);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "pm" : "am";

    const formattedTime = `${hours > 12 ? hours - 12 : hours}:${
      minutes < 10 ? "0" : ""
    }${minutes} ${period}`;

    return `${day} ${month} ${year} ${formattedTime}`;
  }

  // const inputTimestamp = "2023-08-08T04:25:49.638Z";
  // const formattedDate = formatDateTime(inputTimestamp);
  // console.log(formattedDate); // Output: "8 August 2023 4:25 pm"

  // const notificationData = [
  //   {
  //     id: "1",
  //     title: "Subscription cancelled",
  //     subtitle:
  //       "In consectetur aute amet cupidatat et nulla. Nostrud enim sunt eu amet ex nulla nulla mollit laboris consectetur",
  //     notificationTime: "10 July 2020 10:30 pm",
  //   },
  //   {
  //     id: "1",
  //     title: "New user subscribed",
  //     subtitle:
  //       "In consectetur aute amet cupidatat et nulla. Nostrud enim sunt eu amet ex nulla nulla mollit laboris consectetur",
  //     notificationTime: "10 July 2020 10:30 pm",
  //   },
  //   {
  //     id: "1",
  //     title: "New order placed",
  //     subtitle:
  //       "In consectetur aute amet cupidatat et nulla. Nostrud enim sunt eu amet ex nulla nulla mollit laboris consectetur",
  //     notificationTime: "10 July 2020 10:30 pm",
  //   },
  // ];
  const theme = createTheme({
    palette: {
      primary: {
        light: "#67798e",
        main: "#3b4d61",
        dark: "#112537",
        contrastText: "#000000",
      },
      secondary: {
        light: "#ffefe3",
        main: "#e8bcb1",
        dark: "#b58c82",
        contrastText: "#000000",
      },
    },
  });
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const sections = [
    {
      title: "Dashboard",
      url: "/home",
    },
    {
      title: "Users",
      url: "/usermanagerment",
    },
    {
      title: "Products",
      url: "/manage-products",
    },
    {
      title: "Orders",
      url: "/orders",
    },
    {
      title: "Manage Deliveries",
      url: "/manage-deliveries",
    },
    {
      title: "View Earnings",
      url: "/view-earnings",
    },
    {
      title: "Patient’s Education",
      url: "/patients-education",
    },
    {
      title: "Manage Promocode",
      url: "/managerpromocode",
    },
  ];
  useEffect(() => {}, [busy]);
  const [ship, setShip] = useState();

  const onFormSubmit = async (value) => {
    setBusy(true);
    const params = {
      shippingPrice: value.shipping_charge,
    };
    await instance
      .post("/order/addshipping", params)
      .then((res) => {
        setTimeout(() => {
          props.showToast({
            message: strings.SHIPING_SUCCESSFULLY,
            type: "success",
          });
          setBusy(false);
        }, 1000);
      })
      .catch((e) => {});
  };
  const onFormSubmitEditProfile = async (value) => {
    setBusy(true);
    props.showToast({
      message: strings.LOGIN_SUCCESSFULLY,
      type: "success",
    });
    setTimeout(() => {
      setBusy(false);
      history("/home");
    }, 3000);
  };
  const onFormSubmitChangePassword = async (value) => {
    const params = {
      oldPassword: value.oldPassword,
      newPassword: value.newPassword,
    };
    console.log("change pass params", params);
    await instance
      .put("/profile/admin/changePassword", params)
      .then((res, e) => {
        setTimeout(() => {
          props.showToast({
            message: strings.SUCCESFULLY_PASS_CHANGE,
            type: "success",
          });
          // handleClick2(e);
          setAdminProfileShow(false);
        }, 1000);
        // setAdminProfileShow(false);
        // handleClick2(e);
      })
      .catch((e) => {
        setTimeout(() => {
          props.showToast({
            message: strings.INVALID_PASS,
            type: "error",
          });
          setAdminProfileShow(false);
        }, 1000);
      });
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [changePasswordForm, setChangePasswordForm] = useState();
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const openMenu3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openMenu2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const shipRate = {
    value: "shipping_charge",
    validate: {
      required: {
        value: true,
      },
    },
  };
  const stopPropagationForTab = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };
  const onUploadHandle = (acceptImage1) => {
    if (acceptImage1.length > 0) {
      setShowImage("");
      const file = acceptImage1[0];
      const arr = file.name.split(".");
      const mimetype = file.type.split("/");
      console.log(mimetype);

      setLoader(true);
      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: S3_BUCKET,
          contentType: file.type,
          Key: user?.id + "/" + Date.now().toString() + "." + arr[1],
          Body: file,
        },
      });
      console.log("//////////////////////////////////////", user?.id);
      upload.on("httpUploadProgress", (progress) => {
        const progressPercentage = Math.round(
          (progress.loaded / progress.total) * 100
        );
        console.log(progressPercentage);
      });
      const promise = upload.promise();

      promise.then(
        function (image) {
          setLoader(false);
          setShowImage(image.key);
          console.log("````````````````````````````", image.key);
        },
        // eslint-disable-next-line n/handle-callback-err
        function (err) {}
      );
    } else {
      setLoader(false);
      props.showToast({
        message: "Only PDF,JPG,JPEG,DOC and DOCS files are allowed.",
        type: "error",
      });
    }
    const reader = new FileReader();
    // reader.onload('load', (event) => {
    reader.onload = function (event) {
      // console.log('2424234234234234234', reader.onload);
      setProductImg1(event.target.result);
      console.log(
        "==========121212121=======121=2=12=12=1=21=2=1=",
        event.target.result
      );
    };
    reader.readAsDataURL(acceptImage1[0]);
  };

  useEffect(() => {
    editProfile();
  }, [showImage]);

  const editProfile = async (first, last) => {
    // console.log('editProfile', valueData[0], valueData[1]);
    const params = {
      sFirstName: first,
      sLastName: last,
      sProfileImage: showImage,
    };
    await instance
      .put(`/profile/profile/updateProfile?id=${props.user.id}`, params)
      .then((res) => {
        dispatch({
          type: "SET_USER",
          payload: res.payload,
        });
        // handleClose2();
      })
      .catch((e) => {
        console.log("e", e);
      });
  };

  const handleActiveMenu = (title) => {
    sections.map((section, index) => {
      if (title === section.title) {
        console.log("active menu", section.title, title);
        setActiveMenu(title);
      }
      return section;
    });
  };
  return (
    <>
      <AppBar position="fixed" open={open} className="headerMain">
        <ThemeProvider theme={theme}>
          <Toolbar sx={{ borderBottom: 1, borderColor: "divider" }}>
            <IconButton
              color="inherit"
              className="toggleBtn"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "block" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box className="logo-wrap">
              <img
                src="https://res.cloudinary.com/dygllydcg/image/upload/v1689829788/MouthMap/Web/Static-images/mouthmap-logo.svg"
                height="32"
                width="180"
                alt=""
              />
            </Box>
            <Box className="menu-list-wrap">
              {sections.map((section, index) => (
                <span
                  onClick={() => {
                    handleActiveMenu(section.title);
                  }}
                  // className={section.title === activeMenu ? 'active header-menu' : 'header-menu'}
                >
                  <NavLink to={section.url} key={index}>
                    {section.title}
                  </NavLink>
                </span>
              ))}
            </Box>
            <Box className="menu-right" onKeyDown={stopPropagationForTab}>
              <Button
                id="fade-button"
                aria-controls={openMenu ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? "true" : undefined}
                onClick={handleClick}
              >
                <Badge
                  badgeContent="£"
                  color="secondary"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <span className="icon-box"></span>
                </Badge>
              </Button>
              <Menu
                className="shipMenu"
                id="fade-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                TransitionComponent={Fade}
              >
                <MenuItem
                  className="shipMenuItem"
                  disableTouchRipple
                  onKeyDown={stopPropagationForTab}
                >
                  <HookForm
                    init={(form) => setShip(ship)}
                    onSubmit={(e) => onFormSubmit(e)}
                  >
                    {(formMethod) => {
                      return (
                        <>
                          <CustomTextField
                            variant="outlined"
                            autoFocus={false}
                            type="number"
                            classText="masking"
                            numberMask={true}
                            inputLabel={strings.SET_SHIPPING_CHARGE}
                            formMethod={formMethod}
                            name={shipRate.value}
                            rules={shipRate.validate}
                            errors={formMethod?.formState.errors}
                          />
                          <Box className="btnWrap">
                            <Button onClick={handleClose} className="cancelBtn">
                              Cancel
                            </Button>
                            <CustomLoadingButton
                              fullWidth
                              size="large"
                              type="submit"
                              variant="contained"
                              color="primary"
                              btnTitle={strings.APPLY}
                              loading={busy}
                              onClick={handleClose}
                              disabled={!formMethod?.formState.isValid}
                              className="applyBtn"
                            />
                          </Box>
                        </>
                      );
                    }}
                  </HookForm>
                </MenuItem>
              </Menu>
              <Button
                id="fade-button2"
                aria-controls={openMenu2 ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu2 ? "true" : undefined}
                onClick={handleClick2}
              >
                <span className="icon-user"></span>
              </Button>
              <Menu
                className="profile"
                id="fade-menu2"
                anchorEl={anchorEl2}
                open={openMenu2}
                onClose={() => {
                  handleClose2();
                  setAdminProfileShow(false);
                  setEditableField(true);
                  setPasswordShown("text");
                  setPasswordShown2("text");
                }}
                MenuListProps={{
                  "aria-labelledby": "fade-button2",
                }}
                TransitionComponent={Fade}
              >
                <HookForm
                  defaultForm={{}}
                  init={(form) => setProfileForm(userProfileForm)}
                  onSubmit={(e) => {
                    // editProfile(e);
                    // onFormSubmitEditProfile(e);
                    console.log("valueerteretretretre", e);
                  }}
                >
                  {(formMethod) => {
                    return (
                      <>
                        {!adminProfileShow && (
                          <Box
                            className="adminProfileCard"
                            onKeyDown={stopPropagationForTab}
                          >
                            <Box className="adminProfileUplaodCadr">
                              <Box className="sectionTitle">
                                <Typography variant="h5" className="title">
                                  My Profile
                                </Typography>
                              </Box>
                              <Box className="fileUploadSection">
                                <Box
                                  className={
                                    props?.user?.sProfileImage
                                      ? "fileUploadShowSection removeImage"
                                      : "fileUploadShowSection"
                                  }
                                >
                                  {props?.user?.sProfileImage ? (
                                    <img
                                      src={
                                        props.user.sProfileImage
                                          ? process.env.REACT_APP_AWS_BASE_URL +
                                            props.user.sProfileImage
                                          : ""
                                      }
                                      {...loader}
                                      alt="userProfile"
                                      className="userImage"
                                    />
                                  ) : (
                                    <Typography
                                      component="span"
                                      className="userShortName"
                                    >
                                      {props.user.sFirstName?.charAt(0) +
                                        props.user.sLastName?.charAt(0)}
                                    </Typography>
                                  )}
                                </Box>
                                <Box className="fileUpload">
                                  <CustomFileUpload
                                    onDrop={(acceptImage1) => {
                                      onUploadHandle(acceptImage1);
                                      // editProfile();
                                    }}
                                    accept="image/jpeg,image/jpg,image/png"
                                  >
                                    <Typography
                                      component="span"
                                      className="fileUploadButton"
                                    >
                                      {props?.user?.sProfileImage
                                        ? "Edit"
                                        : "Upload"}
                                    </Typography>
                                  </CustomFileUpload>
                                </Box>
                                {props?.user?.sProfileImage && (
                                  <Button
                                    className="removeBtn"
                                    onClick={() => {
                                      setShowImage(null);
                                      setProductImg1(null);
                                    }}
                                  >
                                    Remove
                                  </Button>
                                )}
                              </Box>
                            </Box>
                            <Box className="profileFormGroupField">
                              <Box className="formGroupField fomGroupFieldName">
                                <CustomTextField
                                  variant="outlined"
                                  autoFocus={false}
                                  classText={
                                    editableField ? "editText" : "updateText"
                                  }
                                  type="text"
                                  placeholder={
                                    editableField
                                      ? props.user.sFirstName +
                                        " " +
                                        props.user.sLastName
                                      : "Enter your name"
                                  }
                                  formMethod={formMethod}
                                  rules={
                                    userProfileFormValidation.userName.validate
                                  }
                                  name={userProfileFormValidation.userName.name}
                                  errors={formMethod?.formState.errors}
                                  leftIcon="icon-user2"
                                  IconName={editableField ? "Edit" : "Update"}
                                  {...console.log(
                                    editableField ? "Edit" : "Update",
                                    "editableee=>>>"
                                  )}
                                  onClick={(e) => {
                                    // eslint-disable-next-line no-lone-blocks
                                    {
                                      editableField === "Edit"
                                        ? setEditableField(!editableField)
                                        : setEditableField(!editableField);
                                      const userName = formMethod?.watch(
                                        userProfileFormValidation.userName.name
                                      );
                                      if (userName.includes(" ") === true) {
                                        const valueData = userName.split(" ");
                                        console.log(
                                          "787878887878787878787878",
                                          valueData
                                        );
                                        const first = valueData[0];
                                        const last = valueData[1];
                                        editProfile(first, last);
                                      } else {
                                        const valueData = userName;
                                        editProfile(valueData, " ");
                                      }
                                    }
                                    // setEditableField(!editableField);
                                  }}
                                  inputProps={{
                                    readOnly: editableField,
                                    maxLength: 200,
                                  }}
                                  defaultValue={
                                    props.user.sFirstName +
                                    " " +
                                    props.user.sLastName
                                  }
                                />
                              </Box>
                              <Box className="formGroupField">
                                <CustomTextField
                                  placeholder={props.user.sEmail}
                                  variant="outlined"
                                  autoFocus={false}
                                  type="text"
                                  className="regularInput"
                                  formMethod={formMethod}
                                  rules={
                                    userProfileFormValidation.userEmail.validate
                                  }
                                  name={
                                    userProfileFormValidation.userEmail.name
                                  }
                                  errors={formMethod?.formState.errors}
                                  leftIcon="icon-mail"
                                  inputProps={{ readOnly: true }}
                                />
                              </Box>
                            </Box>
                            <Box className="profileFormGroupButton">
                              <Box className="formGroupButton fomGroupButtonOne">
                                <CustomLoadingButton
                                  fullWidth
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  btnTitle="Change Password"
                                  startIcon="icon-key1"
                                  onClick={() => setAdminProfileShow(true)}
                                />
                              </Box>
                              <Box className="formGroupButton">
                                <CustomLoadingButton
                                  fullWidth
                                  size="large"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  btnTitle="Log Out"
                                  startIcon="icon-power"
                                  onClick={() => {
                                    window.localStorage.removeItem(
                                      Constant.REFRESH_TOKEN_KEY
                                    );
                                    window.localStorage.removeItem(
                                      Constant.ACCESS_TOKEN_KEY
                                    );
                                    navigate("/login");
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </>
                    );
                  }}
                </HookForm>
                {adminProfileShow && (
                  <HookForm
                    defaultForm={{}}
                    init={(form) => setChangePasswordForm(changePasswordForm)}
                    onSubmit={(e) => {
                      onFormSubmitChangePassword(e);
                      // handleClick2(e);
                    }}
                  >
                    {(formMethod) => {
                      return (
                        <Box
                          className="addProfileChangePassword"
                          onKeyDown={stopPropagationForTab}
                        >
                          <Box className="sectionTitle">
                            <Typography variant="h5" className="title">
                              Change Password
                            </Typography>
                          </Box>
                          <Box className="changePasswordFormFeild">
                            <Box className="formGroupField">
                              <CustomTextField
                                requirePlaceHolder="Old password"
                                variant="outlined"
                                inputProps={{
                                  form: {
                                    autocomplete: "off",
                                  },
                                  maxLength: 64,
                                }}
                                autoFocus={false}
                                type="password"
                                formMethod={formMethod}
                                rules={
                                  adminChnagePasswordValidationForm.oldPassword
                                    .validate
                                }
                                name={
                                  adminChnagePasswordValidationForm.oldPassword
                                    .name
                                }
                                errors={formMethod?.formState.errors}
                                autocomplete="false"
                                // onChange = {(e) => {
                                //     console.log(e.target.value);
                                // }}
                              />
                            </Box>
                            <Box className="formGroupField">
                              <CustomTextField
                                requirePlaceHolder="New password"
                                variant="outlined"
                                autoFocus={false}
                                type={passwordShown ? "password" : "text"}
                                formMethod={formMethod}
                                inputProps={{
                                  form: {
                                    autocomplete: "off",
                                  },
                                  maxLength: 64,
                                }}
                                multiErrorFields={multiErrorFields}
                                rules={{
                                  required: {
                                    value: true,
                                    message: strings.NEWPASSWORD_REQUIRED,
                                  },
                                  validate: {
                                    matchPassword: (value) => {
                                      return (
                                        (value?.toLowerCase() &&
                                          value?.toLowerCase() !==
                                            formMethod
                                              .watch(
                                                adminChnagePasswordValidationForm
                                                  .oldPassword.name
                                              )
                                              .toLowerCase()) ||
                                        "New password can not be same as old passowrd"
                                      );
                                    },
                                    length: (value) => {
                                      return (
                                        (value &&
                                          value.length >= 8 &&
                                          value &&
                                          value.length <= 64) ||
                                        ""
                                      );
                                    },
                                    hasSpecialChar: (value) =>
                                      (value &&
                                        value.match(
                                          Constant.REGEX.SPECIALCHARACTERS
                                        )) !== null,
                                    hasNumbers: (value) =>
                                      (value &&
                                        value.match(Constant.REGEX.NUMBER)) !==
                                      null,
                                    hasUpperLowercase: (value) =>
                                      value &&
                                      value.match(
                                        Constant.REGEX.UPPERCASELOWERCASE
                                      ) !== null,
                                  },
                                }}
                                name={
                                  adminChnagePasswordValidationForm.newPassword
                                    .name
                                }
                                errors={formMethod?.formState.errors}
                                onClick={() => setPasswordShown(!passwordShown)}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  e.target.value = value.replaceAll(" ", "");
                                  formMethod.setValue(
                                    adminChnagePasswordValidationForm
                                      .newPassword.name,
                                    e.target.value
                                  );
                                  formMethod.watch(
                                    adminChnagePasswordValidationForm
                                      .confirmPassword.name
                                  ) &&
                                    formMethod.trigger(
                                      adminChnagePasswordValidationForm
                                        .confirmPassword.name
                                    );
                                }}
                                IconName={
                                  !passwordShown ? (
                                    <span className="icon icon-eye"></span>
                                  ) : (
                                    <span className="icon icon-eyeoff"></span>
                                  )
                                }
                              />
                            </Box>
                            <Box className="formGroupField">
                              <CustomTextField
                                requirePlaceHolder="Confirm password"
                                variant="outlined"
                                autoFocus={false}
                                inputProps={{
                                  form: {
                                    autocomplete: "off",
                                  },
                                  maxLength: 64,
                                }}
                                rules={{
                                  required: {
                                    value: true,
                                    message: strings.CONFIRMPASSWORD_REQUIRED,
                                  },
                                  validate: {
                                    matchPassword: (value) =>
                                      (value &&
                                        value ===
                                          formMethod.watch("newPassword")) ||
                                      "Password doesn’t match",
                                  },
                                }}
                                formMethod={formMethod}
                                type={passwordShown2 ? "password" : "text"}
                                name={
                                  adminChnagePasswordValidationForm
                                    .confirmPassword.name
                                }
                                errors={formMethod?.formState.errors}
                                onClick={() =>
                                  setPasswordShown2(!passwordShown2)
                                }
                                IconName={
                                  !passwordShown2 ? (
                                    <span className="icon icon-eye"></span>
                                  ) : (
                                    <span className="icon icon-eyeoff"></span>
                                  )
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  e.target.value = value.replaceAll(" ", "");
                                  formMethod.setValue(
                                    adminChnagePasswordValidationForm
                                      .confirmPassword.name,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box className="formGroupButton">
                              <CustomLoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                btnTitle="Change"
                                disabled={!formMethod?.formState.isValid}
                              />
                              <CustomLoadingButton
                                fullWidth
                                size="large"
                                type="button"
                                variant="outlined"
                                color="primary"
                                btnTitle="Cancel"
                                className="outlineCancelBtn"
                                onClick={() => {
                                  setAdminProfileShow(false);
                                  setPasswordShown2("text");
                                  setPasswordShown("text");
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      );
                    }}
                  </HookForm>
                )}
              </Menu>
              <Button
                id="fade-button3"
                aria-controls={openMenu3 ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu3 ? "true" : undefined}
                onClose={handleClose3}
                onClick={handleClick3}
              >
                <Badge
                  variant="dot"
                  color="secondary"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <span className="icon-bell2"></span>
                </Badge>
              </Button>
              <Menu
                className="notification"
                id="fade-menu3"
                anchorEl={anchorEl3}
                open={openMenu3}
                onClose={handleClose3}
                MenuListProps={{
                  "aria-labelledby": "fade-button3",
                }}
                TransitionComponent={Fade}
              >
                <Box
                  className="notificationCard"
                  onKeyDown={stopPropagationForTab}
                >
                  <Box className="sectionTitle">
                    <Typography variant="h5" className="title">
                      Notifications
                    </Typography>
                  </Box>
                  <Box className="customSearchBox">
                    <SearchBox
                      placeholder="Search"
                      value={searchText}
                      onSearch={(e) => {
                        setsearchText(e);
                      }}
                      onClear={() => {
                        setsearchText("");
                      }}
                    />
                  </Box>
                  <Stack
                    className="notitficationCardRow"
                    direction="column"
                    spacing={1}
                  >
                    {notification.map((item, index) => {
                      return (
                        <Box className="notificationColumn" key={index}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            className="notificationheaderSection"
                          >
                            <Typography className="starIcon"></Typography>
                            <Typography className="title" variant="subtitle1">
                              {item.title}
                            </Typography>
                          </Stack>
                          <Typography className="descreption" variant="body2">
                            {`${item.message}`}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="flex-end"
                            className="dateAndTimeSection"
                            alignItems="center"
                          >
                            <Typography className="dateAndTime" variant="body2">
                              {formatDateTime(item.createdAt)}
                            </Typography>
                          </Stack>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              </Menu>
            </Box>
          </Toolbar>
        </ThemeProvider>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        className="sidebar"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <Close />
          </IconButton>
        </DrawerHeader>
        {sections.map((section, index) => (
          <NavLink key={index} to={section.url} onClick={handleDrawerClose}>
            {section.title}
          </NavLink>
        ))}
      </Drawer>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth?.user,
  };
};

const mapDispatchToProps = {
  setUser,
  showToast,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(multilanguage(Header));

// export default multilanguage(Header);
