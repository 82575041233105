/* eslint no-use-before-define: 0 */
/*eslint-disable */
import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Stack, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import { multilanguage } from 'redux-multilanguage';
import 'react-datepicker/dist/react-datepicker.css';
import Scrollbars from 'react-custom-scrollbars-2';
import DatePicker from 'react-datepicker';
import Highcharts from 'highcharts';

import FilterRadioButton from '../../components/FIlterRadioButton/FilterRadioButton';
import useWindowDimension from '../../hooks/useWindowDimension';
import './ViewEarnings.scss';
import instance from '../../api/axios';
import { MetaTags } from 'react-meta-tags';

const ViewEarnings = (props) => {
    const dimensions = useWindowDimension();
    const [productEarningData, setProductEarningData] = useState([]);
    const [oneTimeProductEarningGraphData, setOneTimeProductEarningGraphData] = useState([]);
    const [subscribedProductEarningGraphData, setSubscribedProductEarningGraphData] = useState([]);
    // const [productEarningGraphData, setProductEarningGraphData] = useState([]);
    const [oneTimeProductEarning, setOneTimeProductEarning] = useState(0);
    const [subscribedProductEarning, setSubscribedProductEarning] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [earningDateRadio, setEarningDateRadio] = useState('');
    const [earningDateRadio2, setEarningDateRadio2] = useState('');
    const [brandEarningData, setBrandEarningData] = useState([]);
    const [brandEarningChartValue, setBrandEarningChartValue] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [totalBrandEarning, setTotalBrandEarning] = useState(0);
    const [productStartDate, setProductStartDate] = useState(new Date());
    const [brandStartDate, setBrandStartDate] = useState(new Date());

    const [weekFilter, setWeekFilter] = useState('');
    // const [selectDateFilter, setSelectDateFilter] = useState(new Date());
    const [checkState, setCheckState] = useState(true);

    Highcharts.setOptions({
        lang: {
            thousandsSep: ','
        }
    });

    const options1 = {
        chart: {
            type: 'column',
            // height: '330',
            spacingTop: 60,
            spacingRight: 0,
            scrollablePlotArea: {
                minWidth: 400,
                scrollPositionX: 1
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: allProducts,
            scrollbar: {
                enabled: true
            },
            max: allProducts.length - 1,
            labels: {
                spacingLeft: 20,
                style: {
                    color: '#3B4D61',
                    fontSize: '12px'
                }
            }
        },
        yAxis: {
            min: 0,
            lineColor: '#D2E1DF',
            lineWidth: 1,
            title: {
                text: 'Earnings',
                style: { color: '#3B4D61' }
            },
            labels: {
                style: {
                    color: '#3B4D61',
                    fontSize: '12px'
                },
                format: '£{text}'
            },
            stackLabels: {
                enabled: false,
                style: {
                    fontWeight: 'bold',
                    color: ( // theme
                        Highcharts.defaultOptions.title.style &&
                        Highcharts.defaultOptions.title.style.color
                    ) || 'gray',
                    textOutline: 'none'
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: -60,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderWidth: 0,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '£{point.y}',
            backgroundColor: 'white',
            borderWidth: '0',
            borderRadius: '8'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                spacingLeft: 0,
                align: 'left',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [{
            name: `<span>Subscriptions</span> <br/> £${subscribedProductEarning.toLocaleString()} <br/> ${(((subscribedProductEarning / (subscribedProductEarning + oneTimeProductEarning)) * 100).toFixed(2))}%`,
            // name: '<span>Subscriptions</span> <br/> £${subscribedProductEarning.toLocaleString()} ${subscribedProductEarning && (((subscribedProductEarning / (subscribedProductEarning + oneTimeProductEarning)) * 100).toFixed(2))}%`,
            borderWidth: 0,
            maxPointWidth: 20,
            color: '#E8BCB1',
            data: subscribedProductEarningGraphData
            // data: [3000, 5000, 1000, 6000, 2000]
        }, {
            name: `<span>One-time purchases</span> <br/> £${Math.floor(oneTimeProductEarning).toLocaleString()}  <br/> ${(((oneTimeProductEarning / (subscribedProductEarning + oneTimeProductEarning)) * 100).toFixed(2))}%`,
            // name: '<span>One-time purchases</span> <br/>',
            borderWidth: 0,
            maxPointWidth: 20,
            color: '#3B4D61',
            data: oneTimeProductEarningGraphData
            // data: [6000, 1000, 3000, 2000, 4000]
        }]
        // series: [
        //     {
        //         name: 'Browsersss',
        //         colorByPoint: true,
        //         data: [
        //             {
        //                 name: 'Chrome',
        //                 y: 63.06,
        //                 drilldown: 'Chrome'
        //             },
        //             {
        //                 name: 'Safari',
        //                 y: 19.84,
        //                 drilldown: 'Safari'
        //             },
        //             {
        //                 name: 'Firefox',
        //                 y: 4.18,
        //                 drilldown: 'Firefox'
        //             },
        //             {
        //                 name: 'Edge',
        //                 y: 4.12,
        //                 drilldown: 'Edge'
        //             },
        //             {
        //                 name: 'Opera',
        //                 y: 2.33,
        //                 drilldown: 'Opera'
        //             },
        //             {
        //                 name: 'Internet Explorer',
        //                 y: 0.45,
        //                 drilldown: 'Internet Explorer'
        //             },
        //             {
        //                 name: 'Other',
        //                 y: 1.582,
        //                 drilldown: null
        //             }
        //         ]
        //     }
        // ]
    };
    useEffect(() => {
        console.log('subscribedProductEarningGraphData', subscribedProductEarningGraphData, oneTimeProductEarningGraphData, allProducts);
    }, [subscribedProductEarningGraphData, oneTimeProductEarningGraphData, allProducts]);
    const options2 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            height: '330',
            spacingBottom: 20,
            type: 'pie'
        },
        title: {
            text: `Total Brand Earnings <br> <span>£${Math.floor(totalBrandEarning).toLocaleString()}</span>`,
            align: 'left',
            floating: 'true',
            style: {
                color: '#3B4D61',
                fontSize: '12px',
                lineHeight: '24px'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                borderColor: '#157575',
                allowPointSelect: true,
                cursor: 'pointer',
                borderWidth: '1',
                size: '217',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Brands',
            shadow: true,
            padding: 20,
            marginTop: 200,
            colorByPoint: true,
            data: brandEarningChartValue
            // data: [{
            //     name: 'Dental care <br><br> <span>£1590(30%)</span>',
            //     y: 30,
            //     color: '#157575'
            // },
            // {
            //     name: 'Mouthcare <br><br> <span>£848(16%)</span>',
            //     y: 16,
            //     color: '#C0C6CC'
            // },
            // {
            //     name: 'Healthcare oral <br><br> <span>£848(16%)</span>',
            //     y: 27,
            //     color: '#3B4D61'
            // },
            // {
            //     name: 'Oral care <br><br> <span>£1431(27%)</span>',
            //     y: 27,
            //     color: '#E8BCB1'
            // }]
            // data:
        }]
    };

    useEffect(() => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
    }, []);
    const handleResize = () => {
        setHeaderHeight(document.getElementsByClassName('headerMain')[0]?.offsetHeight);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(async () => {
        await instance.get('/brands/allBrandList')
            .then((res) => {
                console.log('allBrandList', res);
            }).catch((e) => {
                console.log('allBrandList error', e);
            });
    }, []);

    useEffect(async () => {

    }, [subscribedProductEarningGraphData, oneTimeProductEarningGraphData]);

    const getProductEarnings = async (date) => {
        let params = '';
        if (date && weekFilter === 'Yearly') {
            params = `?date=${weekFilter}&year=${date}`;
        } else {
            if (date) {
                params = `?selectedDate=${date}`;
            } else if (weekFilter) {
                params = `?date=${weekFilter}`;
            }
        }
        // if (weekFilter) {
        // } else if (!selectDateFilter === new Date()) {
        //     params = `?selectedDate=${selectDateFilter}`;
        // }
        await instance.get(`products/productEarning/${params}`).then(async (response) => {
            console.log('product Earning Data', response);
            setProductEarningData(response.payload.getProductEarning);
            // let graphData = [];
            const allProductData = [];
            const productEarningDataSubs = [];
            const productEarningDataOneTime = [];
            let subscribedProductEarningData = 0;
            let setOneTimeProductEarningData = 0;
            if (response.payload.getProductEarning.length !== 0) {
                response.payload.getProductEarning.forEach(async (data) => {
                    allProductData.push(data?.productData[0]?.sName);
                    if (data?.productData[0]?.sMonthly_subScription) {
                        // ?.reduce((n, {  }) => parseInt(n) + parseInt(calories), 0),
                        // subscribedProductEarningData += data?.productData[0]?.nPrice * data.productData.length;
                        subscribedProductEarningData += data?.productEarning;
                        productEarningDataSubs.push(data?.productEarning);
                        productEarningDataOneTime.push(0);
                        // setSubscribedProductEarning(currElem => currElem + (data?.productData[0]?.nPrice * data.productData.length));
                        // setSubscribedProductEarningGraphData(subscribedProductEarningGraphData.push(data?.productData[0]?.nPrice * data.productData.length));
                        // setSubscribedProductEarningGraphData(currElem => [...currElem, data?.productData[0]?.nPrice * data.productData.length]);
                    }
                    if (!data?.productData[0]?.sMonthly_subScription) {
                        // setOneTimeProductEarningData += data?.productData[0]?.nPrice * data.productData.length;
                        setOneTimeProductEarningData += data?.productEarning;
                        productEarningDataOneTime.push(data?.productEarning);
                        productEarningDataSubs.push(0);
                        // setOneTimeProductEarning(currElem => currElem + (data?.productData[0]?.nPrice * data.productData.length));
                        // setOneTimeProductEarningGraphData(oneTimeProductEarningGraphData.push(data?.productData[0]?.nPrice * data.productData.length));
                        // setOneTimeProductEarningGraphData(currElem => [...currElem, data?.productData[0]?.nPrice * data.productData.length]);
                    }
                    setAllProducts(allProductData);
                    setSubscribedProductEarningGraphData(productEarningDataSubs);
                    setOneTimeProductEarningGraphData(productEarningDataOneTime);
                    setOneTimeProductEarning(setOneTimeProductEarningData);
                    setSubscribedProductEarning(subscribedProductEarningData);
                });
                setTimeout(() => {
                    console.log('subscribedProductEarningGraphData', subscribedProductEarningGraphData, oneTimeProductEarningGraphData, allProducts);
                }, 5000);
            } else {
                setAllProducts(allProductData);
                setSubscribedProductEarningGraphData(productEarningDataSubs);
                setOneTimeProductEarningGraphData(productEarningDataOneTime);
                setOneTimeProductEarning(setOneTimeProductEarningData);
                setSubscribedProductEarning(subscribedProductEarningData);
            }
        }).catch(async (error) => {
            console.log('getProductEarningError', error);
        });
    };

    useEffect(async () => {
        getProductEarnings();
    }, [weekFilter]);

    const getBrandEarning = async (date) => {
        let params = '';
        if (earningDateRadio2 === 'Yearly2' && date) {
            params = `?date=${earningDateRadio2}&year=${date}`;
        } else {
            if (date) {
                console.log('date filter');
                params = `?selectedDate=${date}`;
            } else if (earningDateRadio2) {
                params = `?date=${earningDateRadio2}`;
            }
        }
        await instance.get(`/brands/brandEarning/${params}`)
            .then((res) => {
                setBrandEarningData(res.payload.brandEarning);
                const totalEarning = res.payload.brandEarning.reduce((n, { brandEarning }) => parseFloat(n) + parseFloat(brandEarning), 0);
                setTotalBrandEarning(totalEarning.toFixed(2));
                const brandEarningChart = [];
                res.payload?.brandEarning?.forEach((result, index) => {
                    const test = {
                        name: result?.brandData[0]?.sBrandName,
                        y: (result.brandEarning / totalBrandEarning) * 100,
                        // color: '#' + parseInt(Math.random() * 0xffffff).toString(16) + (index + 55)
                        color: ('#' + (157575 + index * 56789))
                    };
                    brandEarningChart.push(test);
                    setTimeout(() => {
                        console.log('sdfsdfs-', brandEarningChart, test);
                    }, 3000);
                    // setBrandEarningChartValue(cur => [...cur, test]);
                    // return brandEarningChartValue;
                });
                setBrandEarningChartValue(brandEarningChart);
                setTimeout(() => {
                    console.log('sdfsdfs', brandEarningChart);
                }, 1000);
                console.log('BrandList', res);
            }).catch((e) => {
                console.log('BrandList error', e);
            });
    };
    const onChangeMyProgressDate = dates => {
        setProductStartDate(dates);
        const year = new Date(dates);
        console.log('yeardate', year.getFullYear());
        if (earningDateRadio === 'Yearly') {
            getProductEarnings(year.getFullYear());
        }
    };
    const onChangeBrandProgressDate = dates => {
        // setEarningDateRadio2('');
        const date = new Date(dates);
        const fullYear = date.getFullYear();
        if (earningDateRadio2 === 'Yearly2') {
            getBrandEarning(fullYear);
            setCheckState(false);
            setBrandStartDate(dates);
        } else {
            setCheckState(false);
            setBrandStartDate(dates);
            getBrandEarning(dates);
            console.log('brandStartDate', brandStartDate);
        }
    };
    useEffect(async () => {
        checkState && getBrandEarning();
        console.log('---state', checkState);
    }, [checkState && totalBrandEarning, checkState && earningDateRadio2]);

    useEffect(async () => {
        if (!checkState && earningDateRadio2 !== 'Yearly2') {
            getBrandEarning(brandStartDate);
        }
        // if (!checkState && earningDateRadio2 === 'Yearly2') {
        //     getBrandEarning();
        // }
    }, [totalBrandEarning]);
    const MyProgressDateFilter = ({ value, onClick }) => {
        return (
            <button type="button" className="custom-date-input" onClick={onClick}>
                {value} <i className='icon icon-chevron-down'
                />
            </button>
        );
    };
    // const productdata = productEarningGraphData;
    // const branddata = [
    //     {
    //         id: '1',
    //         name: 'Dental care',
    //         earings: '£1590',
    //         percentage: '30%'
    //     },
    //     {
    //         id: '2',
    //         name: 'Mouthcare',
    //         earings: '£848',
    //         percentage: '16%'
    //     },
    //     {
    //         id: '3',
    //         name: 'Healthcare Oral',
    //         earings: '£1431',
    //         percentage: '27%'
    //     },
    //     {
    //         id: '4',
    //         name: 'Oral care',
    //         earings: '£1431',
    //         percentage: '27%'
    //     },
    //     {
    //         id: '5',
    //         name: 'Oral care',
    //         earings: '£1431',
    //         percentage: '27%'
    //     }
    // ];
    return (
        <>
            <MetaTags>
                <title>View Earnings | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Explore sales data by product, brand, date, and more. Learn more about the best-selling products, analyse trends, and more." />
                <meta property="og:title" content="View Earnings | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Explore sales data by product, brand, date, and more. Learn more about the best-selling products, analyse trends, and more. " />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Scrollbars className='EarningsWrapper commonWrapper' style={{ marginTop: headerHeight, height: dimensions.height - headerHeight }}>
                <Box>
                    <Container>
                        <Typography variant='h1' className='mainTitle'>View Earnings</Typography>
                    </Container>
                </Box>
                <Container>
                    <Grid container spacing={1}>
                        <Grid item className='produceEarnings'>
                            <Typography variant='subtitle1' className='secTitle'>Product Earnings</Typography>
                            <Box className='chartWrap'>
                                <Box className='timeFilterWrap'>
                                    <Box className='radioWrap'>
                                        <Stack
                                            className='filterRadioButtonRow'>
                                            <FilterRadioButton
                                                id='Weekly'
                                                label='Weekly'
                                                value='Weekly'
                                                changed={(e) => { setEarningDateRadio(e.target.value); setCheckState(true); setWeekFilter(e.target.value); }}
                                                isSelected={earningDateRadio === 'Weekly'}
                                            />
                                            <FilterRadioButton
                                                id='Monthly'
                                                label='Monthly'
                                                value='Monthly'
                                                changed={(e) => { setEarningDateRadio(e.target.value); setWeekFilter(e.target.value); setProductStartDate(new Date()); }}
                                                isSelected={earningDateRadio === 'Monthly'}
                                            />
                                            <FilterRadioButton
                                                id='Yearly'
                                                label='Yearly'
                                                value='Yearly'
                                                changed={(e) => { setEarningDateRadio(e.target.value); setWeekFilter(e.target.value); }}
                                                isSelected={earningDateRadio === 'Yearly'}
                                            />
                                        </Stack>
                                    </Box>
                                    <Box className='dropDownWrap'>
                                        <Box className={(earningDateRadio === 'Weekly' || earningDateRadio === 'Yearly') ? 'monthWrap hideDisabled' : 'monthWrap'}>
                                            <DatePicker
                                                disabled={earningDateRadio === 'Weekly' || earningDateRadio === 'Yearly'}
                                                selected={productStartDate}
                                                onChange={(e) => { onChangeMyProgressDate(e); getProductEarnings(e); }}
                                                customInput={<MyProgressDateFilter />}
                                                popperClassName="dateFilter monthPicker"
                                                maxDate={new Date()}
                                                showYearPicker={false}
                                                showMonthYearPicker={true}
                                                dateFormat="MMM"
                                                isClearable={false}
                                            />
                                        </Box>
                                        <Box className={(earningDateRadio === 'Weekly') ? 'yearWrap hideDisabled' : 'yearWrap'}>
                                            <DatePicker
                                                disabled={earningDateRadio === 'Weekly'}
                                                selected={productStartDate}
                                                onChange={(e) => { onChangeMyProgressDate(e); }}
                                                customInput={<MyProgressDateFilter />}
                                                popperClassName="dateFilter"
                                                maxDate={new Date()}
                                                showYearPicker={true}
                                                dateFormat="yyyy"
                                                isClearable={false}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                {productEarningData.length > 0 ? <HighchartsReact highcharts={Highcharts} scrollPositionX={true} options={options1} /> : <div style={{ height: '300px' }}><h5><center>No Data Found!</center></h5></div>}
                            </Box>
                            <Box className='tableWrap productTable'>
                                <Scrollbars style={{ height: '336px' }}>
                                    <Table stickyHeader className='UserManagementtable'>
                                        <TableHead className='tableHeader'>
                                            <TableRow className='tableHeaderRow'>
                                                <TableCell className='tableHeaderColumn'>Products</TableCell>
                                                <TableCell className='tableHeaderColumn'>One-time</TableCell>
                                                <TableCell className='tableHeaderColumn'>Subscriptions</TableCell>
                                                <TableCell className='tableHeaderColumn'>Earnings</TableCell>
                                                <TableCell className='tableHeaderColumn'>Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tableBody'>
                                            {productEarningData.length > 0
                                                ? productEarningData?.map((item, index) => {
                                                    // console.log('setProductEarningData', productEarningData);
                                                    console.log('oneTimeProductEarning', oneTimeProductEarning, subscribedProductEarning);
                                                    // let nickName = item.sFirstName.charAt(0) + item.sLastName.charAt(0)
                                                    return (
                                                        <TableRow
                                                            key={index}>
                                                            <TableCell className='tableBodyColumn'>
                                                                <Box className='mainBox'>
                                                                    <Typography className='brushColor' style={{ backgroundColor: item?.productData[0]?.color ? item?.productData[0]?.color : 'black' }}></Typography>
                                                                    <Typography className='productSize'>{item?.productData[0]?.sName}</Typography>
                                                                    <Typography className='productSize'>{item?.productData[0]?.size ? item?.productData[0]?.size : '-'}</Typography>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell className='tableBodyColumn '>{!item?.productData[0]?.sMonthly_subScription ? '£' + item?.productEarning.toLocaleString() : '-'}</TableCell>
                                                            <TableCell className='tableBodyColumn '>{item?.productData[0]?.sMonthly_subScription ? '£' + item?.productEarning.toLocaleString() : '-'}</TableCell>
                                                            <TableCell className='tableBodyColumn'>{'£' + item?.productEarning.toLocaleString()}</TableCell>
                                                            <TableCell className='tableBodyColumn'>{((item?.productEarning / (subscribedProductEarning + oneTimeProductEarning)) * 100).toFixed(2)}%</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                : <TableRow className='tableHeaderRow'>
                                                    <TableCell className='tableHeaderColumn'></TableCell>
                                                    <TableCell className='tableHeaderColumn' colSpan='3'><center>No Data Found</center></TableCell>
                                                    <TableCell className='tableHeaderColumn'></TableCell>
                                                </TableRow>}
                                            <TableRow>
                                                <TableCell className='tableFooterColumn'>Total</TableCell>
                                                <TableCell className='tableFooterColumn '>£{oneTimeProductEarning.toLocaleString()}</TableCell>
                                                <TableCell className='tableFooterColumn '>£{subscribedProductEarning.toLocaleString()}</TableCell>
                                                <TableCell className='tableFooterColumn'>£{(subscribedProductEarning + oneTimeProductEarning).toLocaleString()}</TableCell>
                                                <TableCell className='tableFooterColumn'>100%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Scrollbars>
                            </Box>
                        </Grid>
                        <Grid item className='brandEarnings' >
                            <Box>
                                <Typography variant='subtitle1' className='secTitle'>Brand Earnings</Typography>
                                <Box className='chartWrap'>
                                    <Box className='timeFilterWrap'>
                                        <Box className='radioWrap'>
                                            <Stack
                                                className='filterRadioButtonRow'>
                                                <FilterRadioButton
                                                    id='Weekly2'
                                                    label='Weekly'
                                                    value='Weekly2'
                                                    changed={(e) => { setEarningDateRadio2(e.target.value); setCheckState(true); }}
                                                    isSelected={earningDateRadio2 === 'Weekly2'}
                                                />
                                                <FilterRadioButton
                                                    id='Monthly2'
                                                    label='Monthly'
                                                    value='Monthly2'
                                                    changed={(e) => { setEarningDateRadio2(e.target.value); setCheckState(true); setBrandStartDate(new Date()); }}
                                                    isSelected={earningDateRadio2 === 'Monthly2'}
                                                />
                                                <FilterRadioButton
                                                    id='Yearly2'
                                                    label='Yearly'
                                                    value='Yearly2'
                                                    changed={(e) => { setEarningDateRadio2(e.target.value); setCheckState(true); setBrandStartDate(new Date()); }}
                                                    isSelected={earningDateRadio2 === 'Yearly2'}
                                                />
                                            </Stack>
                                        </Box>
                                        <Box className='dropDownWrap'>
                                            <Box className={(earningDateRadio2 === 'Weekly2' || earningDateRadio2 === 'Yearly2') ? 'monthWrap hideDisabled' : 'monthWrap'}>
                                                <DatePicker
                                                    disabled={earningDateRadio2 === 'Weekly2' || earningDateRadio2 === 'Yearly2'}
                                                    selected={brandStartDate}
                                                    onChange={(e) => { onChangeBrandProgressDate(e); }}
                                                    customInput={<MyProgressDateFilter />}
                                                    popperClassName="dateFilter monthPicker"
                                                    maxDate={new Date()}
                                                    showYearPicker={false}
                                                    showMonthYearPicker={true}
                                                    dateFormat="MMM"
                                                    isClearable={false}
                                                />
                                            </Box>
                                            <Box className={(earningDateRadio2 === 'Weekly2') ? 'yearWrap hideDisabled' : 'yearWrap'}>
                                                <DatePicker
                                                    disabled={earningDateRadio2 === 'Weekly2'}
                                                    selected={brandStartDate}
                                                    onChange={(e) => { onChangeBrandProgressDate(e); console.log('zxcvbnm', e); }}
                                                    customInput={<MyProgressDateFilter />}
                                                    popperClassName="dateFilter"
                                                    maxDate={new Date()}
                                                    showYearPicker={true}
                                                    dateFormat="yyyy"
                                                    isClearable={false}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {brandEarningData.length > 0 ? <HighchartsReact highcharts={Highcharts} options={options2} /> : <div style={{ height: '400px' }}><h5><center>No Data Found!</center></h5></div>}
                                </Box>
                            </Box>
                            <Box className='tableWrap brandTable'>
                                <Scrollbars style={{ height: '336px' }}>
                                    <Table stickyHeader className='UserManagementtable'>
                                        <TableHead className='tableHeader'>
                                            <TableRow className='tableHeaderRow'>
                                                <TableCell className='tableHeaderColumn'>Brands</TableCell>
                                                <TableCell className='tableHeaderColumn' colSpan='3'>Earnings</TableCell>
                                                <TableCell className='tableHeaderColumn'>Percentage</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tableBody'>
                                            {brandEarningData.length > 0
                                                ? brandEarningData?.map((item, index) => {
                                                    // setTotalBrandEarning(totalBrandEarning + parseInt(item?.brandEarning));
                                                    // let nickName = item.sFirstName.charAt(0) + item.sLastName.charAt(0)
                                                    return (
                                                        <TableRow
                                                            key={index}>
                                                            <TableCell className='tableBodyColumn'>{item?.brandData[0].sBrandName}</TableCell>
                                                            <TableCell className='tableBodyColumn' colSpan='3'>£{item?.brandEarning.toLocaleString()}</TableCell>
                                                            <TableCell className='tableBodyColumn'>{((item?.brandEarning / totalBrandEarning) * 100).toFixed(2)}%</TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                : <TableRow className='tableHeaderRow'>
                                                    <TableCell className='tableHeaderColumn'></TableCell>
                                                    <TableCell className='tableHeaderColumn' colSpan='3'><center>No Data Found</center></TableCell>
                                                    <TableCell className='tableHeaderColumn'></TableCell>
                                                </TableRow>}
                                            <TableRow>
                                                <TableCell className='tableFooterColumn'>Total</TableCell>
                                                <TableCell className='tableFooterColumn' colSpan='3'>£{totalBrandEarning.toLocaleString()}</TableCell>
                                                <TableCell className='tableFooterColumn'>100%</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Scrollbars>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Scrollbars>
        </>
    );
};

export default multilanguage(ViewEarnings);
