import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { ErrorMessage } from '@hookform/error-message';
import { Box, TextField, InputLabel, InputAdornment, IconButton } from '@mui/material';

import './CustomTextFeild.scss';
const CustomTextField = (props) => {
    const [className, setClassName] = useState('');
    const {
        id,
        variant,
        label,
        errors,
        color,
        pageName,
        requirePlaceHolder,
        disabled,
        type,
        name,
        autoFocus,
        formMethod,
        defaultValue,
        inputLabel,
        rules,
        placeholder,
        multiErrorFields,
        errorIcon,
        successIcon,
        inputProps,
        IconName,
        onClick,
        leftIcon,
        onFocus
    } = props; const hasError = errors[name] !== undefined;
    return (
        <Box component='main' className={hasError ? 'customTextField hasError' : 'customTextField'}>
            <Box className={className}>
                <Box className={(typeof formMethod?.formState.errors[name] === 'undefined' && formMethod.watch(name)) ? 'position valid' : 'position'}>
                    <Controller
                        defaultValue={defaultValue}
                        render={({ field: { onChange } }) => (
                            <>
                                <InputLabel htmlFor={id} className='textfieldLabel'>{inputLabel}</InputLabel>
                                {props.numberMask
                                    ? <Box className={props.classText + ' inputControlStyle'}>
                                        <Box className='MuiOutlinedInput-root' display='flex'>
                                            <Box className='MuiInputAdornment-root'></Box>
                                            <NumericFormat
                                                disabled={disabled}
                                                name={name}
                                                label={label}
                                                className="inputmask-digit MuiInputBase-input"
                                                allowedDecimalSeparators={['%']}
                                                placeholder={placeholder}
                                                maxLength={8}
                                                value={props.value || formMethod.watch(name)}
                                                onChange={(e) => {
                                                    onChange && onChange(e.target.value.trimLeft());
                                                    props.onChange && props.onChange(e);
                                                    e.target.value ? setClassName('hasValue') : setClassName('');
                                                }}
                                            />
                                        </Box>

                                    </Box>
                                    : <TextField
                                        fullWidth
                                        color={color}
                                        placeholder={placeholder}
                                        className={'inputControlStyle ' + props.classText}
                                        id={id}
                                        variant={variant}
                                        label={label}
                                        name={name}
                                        autoFocus={autoFocus}
                                        disabled={disabled}
                                        autoComplete={'off'}
                                        type={type}
                                        onFocus={onFocus}
                                        onKeyPress={props.onKeyPress}
                                        multiline={props.multiline}
                                        maxRows={props.maxRows}
                                        as={props.textarea}
                                        onChange={(e) => {
                                            onChange && onChange(e.target.value.trimLeft());
                                            props.onChange && props.onChange(e);
                                            e.target.value ? setClassName('hasValue') : setClassName('');
                                        } }
                                        value={props.value || formMethod.watch(name)}
                                        inputProps={inputProps}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                                <>
                                                    <InputAdornment className='iconWrap' position="start">
                                                        {leftIcon &&
                                                            <i className={'icon icon- ' + leftIcon} />}
                                                    </InputAdornment>
                                                </>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end" className='iconWrap'>
                                                    {IconName &&
                                                        <IconButton onClick={onClick}>
                                                            {IconName}
                                                        </IconButton>}
                                                </InputAdornment>
                                            )
                                        }} />

                                }
                                <label className='requiredField none-requred'>{pageName ? hasError ? errors[name]?.message : requirePlaceHolder : requirePlaceHolder}</label>
                            </>
                        )}
                        name={name}
                        control={formMethod?.control}
                        rules={rules}
                    />
                </Box>
            </Box>
            {multiErrorFields.length > 0
                ? <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ messages }) => {
                        if (messages) {
                            const isMultipleError = Object.keys(messages).every((errKey) => multiErrorFields.filter((m) => m[errKey] !== undefined).length > 0);
                            if (isMultipleError) {
                                const arr = [];
                                for (const fieldError of multiErrorFields) {
                                    const key = Object.keys(fieldError)[0];
                                    const success = Object.keys(messages).includes(key);
                                    arr.push(
                                        <Box component='div' className={success ? 'red' : 'green'} >
                                            <Box component='span' key={key}><Box component='span' className={success ? errorIcon : successIcon}></Box>{fieldError[key]}</Box>
                                        </Box>
                                    );
                                }
                                return <Box component='div' className="errorMsg show passwordcustom">{arr}</Box>;
                            } else {
                                return <Box component='div' className='errorMsg show'><Box component='span' className=''></Box>{errors[name]?.message}</Box>;
                            }
                        } else {
                            return <Box component='div' className='errorMsg'></Box>;
                        }
                    }}
                />
                : (hasError ? <Box component='div' className='errorMsg show'>{errors[name]?.message}</Box> : <Box className='errorMsg'></Box>)}
        </Box>
    );
};

CustomTextField.defaultProps = {
    multiErrorFields: []
};

export default CustomTextField;
