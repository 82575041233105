const Constant = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    REGEX: {
        EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        SPECIALCHARACTERS: /[!@#$%^&*)(+=._-]/g,
        ALPHANUMERICSPEICALCHARS: /[a-zA-Z0-9@#$%^&*()_+]/i,
        NAME: /^[a-zA-Z\-\s\u00C0-\u00FF]*$/,
        ALPHANUMERIC: /^[ a-zA-Z0-9.'\-,]+$/i,
        NUMBER: /[0-9]/,
        UPPERCASELOWERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
        DATE: /^\d{2}-[0-9]{2}-\d{4}$/,
        BRANDNAME: /^[a-zA-Z0-9- ]*$/i,
        // eslint-disable-next-line no-useless-escape
        URL: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
        // eslint-disable-next-line no-useless-escape
        VIDEOURL: /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
        // ALPHANUMERICNOSPACE: /^[a-zA-Z0-9.\-,]+$/i
        // DATEVALIDATE: /^[ a-zA-Z0-9.\-,]+$/
    },
    API: {
        USERVERIFY: 'auth/user-verify',
        RESETPASSWORD: 'auth/reset-password'
    },
    IMAGESURL: {
        LOGO: 'https://res.cloudinary.com/dygllydcg/image/upload/v1689830201/MouthMap/Web/Static-images/logo.svg',
        LOGO_TEXT: 'https://res.cloudinary.com/dygllydcg/image/upload/v1689830228/MouthMap/Web/Static-images/vertical-logo.svg'
    },
    ACCESS_TOKEN_KEY: 'access_token',
    REFRESH_TOKEN_KEY: 'refresh_token'

};
export default Constant;
