import { SET_REMEMBER_ME, SET_USER } from '../actions/authAction';

const initState = {
    rememberData: {},
    user: {}
};

const authReduer = (state = initState, action) => {
    if (action.type === SET_REMEMBER_ME) {
        return {
            ...state,
            rememberData: action.payload
        };
    }
    if (action.type === SET_USER) {
        return {
            ...state,
            user: action.payload
        };
    }
    return state;
};

export default authReduer;
