import React from 'react';
import Layout from './layout/Admin/AdminLayout';
import ManageProducts from './pages/ManageProducts/ManageProducts';
import PatientsEducation from './pages/PatientsEducation/PatientsEducation';
import UserManagerment from './pages/UserManagement/UserManagerment';
import UserManagementDetails from './pages/UserManagementDetails/UserManagementDetails';
import ViewEarnings from './pages/ViewEarnings/ViewEarnings';
import ViewOrderDetails from './pages/ViewOrderDetails/ViewOrderDetails';
import ViewOrder from './pages/ViewOrder/ViewOrder';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ManageDeliveries from './pages/ManageDeliveries/ManageDeliveries';
import Login from './pages/Admin/Auth/Login/Login';
import ForgotPassword from './pages/Admin/Auth//ForgotPassword/ForgotPassword';
import ManagerPromoCode from './pages/Admin/ManagerPromoCode/ManagerPromoCode';
import Home from './pages/Admin/Home/Home';
import ResetPassword from './pages/Admin/Auth/ResetPassword/ResetPassword';
import VerificationOtp from './pages/Admin/Auth/VerificationOtp/VerificationOtp';

// const Signup = lazy(() => import(
//     /* webpackChunkName: 'signup' */ /* webpackPreload: true */ './pages/Admin/Auth/Signup/Signup'
// ));

// const Login = lazy(() => import(
//     /* webpackChunkName: 'login' */ /* webpackPreload: true */ './pages/Admin/Auth/Login/Login'
// ));

// const ForgotPassword = lazy(() => import(
//     /* webpackChunkName: 'forgotpassword' */ /* webpackPreload: true */ './pages/Admin/Auth/ForgotPassword/ForgotPassword'
// ));

// const ResetPassword = lazy(() => import(
//     /* webpackChunkName: 'resetpassword' */ /* webpackPreload: true */ './pages/Admin/Auth/ResetPassword/ResetPassword'
// ));

// const Home = lazy(() => import(
//     /* webpackChunkName: 'home' */ /* webpackPreload: true */ './pages/Admin/Home/Home'
// ));
// const ManagerPromoCode = lazy(() => import(
//     /* webpackChunkName: 'home' */ /* webpackPreload: true */ './pages/Admin/ManagerPromoCode/ManagerPromoCode'
// ));

const routes = [
    {
        path: '',
        component: <Login />,
        authenticate: false,
        exact: true
    },
    {
        path: '/login',
        component: <Login />,
        authenticate: false,
        exact: true
    },
    {
        path: '/forgotpassword',
        component: <ForgotPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: '/verificationotp',
        component: <VerificationOtp />,
        authenticate: false,
        exact: true
    },
    {
        path: '/resetpassword',
        component: <ResetPassword />,
        authenticate: false,
        exact: true
    },
    {
        path: '/*',
        component: <Layout />,
        authenticate: false,
        exact: true
    }
];

export const AdminLayoutRoute = [
    {
        path: '/managerpromocode',
        component: <ManagerPromoCode />,
        authenticate: true,
        exact: true
    },
    {
        path: '/home',
        component: <Home />,
        authenticate: true,
        exact: true
    },
    {
        path: '/usermanagerment/usermanagementdetails',
        component: <UserManagementDetails />,
        authenticate: true,
        exact: true
    },
    {
        path: '/manage-products',
        component: <ManageProducts />,
        authenticate: true,
        exact: true
    },
    {
        path: '/patients-education',
        component: <PatientsEducation />,
        authenticate: true,
        exact: true
    },
    {
        path: '/orders',
        component: <ViewOrder />,
        authenticate: true,
        exact: true
    },
    {
        path: '/orders/orderdetails',
        component: <ViewOrderDetails />,
        authenticate: true,
        exact: true
    },
    {
        path: '/usermanagerment',
        component: <UserManagerment />,
        authenticate: true,
        exact: true
    },
    {
        path: '/view-earnings',
        component: <ViewEarnings />,
        authenticate: true,
        exact: true
    },
    {
        path: '/manage-deliveries',
        component: <ManageDeliveries />,
        authenticate: true,
        exact: true
    },
    {
        path: '/*',
        component: <PageNotFound />,
        authenticate: true,
        exact: true
    }
];

export default routes;
