import React from 'react';

const Loader = () => {
    return (
        <div className='loader'>
            <img src='https://res.cloudinary.com/dygllydcg/image/upload/v1689829708/MouthMap/Web/Static-images/spinner.gif' alt="" />
        </div>
    );
};

export default Loader;
