export const SET_REMEMBER_ME = 'SET_REMEMBER_ME';
export const SET_USER = 'SET_USER';

export function setRememberMe (payload) {
    return dispatch => {
        dispatch({
            type: SET_REMEMBER_ME,
            payload
        });
    };
}
export function setUser (payload) {
    return dispatch => {
        dispatch({
            type: SET_USER,
            payload
        });
    };
}
