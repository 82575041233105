/* eslint-disable  */

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableBody,
  Grid,
  Stack,
} from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import "slick-carousel/slick/slick-theme.css";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "slick-carousel/slick/slick.css";
import "rc-slider/assets/index.css";
import RangeSlider from "rc-slider";
import Slider from "react-slick";
import moment from "moment";

import HookForm from "../../components/HookForm/HookForm";
import AddModal from "../../components/AddModal/AddModal";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

import instance from "../../api/axios";
import useWindowDimension from "../../hooks/useWindowDimension";
import "./ManageDeliveries.scss";
import { MetaTags } from "react-meta-tags";

// var datess = new Date();
// var last = new Date(datess.getTime() - (days * 24 * 60 * 60 * 1000));
// const getTenPreviousDate = (days) => {
//     console.log('lastDate', last);
//     // var month = last.getMonth() + 1;
//     var newPreviousDate = `${last.getDate()} ${last.toLocaleString('default', { month: 'long' })}, ${last.getFullYear()}`;
//     return newPreviousDate;
//     // var day = last.getDate();
//     // var year = last.getFullYear();
// };
const ManageDeliveries = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
  };
  const statusDropdown = {
    selectDropdown: {
      value: "selectDropdown",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const marks = {
    0: "Pending",
    50: "On The Way",
    100: "Delivered",
  };
  const name = "";
  const lastName = "";
  console.log(name);
  console.log(lastName);
  const dimensions = useWindowDimension();
  const [headerHeight, setHeaderHeight] = useState(0);
  const [mainTitleHeight, setMainTitleHeight] = useState(0);
  const [dateHandleBlockHeight, setDateHandleBlockHeight] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);
  console.log("datestatus", dateStatus);
  // const [monthData, setMonthData] = useState('');
  const [orderStatusPopup, setOrderStatusPopup] = useState({
    value: "",
    type: false,
  });
  const [dataId, setDataID] = useState([]);
  console.log("dataId->", dataId);
  const [deliveriesData, setDeliveriesData] = useState([]);
  const [selectStatus, setSelectStatus] = useState("");
  const [dateSlider, setDateSlider] = useState(new Date());
  const [uniquePostalCode, setUniquePostalCode] = useState([]);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(new Date());
  const [shippingPostalCode, setShippingPostalCode] = useState();
  const [sliderValue, setSliderValue] = useState(0);
  const [weeklyData, setWeeklyData] = useState([]);
  const [checkAllFilter, setCheckAllFilter] = useState(false);
  const [defualtYear, setDefualtYear] = useState(false);
  const [selectDateTab, checkSelectDateTab] = useState();
  const [sevenDayData, setSevenDayData] = useState([]);
  console.log("dateSlider", dateSlider);
  console.log(selectStatus, "selectStatus=>");

  useEffect(async () => {
    await instance
      .get("/manageDeliveries/orderCount")
      .then((res) => {
        setSevenDayData(res.payload.savenDayData);
        setWeeklyData(res.payload.savenDayData);
      })
      .catch((error) => {
        console.log("orderCount", error);
      });
  }, []);

  useEffect(() => {
    deliveryListApi(selectedDeliveryDate, shippingPostalCode);
    setDefualtYear(true);
  }, [selectStatus]);

  /**
   * Filters the deliveriesData array to only include unique objects based on their shipping address postal code.
   * @param {Array} deliveriesData - An array of delivery data objects.
   * @returns None
   */
  useEffect(() => {
    const uniqueData = deliveriesData.filter((obj, index, arr) => {
      return (
        arr
          .map((mapObj) => mapObj.shippingId.sPostCode)
          .indexOf(obj.shippingId.sPostCode) === index
      );
    });
    setUniquePostalCode(uniqueData);
  }, [deliveriesData]);

  const statusDate = [
    {
      id: 1,
      value: "All",
      value1: "all",
      title: "All",
    },
    {
      id: 2,
      value: "Pending",
      value1: "IN_ORDER",
      title: "Pending",
    },
    {
      id: 3,
      value: "On The Way",
      value1: "ON_THE_WAY",
      title: "On The Way",
    },
    {
      id: 4,
      value: "Delivered",
      value1: "COMPLETE",
      title: "Delivered",
    },
  ];

  /**
   * Sets the height of various elements on the page and updates the state accordingly.
   * @returns None
   */
  useEffect(() => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setDateHandleBlockHeight(
      document.getElementsByClassName("dateHandleBlock")[0]?.offsetHeight
    );
  }, []);
  const handleResize = () => {
    setMainTitleHeight(
      document.getElementsByClassName("mainTitle")[0]?.offsetHeight
    );
    setHeaderHeight(
      document.getElementsByClassName("headerMain")[0]?.offsetHeight
    );
    setDateHandleBlockHeight(
      document.getElementsByClassName("dateHandleBlock")[0]?.offsetHeight
    );
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);
  const onChangeMyDeliveryDate = (dates) => {
    setDeliveryDate(dates);
    console.log("dates", dates);
  };
  const onStatusFormSubmit = (data) => {
    console.log(data);
  };
  useEffect(() => { }, [sliderValue]);

  /**
   * Makes an API call to retrieve a list of deliveries based on the selected delivery date and postcode.
   * @param {string} selectedDeliveryDate - The selected delivery date.
   * @param {string} postcode - The postcode to filter the deliveries by.
   * @returns None
   */
  const deliveryListApi = async (selectedDeliveryDate, postcode) => {
    let params = "";
    if (selectStatus && selectedDeliveryDate && postcode) {
      params = `?date=${selectedDeliveryDate}&status=${selectStatus}&Postcode=${postcode}`;
    } else if (selectStatus && selectedDeliveryDate) {
      params = `?date=${selectedDeliveryDate}&status=${selectStatus}`;
    } else if (selectStatus && postcode) {
      params = `?status=${selectStatus}&Postcode=${postcode}`;
    } else if (selectedDeliveryDate && postcode) {
      params = `?date=${selectedDeliveryDate}&Postcode=${postcode}`;
    } else if (selectStatus) {
      params = `?status=${selectStatus}`;
    } else if (selectedDeliveryDate) {
      params = `?date=${selectedDeliveryDate}`;
    } else if (postcode) {
      params = `?Postcode=${postcode}`;
    }

    await instance
      .get(`/manageDeliveries/orderDeliveries${params}`)
      .then((res) => {
        setDeliveriesData(res.payload.deliveriesData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /**
   * Changes the delivery status of an order.
   * @param {string} orderId - The ID of the order to change the delivery status of.
   * @param {string} orderStatus - The new status to set for the order.
   * @returns None
   */
  const changeDeliveryStatus = async (orderId, orderStatus) => {
    const params = {
      oCartId: orderId,
      sStatus: orderStatus,
    };
    await instance
      .put("/manageDeliveries/orderDeliveriesStatus", params)
      .then((res) => {
        // setTimeout(() => {
        //   showToast({
        //     message: "delivery status updated",
        //     type: "success",
        //   });
        // }, 1000);
      })
      .catch((e) => {
        console.log("changeDeliveryStatus", e);
      });
  };

  const changeWeeklyDate = async (date) => {
    const params = `?date=${date}`;
    await instance
      .get(`/manageDeliveries/orderCount${params}`)
      .then((res) => {
        setWeeklyData(res.payload.savenDayData);
      })
      .catch((error) => {
        console.log("orderCount", error);
      });
  };

  const resetDeliveries = async () => {
    setShippingPostalCode();
    setSelectStatus(selectStatus);
    deliveryListApi(new Date());
    setDeliveryDate();
    setCheckAllFilter(false);
    setDefualtYear(true);
    checkSelectDateTab();
    setWeeklyData(sevenDayData);
  };
  return (
    <>
      <MetaTags>
        <title>
          {" "}
          Manage Deliveries | Mouthmap - Your Route To A Healthy Mouth{" "}
        </title>
        <meta
          name="description"
          content="View and manage the latest deliveries, see all shipment history, filter by date, and lots more via the deliveries page."
        />
        <meta
          property="og:title"
          content=" Manage Deliveries | Mouthmap - Your Route To A Healthy Mouth"
        />
        <meta
          property="og:description"
          content="View and manage the latest deliveries, see all shipment history, filter by date, and lots more via the deliveries page."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png"
        />
      </MetaTags>

      <Box
        className="manageDeliveriesWrap"
        style={{
          marginTop: headerHeight,
          height: dimensions.height - headerHeight,
        }}
      >
        <Container>
          <Typography variant="h1" className="mainTitle">
            Manage Deliveries
          </Typography>
          <Box className="dateHandleBlock">
            <Box className="dateSection" display="flex">
              <Typography component="span" className="dateTitle">
                Select Date
              </Typography>
              <Box className="rightWrap" display="flex">
                <Typography className="calText" component="span">
                  Go to Date{" "}
                </Typography>
                <Box>
                  <button
                    type="button"
                    className="custom-date-input icon icon-calender"
                    onClick={() => setIsOpen(!isOpen)}
                  ></button>
                  {isOpen && (
                    <Box className="customCalendar custom-arrows">
                      <DatePicker
                        selected={deliveryDate}
                        onChange={(e) => {
                          onChangeMyDeliveryDate(e);
                        }}
                        popperClassName="dateFilter monthPicker"
                        maxDate={new Date()}
                        dateFormatCalendar="MMM yyyy"
                        isClearable={false}
                        inline
                      />
                      <Button
                        className="applyBtn"
                        onClick={(e) => {
                          checkSelectDateTab(deliveryDate);

                          setIsOpen(false);
                          deliveryListApi(deliveryDate, shippingPostalCode);
                          setDateSlider(deliveryDate);
                          changeWeeklyDate(deliveryDate);
                          setCheckAllFilter(true);
                        }}
                      >
                        Apply
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="dateSlider">
              <Slider {...settings}>
                {weeklyData.map((value, index) => {
                  return (
                    <Box
                      key={index}
                      className="mainWrap"
                      onClick={() => {
                        checkSelectDateTab(value?.date);
                        setCheckAllFilter(true);
                        setDefualtYear(false);
                        deliveryListApi(
                          new Date(
                            dateSlider.getTime() - index * 24 * 60 * 60 * 1000
                          ),
                          ""
                        );
                        setSelectedDeliveryDate(
                          new Date(
                            dateSlider.getTime() - index * 24 * 60 * 60 * 1000
                          )
                        );
                      }}
                    >
                      <Box
                        className={
                          moment(value?.date).format("DD MMMM YYYY") ===
                            moment(selectDateTab).format("DD MMMM YYYY")
                            ? "dateBoxWrap active"
                            : "dateBoxWrap"
                        }
                      >
                        <Typography component="div" className="date">
                          {value?.date}
                        </Typography>
                        <Typography component="div" className="orders">
                          Orders: {value?.orders}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </Slider>
            </Box>
          </Box>
          <Box className="deliveriesBottom">
            <Box className="postalWrap">
              <Typography component="div" className="postalTitle">
                Select Post code{" "}
                <a
                  disabled={!checkAllFilter}
                  className={
                    !checkAllFilter ? "allFilterEnabled" : "allFilterDisabled"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => resetDeliveries()}
                >
                  {" "}
                  Reset filters{" "}
                </a>
              </Typography>
              <Box
                className="leftScrollStyle"
                style={{
                  height:
                    dimensions.height -
                    headerHeight -
                    mainTitleHeight -
                    dateHandleBlockHeight -
                    82,
                }}
              >
                {uniquePostalCode?.length > 0 ? (
                  uniquePostalCode.map((item, index) => {
                    return (
                      <Box
                        className="postCodeWrap active"
                        display="flex"
                        onClick={() => {
                          deliveryListApi(
                            deliveryDate,
                            item?.shippingId?.sPostCode
                          );
                          setShippingPostalCode(item?.shippingId?.sPostCode);
                          setCheckAllFilter(true);
                        }}
                      >
                        <Typography
                          component="i"
                          className="icon icon-pin-code"
                        ></Typography>
                        <Box className="textWrap">
                          Postcode
                          <Typography>{item?.shippingId?.sPostCode}</Typography>
                        </Box>
                        <Typography
                          component="span"
                          className="icon icon-angle-right-b"
                        ></Typography>
                      </Box>
                    );
                  })
                ) : (
                  <Box className="postCodeWrap active" display="flex">
                    <Typography
                      component="i"
                      className="icon icon-pin-code"
                    ></Typography>
                    <Box className="textWrap">
                      Postcode
                      <Typography>No Postcode Found</Typography>
                    </Box>
                    <Typography
                      component="span"
                      className="icon icon-angle-right-b"
                    ></Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box className="orderDataWrap">
              <Box className="innerContainer" display="flex">
                <Box className="dropDownWrap">
                  <Typography className="dropText" component="span">
                    Status
                  </Typography>
                  <HookForm
                    init={(form) => setDateStatus(dateStatus)}
                    onSubmit={(e) => {
                      onStatusFormSubmit(e);
                    }}
                  >
                    {(formMethod) => {
                      return (
                        <>
                          <Controller
                            render={({
                              field: { onChange, SelectedValue },
                            }) => (
                              <CustomDropdown
                                withCheckBox={false}
                                multiple={false}
                                mapData={statusDate}
                                onChange={(e) => {
                                  console.log(e);
                                  const data = statusDate.filter(
                                    (a) => a.value === e.target.value
                                  );
                                  setSelectStatus(data[0].value1);
                                  setSelectedDeliveryDate("");
                                  setShippingPostalCode("");
                                  setCheckAllFilter(true);
                                }}
                                fieldPlaceHodler="Select"
                              />
                            )}
                            control={formMethod.control}
                            name={statusDropdown.selectDropdown.value}
                            rules={statusDropdown.selectDropdown.validate}
                          // defaultValue = {formMethod.watch(addproduct.brandData.sBrandName)}
                          />
                        </>
                      );
                    }}
                  </HookForm>
                </Box>
              </Box>
              <Scrollbars
                className="deliveriesDataTable"
                style={{
                  height:
                    dimensions.height -
                    headerHeight -
                    mainTitleHeight -
                    dateHandleBlockHeight -
                    82,
                }}
              >
                <Table stickyHeader className="table">
                  <TableHead className="tableHeader">
                    <TableRow className="tableHeaderRow">
                      <TableCell className="tableHeaderColumn desktopItem">
                        Order ID
                      </TableCell>
                      <TableCell className="tableHeaderColumn">User</TableCell>
                      <TableCell className="tableHeaderColumn desktopItem">
                        Shipping Address
                      </TableCell>
                      <TableCell className="tableHeaderColumn">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="tableBody">
                    {deliveriesData.length > 0 ? (
                      deliveriesData.map((item, index) => {
                        const houseNo = item?.shippingId?.sHouseNo
                        const addressLine1 = item?.shippingId?.sAddressLine1
                        const sAddressLine2 = item?.shippingId?.sAddressLine2
                        const cityName = item?.shippingId?.sCityName?.value
                        const states = item?.shippingId?.stateName?.value;
                        const postcode = item?.shippingId?.sPostCode
                        const stateWithPost = `${states ? states + "-" + postcode : "-" + postcode}`;

                        const country = item?.shippingId?.sCountryName?.value

                        return (
                          <TableRow
                            className={
                              item.sStatus === "Delivered"
                                ? "delivered tableBodyRow"
                                : "tableBodyRow"
                            }
                            key={index}
                            onClick={() => {
                              // if (dataId.sStatus === 'IN_ORDER') {
                              //     setSliderValue(0);
                              // } else if (dataId.sStatus === 'ON_THE_WAY') {
                              //     setSliderValue(50);
                              // } else if (dataId.sStatus === 'COMPLETE') {
                              //     setSliderValue(100);
                              // }
                              setDataID(item);
                              setOrderStatusPopup({
                                value: item.sStatus,
                                type: true,
                              });
                            }}
                          >
                            <TableCell className="tableBodyColumn desktopItem">
                              <Typography
                                component="span"
                                className="stateChange"
                              >
                                {"#" + item.sOrderNo}
                              </Typography>
                            </TableCell>
                            <TableCell className="tableBodyColumn responsiveCss">
                              <Box
                                component="span"
                                display="flex"
                                className="desktopWrap"
                              >
                                <Typography
                                  className="stateChange nameImg"
                                  component="span"
                                >
                                  {item.userData.sFirstName?.charAt(0) +
                                    item.userData.sLastName?.charAt(0)}
                                </Typography>
                                <Typography
                                  component="span"
                                  className="stateChange desktopItem username"
                                >
                                  {item.userData.sFirstName +
                                    " " +
                                    item.userData.sLastName}
                                </Typography>
                              </Box>
                              <Box className="responsiveItem" component="span">
                                <Typography
                                  component="div"
                                  className="stateChange"
                                >
                                  {"#" + item.sOrderNo}
                                </Typography>
                                <Typography
                                  component="div"
                                  className="stateChange username"
                                >
                                  {item.userData.sFirstName +
                                    item.userData.sLastName}
                                </Typography>
                                <Typography
                                  component="div"
                                  className="stateChange"
                                >
                                  {`${item?.shippingId?.sHouseNo
                                    ? item?.shippingId?.sHouseNo
                                    : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sAddressLine1
                                      ? item
                                        ?.shippingId
                                        ?.sAddressLine1
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sAddressLine2
                                      ? item
                                        ?.shippingId
                                        ?.sAddressLine2
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sCityName
                                      ?.value
                                      ? item
                                        ?.shippingId
                                        ?.sCityName
                                        ?.value
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.stateName
                                      ?.value
                                      ? item
                                        ?.shippingId
                                        ?.stateName
                                        ?.value
                                      : ""
                                    }- ${item?.shippingId?.sPostCode
                                      ? item?.shippingId?.sPostCode
                                      : ""
                                    }`}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell className="tableBodyColumn desktopItem">
                              <Typography variant="body2">
                                <Typography
                                  component="span"
                                  className="stateChange"
                                >
                                  {`${item?.shippingId?.sHouseNo
                                    ? item?.shippingId?.sHouseNo + ", "
                                    : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sAddressLine1
                                      ? item
                                        ?.shippingId
                                        ?.sAddressLine1 +
                                      ", "
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sAddressLine2
                                      ? item
                                        ?.shippingId
                                        ?.sAddressLine2 +
                                      ", "
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sCityName
                                      ?.value
                                      ? item
                                        ?.shippingId
                                        ?.sCityName
                                        ?.value +
                                      ", "
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.stateName
                                      ?.value
                                      ? item
                                        ?.shippingId
                                        ?.stateName
                                        ?.value

                                      : ""
                                    }- ${item?.shippingId?.sPostCode
                                      ? item?.shippingId?.sPostCode
                                      : ""
                                    }
                                                                ${item
                                      ?.shippingId
                                      ?.sCountryName
                                      ?.value
                                      ? ", " +
                                      item
                                        ?.shippingId
                                        ?.sCountryName
                                        ?.value
                                      : ""
                                    }`}
                                </Typography>
                                <Typography
                                  className="copyHover"
                                  component="span"
                                  onClick={(e) => {
                                    e.stopPropagation(e);

                                    navigator.clipboard.writeText(`${houseNo ? houseNo : addressLine1},
${addressLine1 ? addressLine1 : sAddressLine2 ? sAddressLine2 : cityName},
${sAddressLine2 ? sAddressLine2 : cityName ? cityName : stateWithPost},
${cityName ? cityName : stateWithPost ? stateWithPost : country},
${stateWithPost ? stateWithPost : country},
${country ? country : ""}`
                                    );
                                  }}


                                >
                                  <Typography
                                    component="span"
                                    className="icon icon-copy"
                                  ></Typography>
                                  <Typography component="span">Copy</Typography>
                                </Typography>
                              </Typography>
                            </TableCell>
                            {/* <TableCell className="tableBodyColumn desktopItem">
                              <Typography variant="body2">
                                <Typography
                                  component="span"
                                  className="stateChange"
                                >
                                  {[
                                    item.shippingId.sHouseNo,
                                    item.shippingId.sAddressLine1,
                                    item.shippingId.sAddressLine2,
                                    item.shippingId.sCityName?.value,
                                    item.shippingId.stateName?.value,
                                    item.shippingId.sPostCode,
                                    dataId?.shippingId?.sCountryName?.country,
                                  ]
                                    .filter(Boolean) // Remove undefined or empty fields
                                    .join(", ")}
                                </Typography>
                                <Typography
                                  className="copyHover"
                                  component="span"
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    const formattedAddress = [
                                      item.shippingId.sHouseNo,
                                      item.shippingId.sAddressLine1,
                                      item.shippingId.sAddressLine2,
                                      item.shippingId.sCityName?.value,
                                      item.shippingId.stateName?.value,
                                      item.shippingId.sPostCode,
                                      dataId?.shippingId?.sCountryName?.country,
                                    ]
                                      .filter(Boolean) // Remove undefined or empty fields
                                      .join(", ");

                                    navigator.clipboard.writeText(
                                      formattedAddress
                                    );
                                  }}
                                >
                                  <Typography
                                    component="span"
                                    className="icon icon-copy"
                                  ></Typography>
                                  <Typography component="span">Copy</Typography>
                                </Typography>
                              </Typography>
                            </TableCell> */}

                            <TableCell className="tableBodyColumn">
                              <Typography
                                className={
                                  item.sStatus === "IN_ORDER"
                                    ? "red status"
                                    : item.sStatus === "ON_THE_WAY"
                                      ? "green status"
                                      : item.sStatus === "COMPLETE"
                                        ? "green status"
                                        : item.sStatus === "Upcoming"
                                          ? "blue status"
                                          : "status"
                                }
                              >
                                {item.sStatus === "IN_ORDER"
                                  ? "Pending"
                                  : item.sStatus === "ON_THE_WAY"
                                    ? "On The Way"
                                    : item.sStatus === "COMPLETE"
                                      ? "Delivered"
                                      : ""}
                              </Typography>{" "}
                              <Typography
                                component="span"
                                className="icon icon-angle-right-b"
                              ></Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan="5">
                          <Box className="no-data">No Data Found</Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Scrollbars>
            </Box>
          </Box>
        </Container>
        <AddModal
          open={orderStatusPopup.type}
          close={() => setOrderStatusPopup({ value: "", type: false })}
        >
          {/* { console.log('nameee2232332332', name, lastName)} */}
          <Box sx={style} className="addModalWrap orderStatusPopup">
            <Typography id="modal-modal-title" variant="h5">
              Order Details
              <Typography
                component="span"
                className="icon-close clearIcon"
                onClick={() => {
                  setOrderStatusPopup({ value: "", type: false });
                }}
              ></Typography>
            </Typography>
            <Box className="orderDetails" display="flex">
              <Typography component="span">
                <Typography className="stateChange nameImg" component="span">
                  {dataId?.userData?.sFirstName.charAt(0) +
                    dataId?.userData?.sLastName.charAt(0)}
                </Typography>
                <Typography component="span" className="stateChange userName">
                  {dataId?.userData?.sFirstName +
                    " " +
                    dataId?.userData?.sLastName}
                </Typography>
              </Typography>
              <Typography component="span" className="orderId">
                Order Id: {"#" + dataId.sOrderNo}
              </Typography>
              {/* const today = new Date(item.createdAt); */}
              <Typography component="span" className="orderDate">
                Order Date:{" "}
                <Typography component="span">
                  {dataId?.orderPlacedDate !== undefined
                    ? moment(dataId?.orderPlacedDate).format("DD MMM YYYY")
                    : "-"}
                </Typography>
              </Typography>
              <Typography component="span" className="orderStatus">
                Status:{" "}
                <Typography
                  component="span"
                  className={
                    dataId.sStatus === "IN_ORDER"
                      ? "red status"
                      : dataId.sStatus === "ON_THE_WAY"
                        ? "green status"
                        : dataId.sStatus === "COMPLETE"
                          ? "green status"
                          : dataId.sStatus === "Unsubscribed"
                            ? "red status"
                            : dataId.sStatus === "Upcoming"
                              ? "blue status"
                              : "status"
                  }
                >
                  {dataId.sStatus === "IN_ORDER"
                    ? "Pending"
                    : dataId.sStatus === "ON_THE_WAY"
                      ? "On The Way"
                      : dataId.sStatus === "COMPLETE"
                        ? "Delivered"
                        : ""}
                </Typography>
              </Typography>
            </Box>
            <Grid container spacing={2} className="addressWrap" display="flex">
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Shipping Address:
                  </Typography>
                  {/* <Typography variant='body2'>{dataId?.userData?.sFirstName.charAt(0) + dataId?.userData?.sLastName.charAt(0)}</Typography> */}
                  <Typography variant="body2">
                    {`${dataId?.shippingId?.sHouseNo
                      ? dataId?.shippingId?.sHouseNo + ", "
                      : ""
                      } 
                                        ${dataId?.shippingId?.sAddressLine1
                        ? dataId?.shippingId?.sAddressLine1
                        : ""
                      }, ${dataId?.shippingId?.sAddressLine2
                        ? dataId?.shippingId?.sAddressLine2
                        : ""
                      }
                                    ${dataId?.shippingId?.sCityName.value
                        ? ", " +
                        dataId?.shippingId?.sCityName.value
                        : ""
                      }
                                     ${dataId?.shippingId?.stateName?.value
                        ? ", " +
                        dataId?.shippingId?.stateName?.value
                        : ""
                      }- ${dataId?.shippingId?.sPostCode}
                                    ${dataId?.shippingId?.sCountryName?.country
                        ? ", " +
                        dataId?.shippingId?.sCountryName
                          ?.country
                        : ""
                      }`}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack className="addressDetails">
                  <Typography
                    variant="subtitle2"
                    component="div"
                    className="title"
                  >
                    Billing Address:
                  </Typography>
                  {/* <Typography variant='body2'>{dataId.userData?.sAddress[0].fullName}</Typography> */}
                  <Typography variant="body2">
                    {`${dataId?.billingId?.sHouseNo
                      ? dataId?.billingId?.sHouseNo + ", "
                      : ""
                      }
                                    ${dataId?.billingId?.sAddressLine1
                        ? dataId?.billingId?.sAddressLine1 +
                        ", "
                        : ""
                      }
                                    ${dataId?.billingId?.sAddressLine2
                        ? dataId?.billingId?.sAddressLine2 +
                        ", "
                        : ""
                      }
                                    ${dataId?.billingId?.sCityName.value
                        ? dataId?.billingId?.sCityName.value +
                        ", "
                        : ""
                      }
                                    ${dataId?.billingId?.stateName?.value
                        ? dataId?.billingId?.stateName?.value +
                        ", "
                        : ""
                      }- ${dataId?.billingId?.sPostCode
                        ? dataId?.billingId?.sPostCode
                        : ""
                      }
                                    ${dataId?.billingId?.sCountryName?.country
                        ? ", " +
                        dataId?.billingId?.sCountryName
                          ?.country
                        : ""
                      }`}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Scrollbars className="userDataTable" style={{ height: "233px" }}>
              <Table stickyHeader className="table">
                <TableHead className="tableHeader">
                  <TableRow className="tableHeaderRow">
                    <TableCell className="tableHeaderColumn">
                      Product Name
                    </TableCell>
                    <TableCell className="tableHeaderColumn">Brand</TableCell>
                    <TableCell className="tableHeaderColumn">
                      Quantity
                    </TableCell>
                    <TableCell className="tableHeaderColumn">
                      Subscription ID
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableBody">
                  {dataId?.products?.map((item, index) => {
                    return (
                      <TableRow className="tableHeaderRow" key={index}>
                        <TableCell className="tableHeaderColumn">
                          <Box className="productName">
                            <img
                              src={
                                process.env.REACT_APP_AWS_BASE_URL +
                                item.prodctData?.sProductImage[0]
                              }
                              width="47"
                              height="47"
                              alt={"productimage" + item.index}
                            />
                            <Typography className='brushColor' style={{ backgroundColor: item?.prodctData?.color ? item?.prodctData?.color : 'black' }}></Typography>
                            <Typography>{item?.prodctData?.sName}</Typography>
                            <Typography className='productSize'>{item?.prodctData?.size ? item?.prodctData?.size : '-'}</Typography>
                          </Box>
                        </TableCell>
                        <TableCell className="tableHeaderColumn">
                          {item?.prodctData?.brandData?.sBrandName}
                        </TableCell>
                        <TableCell className="tableHeaderColumn">
                          {item?.sQuantity}
                        </TableCell>
                        {/* {item?.prodctData?.sMonthly_subScription ? <TableCell className='tableHeaderColumn'>{item.subscriptionid}</TableCell> : ''} */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Scrollbars>

            {dataId.sStatus !== "Upcoming" && (
              <Box className="updateStatus">
                <Typography
                  variant="subtitle2"
                  component="div"
                  className="title"
                >
                  Update Status
                </Typography>
                <RangeSlider
                  range
                  marks={marks}
                  step={50}
                  // value={50}
                  // railStyle={{ backgroundColor: 'red' }}
                  // trackStyle={{ backgroundColor: 'blue' }}
                  // value={sliderValue}
                  // sliderValue
                  // SelectedValue
                  // ariaValueTextFormatterForHandle
                  pushable={true}
                  disabled={dataId.sStatus === "COMPLETE"}
                  defaultValue={[
                    dataId.sStatus === "ON_THE_WAY" && 50,
                    dataId.sStatus === "COMPLETE" && 100,
                  ]}
                  SelectedValue={[
                    dataId.sStatus === "ON_THE_WAY" && 50,
                    dataId.sStatus === "COMPLETE" && 100,
                  ]}
                  onChange={(e) => {
                    // if (sliderValue < e) {
                    console.log("eeeeeeeeeee", e);
                    if ((e[0] === 0) & (e[1] === 0)) {
                      if (dataId.sStatus === "ON_THE_WAY") {
                        dataId.sStatus = "ON_THE_WAY";
                        setSliderValue(50);
                      } else {
                        dataId.sStatus = "IN_ORDER";
                        changeDeliveryStatus(dataId._id, dataId.sStatus);
                        console.log("Pendinggg");
                        setSliderValue(0);
                      }
                    } else if ((e[0] === 0) & (e[1] === 50)) {
                      dataId.sStatus = "ON_THE_WAY";
                      console.log("On the way");
                      changeDeliveryStatus(dataId._id, dataId.sStatus);
                      setSliderValue(50);
                    } else {
                      dataId.sStatus = "COMPLETE";
                      console.log("Deliverdddd");
                      changeDeliveryStatus(dataId._id, dataId.sStatus);
                      setSliderValue(100);
                    }
                    // }
                  }}
                />
              </Box>
            )}
          </Box>
        </AddModal>
      </Box>
    </>
  );
};

export default ManageDeliveries;
