import { React, useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Checkbox } from '@mui/material';

import './CustomCheckBox.scss';

const CustomCheckBox = (props) => {
    const { label, onCheckedChange, defaultChecked, disabled } = props;
    const [checked, setChecked] = useState(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const onChecked = (e) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };
    return (
        <>
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    disabled={disabled}
                    value={label}
                    control={<Checkbox className='chkBox' />}
                    label={label}
                    labelPlacement="end"
                    className='formInput'
                    onChange={onChecked}
                    onClick={props.onClick}
                    checked={checked}
                    defaultChecked={defaultChecked}
                />
            </FormGroup>
        </>
    );
};
export default CustomCheckBox;
