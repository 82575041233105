import React from 'react';
import { Box, Button, Modal, Typography } from '@material-ui/core';
import { multilanguage } from 'redux-multilanguage';

import './ModalBox.scss';

const ModalBox = (props) => {
    const { strings, onSubmit } = props;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={'smallPopup ' + props.className}
            >
                <Box sx={style} className="modalWrap">
                    <Typography component='span' className='icon-warning'></Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {props.title}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {props.warningText}
                    </Typography>
                    <Box className='btnWrap'>
                        <Button onClick={onSubmit}>{props.button1}</Button>
                        <Button onClick={props.close}>{strings.NO_CANCEL}</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default multilanguage(ModalBox);
