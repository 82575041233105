import React, { Fragment, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Link, Typography } from '@mui/material';
import { multilanguage } from 'redux-multilanguage';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import HookForm from '../../../../components/HookForm/HookForm';
import CustomTextField from '../../../../components/CustomTextField/CustomTextFeild';
import CustomLoadingButton from '../../../../components/CustomLoadingButton/CustomLoadingButton';

import instance from '../../../../api/axios';
import Constant from '../../../../utils/constant';
import { showToast } from '../../../../redux/actions/toastAction';
import useWindowDimension from '../../../../hooks/useWindowDimension';

import './ForgotPassword.scss';
import { MetaTags } from 'react-meta-tags';

const ForgotPassword = (props) => {
    const { strings } = props;
    const history = useNavigate();
    const dimensions = useWindowDimension();
    const [busy, setBusy] = useState(false);
    const [forgotPass, setForgotPass] = useState();
    const [changeEmailValue, setChangeEmailValue] = useState(0);
    const forgotPassForm = {
        email: {
            name: 'email',
            validate: {
                required: {
                    value: true,
                    message: strings.EMAIL_ADDRESS_IS_REQUIRED
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: strings.INVALID_EMAIL_ADDRESS
                }
            }
        }
    };
    const onFormSubmit = async (value) => {
        setBusy(true);
        console.log(value, 'value=>>>');
        const params = {
            sEmail: value.email,
            sUserRole: 'Admin'
        };
        await instance.post('/auth/forgetPassword', params)
            .then((res) => {
                props.showToast({
                    message: strings.LINK_TO_OTP,
                    type: 'success'
                });
                setTimeout(() => {
                    setBusy(false);
                    localStorage.setItem('verification-token', res.payload.tokens);
                    localStorage.setItem('VERIFY_ID', res.payload.user.id);
                    history('/verificationotp');
                }, 1000);
            }).catch((e) => {
                setTimeout(() => {
                    setBusy(false);
                    props.showToast({
                        message: e.response.data.message,
                        type: 'error'
                    });
                }, 3000);
            });
    };
    return (
        <>
            <MetaTags>
                <title> Forgot Password | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Reset your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:title" content=" Forgot Password | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Reset your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Grid container component="main" className="ForgotPasswordMain" style={{ height: dimensions.height + 'px' }}>
                <Grid item xs={6} className="signinLeft">
                    <Box className='centerContent' align='center'>
                        <img src={Constant.IMAGESURL.LOGO} className="logo" alt="Logo" width='336' height='287' />
                    </Box>
                </Grid>
                <Grid item xs={6} className="signinRight" style={{ height: dimensions.height + 'px' }}>
                    <img src={Constant.IMAGESURL.LOGO_TEXT} className="logo" alt="Logo2" width='167' height='92' />
                    <Box className='upperContent'>
                        <Typography className='title'>{strings.FORGOTPASSWORD}</Typography>
                        <Typography className='description'>{strings.PROVIDE_EMAIL_ADDRESS}</Typography>
                    </Box>
                    <HookForm
                        defaultForm={{}}
                        init={(form) => setForgotPass(forgotPass)}
                        onSubmit={(e) => onFormSubmit(e)}
                    >
                        {(formMethod) => {
                            return (
                                <Fragment>
                                    <Box className='formGroupField'>
                                        <CustomTextField
                                            placeholder={strings.EMAIL_ADDRESS}
                                            variant='outlined'
                                            autoFocus={false}
                                            type='text'
                                            formMethod={formMethod}
                                            rules={forgotPassForm.email.validate}
                                            name={forgotPassForm.email.name}
                                            classText="loginpage"
                                            errors={formMethod?.formState.errors}
                                            onChange={(e) => {
                                                setChangeEmailValue(e.target.value.length);
                                            }}
                                            leftIcon={changeEmailValue > 0 ? 'email iconFocusColor' : 'email'}
                                        />
                                    </Box>
                                    <Box className='submitBtn'>
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            btnTitle={strings.SUBMIT}
                                            loading={busy}
                                            disabled={!formMethod?.formState.isValid}
                                            className="submitButton"
                                        />
                                    </Box>
                                    <div className='backLink'>Back to <Link
                                        component={RouterLink}
                                        to='/login'
                                        className='redirectLinkText'
                                        color='text.primary'>{strings.SIGNIN}
                                    </Link></div>
                                </Fragment>
                            );
                        }}
                    </HookForm>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    showToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ForgotPassword));
