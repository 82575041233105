import React, { Fragment, useState } from 'react';
import { multilanguage } from 'redux-multilanguage';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import HookForm from '../../../../components/HookForm/HookForm';
import CustomTextField from '../../../../components/CustomTextField/CustomTextFeild';
import CustomLoadingButton from '../../../../components/CustomLoadingButton/CustomLoadingButton';

import instance from '../../../../api/axios';
import Constant from '../../../../utils/constant';
import { showToast } from '../../../../redux/actions/toastAction';
import useWindowDimension from '../../../../hooks/useWindowDimension';

import './ResetPassword.scss';
import { MetaTags } from 'react-meta-tags';

const ResetPassword = (props) => {
    const dimensions = useWindowDimension();
    const { strings } = props;
    const history = useNavigate();
    const [busy, setBusy] = useState(false);
    const [reset, setReset] = useState();
    const [showPass, setShowPass] = useState('password');
    const [showPass2, setShowPass2] = useState('password');

    const handleChangePassword = () => {
        if (showPass === 'password') {
            setShowPass('text');
        } else {
            setShowPass('password');
        }
    };
    const handleChangePassword2 = () => {
        if (showPass2 === 'password') {
            setShowPass2('text');
        } else {
            setShowPass2('password');
        }
    };
    const multiErrorFields = [
        { length: 'Minimum 8 characters' },
        { hasSpecialChar: 'Minimum 1 special character' },
        { hasNumbers: 'Minimum 1 numerical character' },
        { hasUpperLowercase: 'Minimum 1 uppercase & lowercase character' }
    ];
    const resetPassForm = {

        password: {
            name: 'password'
        },
        resetpassword: {
            name: 'resetpassword',
            validate: {
                required: {
                    value: true,
                    message: strings.NEWPASS_REQUIRED
                },
                validate: {
                    length: (value) =>
                        (value && value.length >= 8) || '',
                    hasSpecialChar: (value) =>
                        (value && value.match(Constant.REGEX.SPECIALCHARACTERS)) !== null,
                    hasNumbers: (value) =>
                        (value && value.match(Constant.REGEX.NUMBER)) !== null,
                    hasUpperLowercase: (value) =>
                        value && value.match(Constant.REGEX.UPPERCASELOWERCASE) !== null
                }
            }
        }
    };
    const onKeyDown = (e) => {
        e.keyCode === 13 && onFormSubmit();
    };

    const onFormSubmit = async (value) => {
        console.log('resetetetert', value);
        setBusy(true);
        await instance.post(`/auth/resetPassword/${localStorage.getItem('verification-token')}`, {
            sPassword: value.password
        }).then((res) => {
            setTimeout(() => {
                setBusy(false);
                props.showToast({
                    message: strings.SUCCESS_CHANGED,
                    type: 'success'
                });
                history('/login');
            }, 1000);
        }).catch((e) => {
        });
    };
    return (
        <>
            <MetaTags>
                <title>Reset Password | Mouthmap - Your Route To A Healthy Mouth</title>
                <meta name="description" content="Reset Password  your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:title" content="Reset Password | Mouthmap - Your Route To A Healthy Mouth" />
                <meta property="og:description" content="Reset Password  your Mouthmap Admin Portal password. If you're experiencing technical difficulties, please contact support." />
                <meta property="og:image" content="https://res.cloudinary.com/dygllydcg/image/upload/v1689838471/MouthMap/Web/Static-images/Adminlogin_homnqb.png" />
            </MetaTags>
            <Grid container component="main" className="ResetPasswordMain" style={{ height: dimensions.height + 'px' }}>
                <Grid item xs={6} className="signinLeft">
                    <Box className='centerContent' align='center'>
                        <img src={Constant.IMAGESURL.LOGO} className="logo" alt="Logo" width='336' height='287' />
                    </Box>
                </Grid>
                <Grid item xs={6} className="signinRight" style={{ height: dimensions.height + 'px' }}>
                    <img src={Constant.IMAGESURL.LOGO_TEXT} className="logo" alt="Logo2" width='167' height='92' />
                    <Box className='upperContent'>
                        <Typography className='title'>{strings.RESET_PASSWORD}</Typography>
                    </Box>
                    <HookForm
                        defaultForm={{}}
                        init={(form) => setReset(reset)}
                        onSubmit={(e) => onFormSubmit(e)}
                    >
                        {(formMethod) => {
                            return (
                                <Fragment>
                                    <Box className='formGroupField'>
                                        <CustomTextField
                                            placeholder={strings.NEWPASS}
                                            variant='outlined'
                                            autoFocus={false}
                                            type={showPass}
                                            multiErrorFields={multiErrorFields}
                                            formMethod={formMethod}
                                            inputProps={{
                                                maxLength: 64
                                            }}
                                            rules={resetPassForm.resetpassword.validate}
                                            name={resetPassForm.resetpassword.name}
                                            classText="loginpage"
                                            errors={formMethod?.formState.errors}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                e.target.value = value.replaceAll(' ', '');
                                                formMethod.setValue(
                                                    resetPassForm.resetpassword.name,
                                                    e.target.value
                                                );
                                                formMethod.watch(resetPassForm.password.name) &&
                                                formMethod.trigger(resetPassForm.password.name);
                                            }}
                                            IconName={showPass === 'password' ? <span className='icon icon-eyeoff' onClick={handleChangePassword} ></span> : <span className='icon icon-eye' onClick={handleChangePassword}></span>}
                                        />
                                        <CustomTextField
                                            formMethod={formMethod}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: strings.CONFORMPASS_REQUIRED
                                                },
                                                validate: {
                                                    matchPassword: (value) =>
                                                        (value &&
                                                        value === formMethod.watch(resetPassForm.resetpassword.name)) || strings.BOTH_SAME
                                                }
                                            }}
                                            classText="loginpage"
                                            name={resetPassForm.password.name}
                                            errors={formMethod?.formState.errors}
                                            type={showPass2}
                                            inputProps={{
                                                maxLength: 64
                                            }}
                                            placeholder={strings.CONFORMPASS}
                                            IconName={showPass2 === 'password' ? <span className='icon icon-eyeoff' onClick={handleChangePassword2} ></span> : <span className='icon icon-eye' onClick={handleChangePassword2}></span>}
                                            onChange={(e) => {
                                            // formMethod.watch('resetpassword') &&
                                            //     formMethod.trigger('resetpassword');
                                                const value = e.target.value;
                                                e.target.value = value.replaceAll(' ', '');
                                                formMethod.setValue(
                                                    resetPassForm.password.name,
                                                    e.target.value
                                                );
                                            }}
                                            onKeyDown={onKeyDown}
                                        />
                                    </Box>
                                    <Box className='submitBtn '>
                                        <CustomLoadingButton
                                            fullWidth
                                            size='large'
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            btnTitle={strings.RESET}
                                            loading={busy}
                                            disabled={!formMethod?.formState.isValid}
                                            className="submitButton"
                                        />
                                    </Box>
                                </Fragment>
                            );
                        }}
                    </HookForm>
                </Grid>
            </Grid>
        </>

    );
};

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = {
    showToast
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(ResetPassword));
